import React, { useState, useEffect } from 'react';
import AdminSidebar from '../admin/AdminSidebar';
import iziToast from 'izitoast';
import { useAuth } from '../../AuthContext';

const HomeCmsPageAdd = () => {
    const { apipath } = useAuth();
    const [formData, setFormData] = useState({
        banner: [{ bannerimage: '', bannertext: '' }],
        body1: [{ body1image: '', body1heading: '', body1text: '' }],
        body2card: [{ cardimage: '', cardheading: '', cardcontent: '' }],
        body3heading: '',
        body3card: [{ cardimage: '', cardtitle: '' }],
        body4heading: '',
        body4card: [{ cardimage: '', cardheading: '', cardcontent: '' }],
        body5card: [{ cardimage: '', cardtitle: '' }],
    });

    const [images, setImages] = useState({
        banner: [],
        body1: [],
        body2card: [],
        body3card: [],
        body4card: [],
        body5card: [],
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${apipath}/home-cms/details`); // Adjust the endpoint as necessary
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const data = await response.json();

                // Populate formData with fetched data
                setFormData({
                    banner: data.banner || [{ bannerimage: '', bannertext: '' }],
                    body1: data.body1 || [{ body1image: '', body1heading: '', body1text: '' }],
                    body2card: data.body2card || [{ cardimage: '', cardheading: '', cardcontent: '' }],
                    body3heading: data.body3heading || '',
                    body3card: data.body3card || [{ cardimage: '', cardtitle: '' }],
                    body4heading: data.body4heading || '',
                    body4card: data.body4card || [{ cardimage: '', cardheading: '', cardcontent: '' }],
                    body5card: data.body5card || [{ cardimage: '', cardtitle: '' }],
                });

                // Initialize images state based on fetched data if necessary
                setImages({
                    banner: data.banner.map(banner => banner.bannerImage ? { name: banner.bannerImage } : null),
                    body1: data.body1.map(body1 => body1.body1image ? { name: body1.body1image } : null),
                    body2card: data.body2card.map(card => card.cardimage ? { name: card.cardimage } : null),
                    body3card: data.body3card.map(card => card.cardimage ? { name: card.cardimage } : null),
                    body4card: data.body4card.map(card => card.cardimage ? { name: card.cardimage } : null),
                    body5card: data.body5card.map(card => card.cardimage ? { name: card.cardimage } : null),
                });
            } catch (error) {
                console.error('Error fetching data:', error);
                iziToast.error({
                    message: `An error occurred while fetching data: ${error.message}`,
                    position: 'topCenter',
                });
            }
        };

        fetchData();
    }, []);

    const handleChange = (e, field) => {
        const { value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    const handleSectionChange = (section, index, field, value) => {
        setFormData((prevData) => {
            const updatedSection = [...prevData[section]];
            updatedSection[index] = {
                ...updatedSection[index],
                [field]: value,
            };
            return { ...prevData, [section]: updatedSection };
        });
    };

    const addSection = (section) => {
        const newSection = {
            banner: { bannerImage: '', bannertext: '' },
            body1: { body1image: '', body1heading: '', body1text: '' },
            body2card: { cardimage: '', cardheading: '', cardcontent: '' },
            body3card: { cardimage: '', cardtitle: '' },
            body4card: { cardimage: '', cardheading: '', cardcontent: '' },
            body5card: { cardimage: '', cardtitle: '' },
        };

        setFormData((prevData) => ({
            ...prevData,
            [section]: [...prevData[section], newSection[section]],
        }));
    };

    const handleFileChange = (e, section, index, field) => {
        const file = e.target.files[0];
        setImages((prevImages) => {
            const updatedImages = [...prevImages[section]];
            updatedImages[index] = file;
            return { ...prevImages, [section]: updatedImages };
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = new FormData();

        // Append non-array form data
        data.append('body3heading', formData.body3heading);
        data.append('body4heading', formData.body4heading);

        // Append array form data as JSON
        data.append('banner', JSON.stringify(formData.banner));
        data.append('body1', JSON.stringify(formData.body1));
        data.append('body2card', JSON.stringify(formData.body2card));
        data.append('body3card', JSON.stringify(formData.body3card));
        data.append('body4card', JSON.stringify(formData.body4card));
        data.append('body5card', JSON.stringify(formData.body5card));

        // Append images as FormData entries
        if (images.banner.length) {
            images.banner.forEach((image, index) => {
                if (image instanceof File) {
                    data.append(`bannerImage`, image);
                }
            });
        }

        if (images.body1.length) {
            images.body1.forEach((image, index) => {
                if (image instanceof File) {
                    data.append(`body1Image`, image);
                }
            });
        }

        if (images.body2card.length) {
            images.body2card.forEach((image, index) => {
                if (image instanceof File) {
                    data.append(`body2cardImage`, image);
                }
            });
        }

        if (images.body3card.length) {
            images.body3card.forEach((image, index) => {
                if (image instanceof File) {
                    data.append(`body3cardImage`, image);
                }
            });
        }

        if (images.body4card.length) {
            images.body4card.forEach((image, index) => {
                if (image instanceof File) {
                    data.append(`body4cardImage`, image);
                }
            });
        }

        if (images.body5card.length) {
            images.body5card.forEach((image, index) => {
                if (image instanceof File) {
                    data.append(`body5cardImage`, image);
                }
            });
        }

        try {
            const response = await fetch(`${apipath}/home-cms/edit`, {
                method: 'POST',
                body: data,
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Network response was not ok: ${errorText}`);
            }

            const result = await response.json();

            if (result.success) {
                iziToast.success({
                    message: 'CMS Page added successfully',
                    position: 'topCenter',
                });
            } else {
                iziToast.error({
                    message: `CMS Page addition failed: ${result.error || 'Unknown error'}`,
                    position: 'topCenter',
                });
            }
        } catch (error) {
            console.error('Error adding CMS page:', error);
            iziToast.error({
                message: `An error occurred: ${error.message}`,
                position: 'topCenter',
            });
        }
    };

    return (
        <>
            <AdminSidebar />
            <div className="home">
                <div className="toggle-sidebar" style={{ display: 'none' }}>
                    <i className="bi bi-menu"></i>
                </div>
                <div className="dashboard">
                    <div className="dashboard-header">
                        <h1>Add Home CMS Page</h1>
                    </div>
                    <div className="container-fluid mt-3">
                        <form className="row login-form" onSubmit={handleSubmit}>
                            {/* Banner Section */}
                            <div className="mb-3 col-lg-12">
                                <h3>Banner</h3>
                                {Array.isArray(formData.banner) && formData.banner.map((item, index) => (
                                    <div key={index} className="mb-3">
                                        <label htmlFor={`bannerimage${index}`} className="mb-2">
                                            Banner Image
                                        </label>
                                        <input
                                            type="file"
                                            id={`bannerimage${index}`}
                                            className="form-control"
                                            accept=".png, .jpg, .jpeg"
                                            onChange={(e) => handleFileChange(e, 'banner', index, 'bannerimage')}
                                        />
                                        {item.bannerImage && (
                                            <p className="mt-1">Current Image: {item.bannerImage instanceof File ? item.bannerImage.name : item.bannerImage}</p>
                                        )}
                                        <label htmlFor={`bannertext${index}`} className="mb-2 mt-2">
                                            Banner Text
                                        </label>
                                        <input
                                            type="text"
                                            id={`bannertext${index}`}
                                            className="form-control"
                                            placeholder="Enter banner text"
                                            value={item.bannertext || ''}
                                            onChange={(e) => handleSectionChange('banner', index, 'bannertext', e.target.value)}
                                        />
                                    </div>
                                ))}
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => addSection('banner')}
                                >
                                    Add Banner
                                </button>
                            </div>
                            {/* Body 1 Section */}
                            <div className="mb-3 col-lg-12">
                                <h3>Body 1</h3>
                                {Array.isArray(formData.body1) && formData.body1.map((item, index) => (
                                    <div key={index} className="mb-3">
                                        <label htmlFor={`body1image${index}`} className="mb-2">
                                            Body 1 Image
                                        </label>
                                        <input
                                            type="file"
                                            id={`body1image${index}`}
                                            className="form-control"
                                            accept=".png, .jpg, .jpeg"
                                            onChange={(e) => handleFileChange(e, 'body1', index, 'body1image')}
                                        />
                                        {item.body1image && (
                                            <p className="mt-1">Current Image: {item.body1image instanceof File ? item.body1image.name : item.body1image}</p>
                                        )}
                                        <label htmlFor={`body1heading${index}`} className="mb-2 mt-2">
                                            Body 1 Heading
                                        </label>
                                        <input
                                            type="text"
                                            id={`body1heading${index}`}
                                            className="form-control"
                                            placeholder="Enter body 1 heading"
                                            value={item.body1heading || ''}
                                            onChange={(e) => handleSectionChange('body1', index, 'body1heading', e.target.value)}
                                        />
                                        <label htmlFor={`body1text${index}`} className="mb-2 mt-2">
                                            Body 1 Text
                                        </label>
                                        <textarea
                                            id={`body1text${index}`}
                                            className="form-control"
                                            placeholder="Enter body 1 text"
                                            value={item.body1text || ''}
                                            onChange={(e) => handleSectionChange('body1', index, 'body1text', e.target.value)}
                                        />
                                    </div>
                                ))}
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => addSection('body1')}
                                >
                                    Add Body 1
                                </button>
                            </div>
                            {/* Body 2 Card Section */}
                            <div className="mb-3 col-lg-12">
                                <h3>Body 2 Card</h3>
                                {Array.isArray(formData.body2card) && formData.body2card.map((item, index) => (
                                    <div key={index} className="mb-3">
                                        <label htmlFor={`cardimage${index}`} className="mb-2">
                                            Card Image
                                        </label>
                                        <input
                                            type="file"
                                            id={`cardimage${index}`}
                                            className="form-control"
                                            accept=".png, .jpg, .jpeg"
                                            onChange={(e) => handleFileChange(e, 'body2card', index, 'cardimage')}
                                        />
                                        {item.cardimage && (
                                            <p className="mt-1">Current Image: {item.cardimage instanceof File ? item.cardimage.name : item.cardimage}</p>
                                        )}
                                        <label htmlFor={`cardheading${index}`} className="mb-2 mt-2">
                                            Card Heading
                                        </label>
                                        <input
                                            type="text"
                                            id={`cardheading${index}`}
                                            className="form-control"
                                            placeholder="Enter card heading"
                                            value={item.cardheading || ''}
                                            onChange={(e) => handleSectionChange('body2card', index, 'cardheading', e.target.value)}
                                        />
                                        <label htmlFor={`cardcontent${index}`} className="mb-2 mt-2">
                                            Card Content
                                        </label>
                                        <textarea
                                            id={`cardcontent${index}`}
                                            className="form-control"
                                            placeholder="Enter card content"
                                            value={item.cardcontent || ''}
                                            onChange={(e) => handleSectionChange('body2card', index, 'cardcontent', e.target.value)}
                                        />
                                    </div>
                                ))}
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => addSection('body2card')}
                                >
                                    Add Body 2 Card
                                </button>
                            </div>
                            {/* Body 3 Section */}
                            <div className="mb-3 col-lg-12">
                                <h3>Body 3</h3>
                                <label htmlFor="body3heading" className="mb-2">
                                    Body 3 Heading
                                </label>
                                <input
                                    type="text"
                                    id="body3heading"
                                    className="form-control"
                                    placeholder="Enter body 3 heading"
                                    value={formData.body3heading || ''}
                                    onChange={(e) => handleChange(e, 'body3heading')}
                                />
                                {Array.isArray(formData.body3card) && formData.body3card.map((item, index) => (
                                    <div key={index} className="mb-3">
                                        <label htmlFor={`cardimage${index}`} className="mb-2">
                                            Card Image
                                        </label>
                                        <input
                                            type="file"
                                            id={`cardimage${index}`}
                                            className="form-control"
                                            accept=".png, .jpg, .jpeg"
                                            onChange={(e) => handleFileChange(e, 'body3card', index, 'cardimage')}
                                        />
                                        {item.cardimage && (
                                            <p className="mt-1">Current Image: {item.cardimage instanceof File ? item.cardimage.name : item.cardimage}</p>
                                        )}
                                        <label htmlFor={`cardtitle${index}`} className="mb-2 mt-2">
                                            Card Title
                                        </label>
                                        <input
                                            type="text"
                                            id={`cardtitle${index}`}
                                            className="form-control"
                                            placeholder="Enter card title"
                                            value={item.cardtitle || ''}
                                            onChange={(e) => handleSectionChange('body3card', index, 'cardtitle', e.target.value)}
                                        />
                                    </div>
                                ))}
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => addSection('body3card')}
                                >
                                    Add Body 3 Card
                                </button>
                            </div>
                            {/* Body 4 Section */}
                            <div className="mb-3 col-lg-12">
                                <h3>Body 4</h3>
                                <label htmlFor="body4heading" className="mb-2">
                                    Body 4 Heading
                                </label>
                                <input
                                    type="text"
                                    id="body4heading"
                                    className="form-control"
                                    placeholder="Enter body 4 heading"
                                    value={formData.body4heading || ''}
                                    onChange={(e) => handleChange(e, 'body4heading')}
                                />
                                {Array.isArray(formData.body4card) && formData.body4card.map((item, index) => (
                                    <div key={index} className="mb-3">
                                        <label htmlFor={`cardimage${index}`} className="mb-2">
                                            Card Image
                                        </label>
                                        <input
                                            type="file"
                                            id={`cardimage${index}`}
                                            className="form-control"
                                            accept=".png, .jpg, .jpeg"
                                            onChange={(e) => handleFileChange(e, 'body4card', index, 'cardimage')}
                                        />
                                        {item.cardimage && (
                                            <p className="mt-1">Current Image: {item.cardimage instanceof File ? item.cardimage.name : item.cardimage}</p>
                                        )}
                                        <label htmlFor={`cardheading${index}`} className="mb-2 mt-2">
                                            Card Heading
                                        </label>
                                        <input
                                            type="text"
                                            id={`cardheading${index}`}
                                            className="form-control"
                                            placeholder="Enter card heading"
                                            value={item.cardheading || ''}
                                            onChange={(e) => handleSectionChange('body4card', index, 'cardheading', e.target.value)}
                                        />
                                        <label htmlFor={`cardcontent${index}`} className="mb-2 mt-2">
                                            Card Content
                                        </label>
                                        <textarea
                                            id={`cardcontent${index}`}
                                            className="form-control"
                                            placeholder="Enter card content"
                                            value={item.cardcontent || ''}
                                            onChange={(e) => handleSectionChange('body4card', index, 'cardcontent', e.target.value)}
                                        />
                                    </div>
                                ))}
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => addSection('body4card')}
                                >
                                    Add Body 4 Card
                                </button>
                            </div>
                            {/* Body 5 Section */}
                            <div className="mb-3 col-lg-12">
                                <h3>Body 5</h3>
                                {Array.isArray(formData.body5card) && formData.body5card.map((item, index) => (
                                    <div key={index} className="mb-3">
                                        <label htmlFor={`cardimage${index}`} className="mb-2">
                                            Card Image
                                        </label>
                                        <input
                                            type="file"
                                            id={`cardimage${index}`}
                                            className="form-control"
                                            accept=".png, .jpg, .jpeg"
                                            onChange={(e) => handleFileChange(e, 'body5card', index, 'cardimage')}
                                        />
                                        {item.cardimage && (
                                            <p className="mt-1">Current Image: {item.cardimage instanceof File ? item.cardimage.name : item.cardimage}</p>
                                        )}
                                        <label htmlFor={`cardtitle${index}`} className="mb-2 mt-2">
                                            Card Title
                                        </label>
                                        <input
                                            type="text"
                                            id={`cardtitle${index}`}
                                            className="form-control"
                                            placeholder="Enter card title"
                                            value={item.cardtitle || ''}
                                            onChange={(e) => handleSectionChange('body5card', index, 'cardtitle', e.target.value)}
                                        />
                                    </div>
                                ))}
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => addSection('body5card')}
                                >
                                    Add Body 5 Card
                                </button>
                            </div>
                            <button type="submit" className="btn btn-primary">
                                Save
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HomeCmsPageAdd;
