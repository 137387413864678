import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AdminSidebar from './AdminSidebar';
import axios from 'axios';
import { useAuth } from '../../AuthContext';

const EnquireEdit = () => {
  const { enquireId } = useParams();
  const { apipath } = useAuth();
  const [formData, setFormData] = useState({}); // Initialize as an empty object

  useEffect(() => {
    fetchEnquireDetails();
  }, []);

  const fetchEnquireDetails = async () => {
    try {
      const response = await axios.get(`${apipath}/enquireNow/details/${enquireId}`);
      setFormData(response.data.enquireNow);
    } catch (error) {
      console.error("Error fetching enquire details:", error);
    }
  };


  return (
    <>
      <AdminSidebar />
      <div className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>
        <div className="dashboard">
          <div className="dashboard-header">
            <h1> Contact Details</h1>
          </div>
          <div className="container-fluid mt-3">
            <form className="row login-form">
              <div className="mb-3 col-lg-6">
                <label htmlFor="name" className='mb-2'>Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={formData.name || ''} // Ensure value is a string
                  readOnly
                />
              </div>              

              <div className="mb-3 col-lg-6">
                <label htmlFor="address" className='mb-2'> Address</label>
                <input
                  type="text"
                  className="form-control"
                  name="address"
                  value={formData.address || ''} // Ensure value is a string
                  readOnly
                />
              </div>

              <div className="mb-3 col-lg-6">
                <label htmlFor="email" className='mb-2'>Email</label>
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  value={formData.email || ''} // Ensure value is a string
                  readOnly
                />
              </div>
              <div className="mb-3 col-lg-6">
                <label htmlFor="phoneNo" className='mb-2'>Phone Number</label>
                <input
                  type="text"
                  className="form-control"
                  name="phoneNo"
                  value={formData.phoneNo || ''} // Ensure value is a string
                  readOnly
                />
              </div>

              <div className="mb-3 col-lg-12">
                <label htmlFor="message" className='mb-2'>Message</label>
                <textarea
                  type="text"
                  className="form-control"
                  name="message"
                  value={formData.message || ''} // Ensure value is a string
                  readOnly
                />
              </div>
              <div className="submit-box pt-4">
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnquireEdit;
