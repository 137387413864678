import React, { useState, useEffect } from 'react';
import AdminSidebar from '../admin/AdminSidebar'; // Adjust the path as needed
import iziToast from 'izitoast';
import { useAuth } from '../../AuthContext';

const ManufactureMainForm = () => {
    const { apipath } = useAuth();
    const [formData, setFormData] = useState({
        ExtrusionImage: null,
        AnodizingImage: null,
        PowderCoatingImage: null,
        BilletMakingImage: null,
        ToolRoomImage: null,
        FabricationImage: null,
        ColoringImage: null,
        DesigningImage: null
    });

    // Load data for an existing ManufactureMain entry if needed
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${apipath}/manufacture/details`);
                if (response.ok) {
                    const result = await response.json();
                    setFormData({
                        Maintitle: result.Maintitle || '',
                        ExtrusionImage: result.ExtrusionImage || null,
                        AnodizingImage: result.AnodizingImage || null,
                        PowderCoatingImage: result.PowderCoatingImage || null,
                        BilletMakingImage: result.BilletMakingImage || null,
                        ToolRoomImage: result.ToolRoomImage || null,
                        FabricationImage: result.FabricationImage || null,
                        ColoringImage: result.ColoringImage || null,
                        DesigningImage: result.DesigningImage || null
                    });
                } else {
                    iziToast.error({ message: 'Failed to load data', position: 'topCenter' });
                }
            } catch (error) {
                iziToast.error({ message: `An error occurred: ${error.message}`, position: 'topCenter' });
            }
        };
        fetchData();
    }, [apipath]);

    // const handleChange = (e) => {
    //     const { name, type, files } = e.target;
    //     if (type === 'file') {
    //         setFormData(prevData => ({ ...prevData, [name]: files[0] }));
    //     }
    // };
    const handleChange = (e) => {
        const { name, type, value, files } = e.target;
        if (type === 'file') {
            setFormData(prevData => ({ ...prevData, [name]: files[0] }));
        } else {
            setFormData(prevData => ({ ...prevData, [name]: value }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = new FormData();

        // Append non-file fields like Maintitle
        data.append('Maintitle', formData.Maintitle);

        // Append only file fields to the FormData
        for (let key in formData) {
            if (formData[key] instanceof File) {
                data.append(key, formData[key]);
            }
        }

        try {
            const response = await fetch(`${apipath}/manufacture/edit`, {
                method: 'POST',
                body: data // Use FormData to handle both text and file data
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Network response was not ok: ${errorText}`);
            }

            const result = await response.json();

            if (result.success) {
                iziToast.success({ message: 'ManufactureMain added/updated successfully', position: 'topCenter' });
            } else {
                iziToast.error({ message: 'ManufactureMain addition/update failed', position: 'topCenter' });
            }
        } catch (error) {
            iziToast.error({ message: `An error occurred: ${error.message}`, position: 'topCenter' });
        }
    };

    return (
        <>
            <AdminSidebar />
            <div className="home">
                <div className="toggle-sidebar" style={{ display: 'none' }}>
                    <i className="bi bi-menu"></i>
                </div>
                <div className="dashboard">
                    <div className="dashboard-header">
                        <h1>Add or Update Manufacturing Main</h1>
                    </div>
                    <div className="container-fluid mt-3">
                        <form className="row login-form" onSubmit={handleSubmit}>
                            <div className="mb-3 col-lg-12">
                                <label htmlFor="Maintitle" className="mb-2">Main Title</label>
                                <input
                                    type="text"
                                    id="Maintitle"
                                    name="Maintitle"
                                    placeholder="Maintitle"
                                    className="form-control"
                                    value={formData.Maintitle}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="mb-3 col-lg-12">
                                <label htmlFor="ExtrusionImage" className="mb-2">Extrusion Image</label>
                                <input
                                    type="file"
                                    id="ExtrusionImage"
                                    name="ExtrusionImage"
                                    className="form-control"
                                    onChange={handleChange}
                                />
                                {formData.ExtrusionImage && (
                                    <p className="mt-1">Current Image: {formData.ExtrusionImage instanceof File ? formData.ExtrusionImage.name : formData.ExtrusionImage}</p>
                                )}
                            </div>
                            <div className="mb-3 col-lg-12">
                                <label htmlFor="AnodizingImage" className="mb-2">Anodizing Image</label>
                                <input
                                    type="file"
                                    id="AnodizingImage"
                                    name="AnodizingImage"
                                    className="form-control"
                                    onChange={handleChange}
                                />
                                {formData.AnodizingImage && (
                                    <p className="mt-1">Current Image: {formData.AnodizingImage instanceof File ? formData.AnodizingImage.name : formData.AnodizingImage}</p>
                                )}
                            </div>
                            <div className="mb-3 col-lg-12">
                                <label htmlFor="PowderCoatingImage" className="mb-2">Powder Coating Image</label>
                                <input
                                    type="file"
                                    id="PowderCoatingImage"
                                    name="PowderCoatingImage"
                                    className="form-control"
                                    onChange={handleChange}
                                />
                                {formData.PowderCoatingImage && (
                                    <p className="mt-1">Current Image: {formData.PowderCoatingImage instanceof File ? formData.PowderCoatingImage.name : formData.PowderCoatingImage}</p>
                                )}
                            </div>
                            <div className="mb-3 col-lg-12">
                                <label htmlFor="BilletMakingImage" className="mb-2">Billet Making Image</label>
                                <input
                                    type="file"
                                    id="BilletMakingImage"
                                    name="BilletMakingImage"
                                    className="form-control"
                                    onChange={handleChange}
                                />
                                {formData.BilletMakingImage && (
                                    <p className="mt-1">Current Image: {formData.BilletMakingImage instanceof File ? formData.BilletMakingImage.name : formData.BilletMakingImage}</p>
                                )}
                            </div>
                            <div className="mb-3 col-lg-12">
                                <label htmlFor="ToolRoomImage" className="mb-2">Tool Room Image</label>
                                <input
                                    type="file"
                                    id="ToolRoomImage"
                                    name="ToolRoomImage"
                                    className="form-control"
                                    onChange={handleChange}
                                />
                                {formData.ToolRoomImage && (
                                    <p className="mt-1">Current Image: {formData.ToolRoomImage instanceof File ? formData.ToolRoomImage.name : formData.ToolRoomImage}</p>
                                )}
                            </div>
                            <div className="mb-3 col-lg-12">
                                <label htmlFor="FabricationImage" className="mb-2">Fabrication Image</label>
                                <input
                                    type="file"
                                    id="FabricationImage"
                                    name="FabricationImage"
                                    className="form-control"
                                    onChange={handleChange}
                                />
                                {formData.FabricationImage && (
                                    <p className="mt-1">Current Image: {formData.FabricationImage instanceof File ? formData.FabricationImage.name : formData.FabricationImage}</p>
                                )}
                            </div>
                            <div className="mb-3 col-lg-12">
                                <label htmlFor="ColoringImage" className="mb-2">Coloring Image</label>
                                <input
                                    type="file"
                                    id="ColoringImage"
                                    name="ColoringImage"
                                    className="form-control"
                                    onChange={handleChange}
                                />
                                {formData.ColoringImage && (
                                    <p className="mt-1">Current Image: {formData.ColoringImage instanceof File ? formData.ColoringImage.name : formData.ColoringImage}</p>
                                )}
                            </div>
                            <div className="mb-3 col-lg-12">
                                <label htmlFor="DesigningImage" className="mb-2">Designing Image</label>
                                <input
                                    type="file"
                                    id="DesigningImage"
                                    name="DesigningImage"
                                    className="form-control"
                                    onChange={handleChange}
                                />
                                {formData.DesigningImage && (
                                    <p className="mt-1">Current Image: {formData.DesigningImage instanceof File ? formData.DesigningImage.name : formData.DesigningImage}</p>
                                )}
                            </div>
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ManufactureMainForm;
