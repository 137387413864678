// import React, { useEffect } from "react";
// import Navbar from "./Navbar";
// import Footer from "./Footer";
// import { Link } from "react-router-dom";

// const Designing = () => {
//   useEffect(() => {
//     window.scrollTo({
//       top: 0,
//       left: 0,
//       behavior: "auto",
//     });
//   }, []);

//   return (
//     <>
//       <Navbar />

//       <Link to="/" className="float">
//         <img src="imgs-alu/whtsp.png" alt="" />
//       </Link>

//       <div className="container-fluid main-banner">
//         <div className="heading-main">
//           <h1 className="text-[48px] text-white font-candal text-center">
//             Designing
//           </h1>
//           <h4 className="text-[17px] font-exo text-white font-medium text-center">
//             Home{" "}
//             <i className="bi bi-chevron-double-right text-light-yellow"></i>
//             Designing
//           </h4>
//         </div>
//       </div>

//       <div className="container py-5">
//         <img src="imgs-alu/designing.png" alt="" className="w-full" />
//       </div>

//       <div className="container-fluid py-5 my-3 bg-yellow-2">
//         <div className="main-heading">
//           <h1>
//             D<span>esignin</span>g
//           </h1>
//         </div>
//         <div className="container">
//           <div className="w-full lg:w-9/12 mx-auto">
//             <p className="font-nunito font-normal text-[20px] leading-9 text-regal-blue">
//               Narayan Aluminium Alloys Pvt. Ltd provides production and design
//               solutions in the fields of electronics, hydraulics, refrigeration,
//               and defense. When it comes to selecting the best metal fabrication
//               company, Narayan Aluminium Alloys Pvt. Ltd is the first choice
//               because it meets production criteria while also providing the
//               greatest degree of quality work. With our years of experience, we
//               can provide our clients with a high-quality product and measurable
//               value.
//             </p>
//           </div>
//         </div>
//       </div>

//       <div className="container py-4">
//         <div className="flex flex-wrap py-3">
//           <div className="w-full md:w-6/12">
//             <div className="p-3">
//               <h2 className="font-raleway font-semibold text-[24px] text-dark-yellow">
//                 Our Design Methodology :
//               </h2>

//               <p className="pt-1 font-nunito font-normal text-lg text-dark-gray leading-8">
//                 Adherence to criteria that ensure sheet metal designs are
//                 prepared by the design for manufacturing (DFM) guidelines
//                 seamlessly.
//               </p>
//               <p className="pt-1 font-nunito font-normal text-lg text-dark-gray leading-8">
//                 <b>
//                   It is important to select the appropriate material for design:
//                 </b>
//               </p>
//               <p className="pt-1 font-nunito font-normal text-lg text-dark-gray leading-8">
//                 Aluminum is a temperature-resistant and weldable metal that can
//                 be formed into any shape. It is commonly utilized in the design
//                 of parts. In the majority of cases, it’s also resistant to
//                 corrosion and has a high strength-to-weight ratio.
//               </p>
//             </div>
//           </div>
//           <div className="w-full md:w-6/12 flex items-center">
//             <div className="p-3">
//               <img className="w-full" src="imgs-alu/designing2.png" alt="" />
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="container pb-4">
//         <div className="flex flex-wrap">
//           <div className="w-full lg:w-1/2 p-2 mt-3 mx-auto">
//             <div className="shadow-custom-4 rounded-[16px] bg-white p-4">
//               <h2 className="font-raleway font-semibold text-[22px] text-dark-yellow mb-3">
//                 Check out the following common material choices for your design:
//               </h2>
//               <p className="font-nunito font-normal text-base text-dark-gray leading-2">
//                 The material you use for design will have an impact on the
//                 part’s design, integrity, and cost.
//               </p>
//               <div className="list-none">
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-4"
//                     />
//                   </div>{" "}
//                   Efficacy
//                 </li>
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-4"
//                     />
//                   </div>{" "}
//                   Corrosion Resistance Requirements.
//                 </li>
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-4"
//                     />
//                   </div>{" "}
//                   Aesthetics.
//                 </li>
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-4"
//                     />
//                   </div>{" "}
//                    Formability of Desired Weight Geometry.
//                 </li>
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-4"
//                     />
//                   </div>{" "}
//                    Joining.
//                 </li>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="container py-4">
//         <h2 className="font-raleway font-semibold text-[20px] text-dark-yellow my-2">
//           Consider the full Size:
//         </h2>

//         <p className="font-nunito font-normal text-dark-gray text-base ">
//           When working with sheet metal, keep the whole size in mind: Assume you
//           design your part and determine where to put the holes. The holes
//           should not be so small that they cause the tool to break. As the punch
//           becomes thinner, the size of the sheet metal hole shrinks. If a little
//           punch becomes too small, it can fail during operation. Drill holes
//           that are greater than the thickness of the sheet metal.
//         </p>
//         <h2 className="font-raleway font-semibold text-[20px] text-dark-yellow my-2">
//           The radius of the Hole :
//         </h2>

//         <p className="font-nunito font-normal text-dark-gray text-base ">
//            A smaller bend radius indicates more strain on the work piece. When
//           cracks appear, the material has reached its fatigue point. Aluminum,
//           unlike ductile materials such as cold-rolled aluminum, requires a
//           wider radius of the bend. When bend radii and flange depths are both
//           small, flexible material produces good performance.
//         </p>
//         <h2 className="font-raleway font-semibold text-[20px] text-dark-yellow my-2">
//           The radius of the Hole :
//         </h2>

//         <p className="font-nunito font-normal text-dark-gray text-base ">
//            A smaller bend radius indicates more strain on the work piece. When
//           cracks appear, the material has reached its fatigue point. Aluminum,
//           unlike ductile materials such as cold-rolled aluminum, requires a
//           wider radius of the bend. When bend radii and flange depths are both
//           small, flexible material produces good performance.
//         </p>
//         <h2 className="font-raleway font-semibold text-[20px] text-dark-yellow my-2">
//           Make Simple Folding :
//         </h2>

//         <p className="font-nunito font-normal text-dark-gray text-base ">
//            Making a difficult part will cost more. A bend should have a radius
//           greater than the thickness of the sheet, and a tiny bend in a large,
//           thick section is frequently inaccurate and difficult to build. It is
//           preferable to design your part such that it is as inexpensive as
//           feasible.
//         </p>
//         <p className="font-nunito font-normal text-dark-gray text-base ">
//           Tooling can limit the shape of a part, and a standard press-brake tool
//           can bend practically any sheet metal part. For low manufacturing costs
//           and rapid production times, simple designs are usually ideal.
//         </p>
//         <p className="font-nunito font-normal text-dark-gray text-base ">
//           These are normally handled by a punch press, and uniform sizes should
//           be used wherever possible. A precision sheet metal part is not the
//           same as a machined part. Precision sheet metal parts are not the same
//           as machined parts.
//         </p>
//         <p className="font-nunito font-normal text-dark-gray text-base ">
//           Because of their numerous processes, they are impossible to mill to
//           the same tolerances as a machined object.
//         </p>

//         <h2 className="font-raleway font-semibold text-[20px] text-dark-yellow my-2">
//           Avoid utilizing rigid tolerances:
//         </h2>

//         <p className="font-nunito font-normal text-dark-gray text-base ">
//             Most parts have a few key surfaces for their functionality. A
//           product with a higher tolerance callout will cost more, and tolerance
//           can encompass radii, diameters, and lengths. Tolerances must be
//           specified for features and surfaces that are critical to the work. By
//           minimizing tight tolerances, you can produce an economical design.
//         </p>
//         <h2 className="font-raleway font-semibold text-[20px] text-dark-yellow my-2">
//           Maintain uniform bend orientation:
//         </h2>

//         <p className="font-nunito font-normal text-dark-gray text-base ">
//           By keeping your bends uniform, you can boost the affordability of your
//           design. Bends in the same location should be created in the same
//           direction to save time and money. If you maintain your bend radius
//           constant, you can create your parts more cheaply.
//         </p>
//         <h2 className="font-raleway font-semibold text-[20px] text-dark-yellow my-2">
//           Specific Benefits :
//         </h2>

//         <ul className="list-disc font-nunito font-normal text-dark-gray text-base ">
//           <li>Cost-effective</li>
//           <li>Durability</li>
//           <li>Longevity</li>
//           <li>Excellent Quality</li>
//         </ul>

//         <p className="font-nunito font-normal text-dark-gray text-lg ">
//           Narayan Aluminium Alloys Pvt. Ltd is a one-stop shop for all of your
//           manufacturing needs. Our products include building doors and windows,
//           curtain walls, and hardware. Commercial cars, containers, buses, and
//           heavy equipment are all part of the transportation division.
//         </p>
//         <p className="font-nunito font-normal text-dark-gray text-lg ">
//           We cover everything. Our skilled staff uses their talents and
//           expertise to provide you with the finest. Contact us immediately for
//           more information.
//         </p>
//       </div>

//       <div className="container enquiry">
//         <div className="main-heading">
//           <h1>
//             E<span>nquire No</span>w
//           </h1>
//         </div>
//         <div className="my-4 lg:p-5 p-3 w-full lg:w-8/12 bg-enquiry rounded-[10px] mx-auto">
//           <form action="" className="flex flex-wrap">
//             <div className="w-full md:w-6/12 p-2">
//               <div>
//                 <label
//                   htmlFor="name"
//                   className="block font-poppins font-medium text-lg mb-1"
//                 >
//                   Name
//                 </label>
//                 <input
//                   type="text"
//                   className="w-full p-2 border-1 border-enquiry-border rounded-[10px] bg-transparent font-poppins font-normal text-md text-dark-gray"
//                   placeholder="Name"
//                 />
//               </div>
//             </div>
//             <div className="w-full md:w-6/12 p-2">
//               <div>
//                 <label
//                   htmlFor="name"
//                   className="block font-poppins font-medium text-lg mb-1"
//                 >
//                   Phone No.
//                 </label>
//                 <input
//                   type="text"
//                   className="w-full p-2 border-1 border-enquiry-border rounded-[10px] bg-transparent font-poppins font-normal text-md text-dark-gray"
//                   placeholder="Phone no"
//                 />
//               </div>
//             </div>
//             <div className="w-full md:w-6/12 p-2">
//               <div>
//                 <label
//                   htmlFor="name"
//                   className="block font-poppins font-medium text-lg mb-1"
//                 >
//                   Email
//                 </label>
//                 <input
//                   type="text"
//                   className="w-full p-2 border-1 border-enquiry-border rounded-[10px] bg-transparent font-poppins font-normal text-md text-dark-gray"
//                   placeholder="Email"
//                 />
//               </div>
//             </div>
//             <div className="w-full md:w-6/12 p-2">
//               <div>
//                 <label
//                   htmlFor="name"
//                   className="block font-poppins font-medium text-lg mb-1"
//                 >
//                   Address
//                 </label>
//                 <input
//                   type="text"
//                   className="w-full p-2 border-1 border-enquiry-border rounded-[10px] bg-transparent font-poppins font-normal text-md text-dark-gray"
//                   placeholder="Address"
//                 />
//               </div>
//             </div>
//             <div className="w-full p-2">
//               <div>
//                 <label
//                   htmlFor="name"
//                   className="block font-poppins font-medium text-lg mb-1"
//                 >
//                   Message
//                 </label>
//                 <textarea
//                   type="text"
//                   className="w-full p-2 border-1 border-enquiry-border rounded-[10px] bg-transparent font-poppins font-normal text-md text-dark-gray"
//                   placeholder="Message"
//                 >
//                   {" "}
//                 </textarea>
//               </div>
//             </div>
//             <div className="p-2">
//               <button className="rounded-[10px] bg-enquiry-button font-poppins font-medium text-white text-lg py-1 px-4">
//                 Send
//               </button>
//             </div>
//           </form>
//         </div>
//       </div>

//       <Footer />
//     </>
//   );
// };

// export default Designing;


import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link, useSearchParams } from "react-router-dom";
import EnquiryForm from "./EnquiryForm";
import { useAuth } from "../../AuthContext";
import axios from 'axios';

const Designing = () => {
  const { apipath, filepath } = useAuth();
  const [designingData, setDesigningData] = useState(null);
  const [searchParams] = useSearchParams();
  const pageType = searchParams.get("pageType") || "Default";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apipath}/manufacturingcms/content/${pageType}`);
        // Log the data portion of the response
        //  console.log('Response Data:', response.data);
        setDesigningData(response.data);
      } catch (error) {
        console.error('Error fetching about data:', error);
      }
    };

    fetchData();
  }, [pageType]);

  if (!designingData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Navbar />

      <Link to="/" className="float">
        <img src="imgs-alu/whtsp.png" alt="" />
      </Link>

      <div className="container-fluid main-banner">
        <div className="heading-main">
          <h1 className="text-[48px] text-white font-candal text-center">
            Designing
          </h1>
          <h4 className="text-[17px] font-exo text-white font-medium text-center">
            Home{" "}
            <i className="bi bi-chevron-double-right text-light-yellow"></i>
            Designing
          </h4>
        </div>
      </div>

      <div className="container py-5">
        <img src={filepath + designingData.bannerImage} alt="" className="w-full" />
      </div>

      <div className="container-fluid py-5 my-3 bg-yellow-2">
        <div className="main-heading">
          <h1>
            {designingData.maintitle}
          </h1>
        </div>
        <div className="container">
          <div className="w-full lg:w-9/12 mx-auto">
            <p className="font-nunito font-normal text-[20px] leading-9 text-regal-blue">
              {designingData.headContent}
            </p>
          </div>
        </div>
      </div>

      <div className="container py-4">
        <div className="flex flex-wrap py-3">
          <div className="w-full md:w-6/12">
            <div className="p-3">
              <h2 className="font-raleway font-semibold text-[24px] text-dark-yellow">
                {designingData.body1title}
              </h2>

              <p className="pt-1 font-nunito font-normal text-lg text-dark-gray leading-8">
                {designingData.body1content[0].content}
              </p>
              <p className="pt-1 font-nunito font-normal text-lg text-dark-gray leading-8">
                <b>
                  {designingData.body1content[1].content}
                </b>
              </p>
              <p className="pt-1 font-nunito font-normal text-lg text-dark-gray leading-8">
                {designingData.body1content[2].content}
              </p>
            </div>
          </div>
          <div className="w-full md:w-6/12 flex items-center">
            <div className="p-3">
              <img className="w-full" src={filepath + designingData.sideImage} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="container pb-4">
        <div className="flex flex-wrap">
          {designingData.body2card.slice(0, 1).map((item, index) => (
            <div className="w-full lg:w-1/2 p-2 mt-3 mx-auto" key={index}>
              <div className="shadow-custom-4 rounded-[16px] bg-white p-4">
                <h2 className="font-raleway font-semibold text-[22px] text-dark-yellow mb-3">
                  {item.cardTitle}
                </h2>
                <p className="font-nunito font-normal text-base text-dark-gray leading-2">
                  {item.cardContent || ""}
                </p>
                <div className="list-none">
                  {item.bulletins.map((bulletin, index) => (
                    <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1" key={index}>
                      <div>
                        <img
                          src="imgs-alu/material-symbols_point-scan.png"
                          alt=""
                          className="mr-4"
                        />
                      </div>{" "}
                      {bulletin}
                    </li>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="container py-4">
        {designingData.body2card.slice(1, 7).map((item, index) => (
          <div key={index}>
            <h2 className="font-raleway font-semibold text-[20px] text-dark-yellow my-2">
              {item.cardTitle}
            </h2>

            <p className="font-nunito font-normal text-dark-gray text-base ">
              {item.cardContent || ""}
            </p>
          </div>
        ))}
        
        {designingData.body2card.slice(7,).map((item, index) => (
          <div key={index}>
            <h2 className="font-raleway font-semibold text-[20px] text-dark-yellow my-2">
            {item.cardTitle}
            </h2>

            {item.bulletins.map((bulletin, index) => (
              <ul className="list-disc font-nunito font-normal text-dark-gray text-base" key={index}>
                <li>{bulletin}</li>
              </ul>
            ))}
          </div>
        ))}

        <p className="font-nunito font-normal text-dark-gray text-lg ">
          {designingData.bottomstatement}
        </p>
      </div>

      <EnquiryForm />

      <Footer />
    </>
  );
};

export default Designing;
