// import React from "react";
// import Navbar from "./Navbar";
// import Footer from "./Footer";
// import { Link } from "react-router-dom";

// const ToolRoom = () => {
//   return (
//     <>
//       <Navbar />

//       <Link to="/" className="float">
//         <img src="imgs-alu/whtsp.png" alt="" />
//       </Link>

//       <div className="container-fluid main-banner">
//         <div className="heading-main">
//           <h1 className="text-[48px] text-white font-candal text-center">
//             Tool Room
//           </h1>
//           <h4 className="text-[17px] font-exo text-white font-medium text-center">
//             Home{" "}
//             <i className="bi bi-chevron-double-right text-light-yellow"></i>
//             Tool Room
//           </h4>
//         </div>
//       </div>

//       <div className="container py-5">
//         <img src="imgs-alu/tool.png" alt="" className="w-full" />
//       </div>

//       <div className="container-fluid py-5 my-3 bg-yellow-2">
//         <div className="main-heading">
//           <h1>
//             T<span>ool Roo</span>m
//           </h1>
//         </div>
//         <div className="container">
//           <div className="w-full lg:w-9/12 mx-auto">
//             <p className="font-nunito font-normal text-[20px] leading-9 text-regal-blue">
//               <b>
//                 Material removal is a simple technique that turns raw material
//                 into any required shape.
//               </b>
//               <br />
//               Narayan Aluminium Alloys Pvt. Ltd’s aluminum machining
//               professionals have many years of experience and are ready to
//               assist you with your next project. We are trustworthy Aluminum
//               Machining operators with years of experience in this industry.
//             </p>
//             <p className="font-nunito font-normal text-[20px] leading-9 text-regal-blue">
//               Machining refers to the process of removing materials from a work
//               item during the manufacturing process. This technology is
//               extremely adaptable, accommodating a wide range of metals and
//               nonmetals.
//             </p>
//           </div>
//         </div>
//       </div>

//       <div className="container py-4">
//         <div className="flex flex-wrap py-3">
//           <div className="w-full md:w-6/12">
//             <div className="p-3">
//               <h2 className="font-raleway font-semibold text-[24px] text-dark-yellow">
//                 Aluminum machining process :
//               </h2>

//               <p className="pt-1 font-nunito font-normal text-lg text-dark-gray leading-8">
//                 Machines are used in manufacturing procedures such as milling,
//                 turning, and drilling. Horizontal and vertical milling, as well
//                 as electrical discharge machining (EDM), are kinds of machining
//                 that are similar to CNC machining. <br />
//                 Here’s how each of these machining processes handles aluminum :
//               </p>
//               <ul className="list-disc">
//                 <li>
//                   {" "}
//                   <b>CNC Machining :</b>
//                   <p className="font-nunito font-normal text-lg text-dark-gray leading-8">
//                     Computer Numerical Control or CNC-compatible hardware and
//                     software are used to operate machine tools across work
//                     pieces. We can produce aluminum CNC products and parts with
//                     high precision and accuracy.
//                   </p>
//                 </li>
//                 <li>
//                   {" "}
//                   <b>Vertical/horizontal milling :</b>
//                   <p className="font-nunito font-normal text-lg text-dark-gray leading-8">
//                     Milling removes superfluous materials from a work piece by
//                     rotating cutting tools. Milling machines can be set either
//                     vertically or horizontally. <br /> Vertical mills are better
//                     suited for small quantities of simple aluminum components,
//                     whereas horizontal mills are better suited for large numbers
//                     of complex aluminum parts.
//                   </p>
//                 </li>
//                 <li>
//                   {" "}
//                   <b>Electronic discharge machining (EDM) :</b>
//                   <p className="font-nunito font-normal text-lg text-dark-gray leading-8">
//                     During EDM, an electrical discharge occurs between two
//                     electrodes, which removes material from work parts. However,
//                     it’s suitable for use on any electrically conductive
//                     material.
//                   </p>
//                 </li>
//               </ul>
//             </div>
//           </div>
//           <div className="w-full md:w-6/12 flex items-center">
//             <div className="p-3">
//               <img className="w-full" src="imgs-alu/tool2.png" alt="" />
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="container pb-4">
//         <div className="flex flex-wrap">
//           <div className="w-full lg:w-1/2 p-2">
//             <div className="shadow-custom-4 rounded-[16px] bg-white p-4">
//               <h2 className="font-raleway font-semibold text-[22px] text-dark-yellow mb-3">
//                 Industries that rely on our machinable aluminum parts include:
//               </h2>
//               <p className="font-nunito font-normal text-base text-dark-gray leading-6">
//                 Aluminum is used in manufacturing to make a variety of parts and
//                 products. Aluminum is used in the following industries:
//               </p>
//               <div className="list-none">
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-2"
//                     />
//                   </div>{" "}
//                   Automotive
//                 </li>
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-2"
//                     />
//                   </div>{" "}
//                   Communications.
//                 </li>
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-2"
//                     />
//                   </div>{" "}
//                   Electrical and electronic equipment.
//                 </li>
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-2"
//                     />
//                   </div>{" "}
//                   Lighting.
//                 </li>
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-2"
//                     />
//                   </div>{" "}
//                   Front panels, lighting fixtures, buses, refrigerators, and
//                   other common products.
//                 </li>
//               </div>
//             </div>
//           </div>
//           <div className="w-full lg:w-1/2 p-2">
//             <div className="shadow-custom-4 rounded-[16px] bg-white p-4">
//               <h2 className="font-raleway font-semibold text-[22px] text-dark-yellow mb-3">
//                 The primary reasons why you should choose aluminum:
//               </h2>
//               <p className="font-nunito font-normal text-base text-dark-gray leading-2">
//                 Aluminum is a material that is frequently utilized in machining
//                 operations. Because of its smaller material weight, lower
//                 material hardness, and great formability, it is perfect for
//                 machining and other manufacturing activities.
//               </p>
//               <div className="list-none">
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-2"
//                     />
//                   </div>{" "}
//                   Aluminum maintains a higher material strength.
//                 </li>
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-2"
//                     />
//                   </div>{" "}
//                   Aluminum is more potent and heat-resistant.
//                 </li>
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-2"
//                     />
//                   </div>{" "}
//                   Aluminum is more machinable and cheaper.
//                 </li>
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-2"
//                     />
//                   </div>{" "}
//                   It offers a higher material strength than ABS.
//                 </li>
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-2"
//                     />
//                   </div>{" "}
//                   Aluminum is lighter and more machinable than steel and
//                   stainless steel
//                 </li>
//               </div>
//             </div>
//           </div>
//           <div className="w-full lg:w-8/12 p-2 mt-3 mx-auto">
//             <div className="shadow-custom-4 rounded-[16px] bg-white p-4">
//               <h2 className="font-raleway font-semibold text-[22px] text-dark-yellow mb-3">
//                 Unique Advantages of Aluminum Machining that can Help Your
//                 Business :
//               </h2>
//               <p className="font-nunito font-normal text-base text-dark-gray leading-6">
//                 Aside from its exceptional machinability, aluminum has several
//                 properties that make it appropriate for machining:
//               </p>
//               <div className="list-none">
//                 <li className="font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div className="flex items-center">
//                     <div>
//                       <img
//                         src="imgs-alu/material-symbols_point-scan.png"
//                         alt=""
//                         className="mr-2"
//                       />
//                     </div>{" "}
//                     <b>High strength-to-weight ratio: </b>
//                   </div>

//                   <p className="font-nunito font-normal text-base text-dark-gray leading-6 ml-5">
//                     Aluminum has a high strength-to-weight ratio, which is
//                     important in the aerospace and automotive industries.
//                   </p>
//                 </li>
//                 <li className="font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div className="flex items-center">
//                     <div>
//                       <img
//                         src="imgs-alu/material-symbols_point-scan.png"
//                         alt=""
//                         className="mr-2"
//                       />
//                     </div>{" "}
//                     <b>Corrosion resistance: </b>
//                   </div>

//                   <p className="font-nunito font-normal text-base text-dark-gray leading-6 ml-5">
//                     Different types of aluminum have differing degrees of
//                     corrosion resistance. One of the primary benefits of one of
//                     the most often-used grades, 6061, is its resistance to
//                     corrosion.
//                   </p>
//                 </li>
//                 <li className="font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div className="flex items-center">
//                     <div>
//                       <img
//                         src="imgs-alu/material-symbols_point-scan.png"
//                         alt=""
//                         className="mr-2"
//                       />
//                     </div>{" "}
//                     <b>Electrical conductivity: </b>
//                   </div>

//                   <p className="font-nunito font-normal text-base text-dark-gray leading-6 ml-5">
//                     Metals with poorer electrical conductivity than aluminum
//                     include steel and stainless steel. This type of aluminum
//                     part is perfect for electrical and electronic components.
//                   </p>
//                 </li>
//                 <li className="font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div className="flex items-center">
//                     <div>
//                       <img
//                         src="imgs-alu/material-symbols_point-scan.png"
//                         alt=""
//                         className="mr-2"
//                       />
//                     </div>{" "}
//                     <b>Anodization and surface finishing: </b>
//                   </div>

//                   <p className="font-nunito font-normal text-base text-dark-gray leading-6 ml-5">
//                     Painting, tinting, and anodizing are all common ways to
//                     finish aluminum. An aluminum part or product with this
//                     quality can be improved in terms of function and aesthetics.
//                   </p>
//                 </li>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="container py-4">
//         <p className="font-nunito font-normal text-dark-gray text-lg ">
//           We at Narayan Aluminium Alloys Pvt. Ltd provide the ideal solution for
//           items that demand a high strength-to-weight ratio for your Machining
//           requirements. We recognize that the production material is critical in
//           the planning of a machining job.
//         </p>
//         <p className="font-nunito font-normal text-dark-gray text-lg ">
//           Get in touch with us for further information.
//         </p>
//       </div>

//       <div className="container enquiry">
//         <div className="main-heading">
//           <h1>
//             E<span>nquire No</span>w
//           </h1>
//         </div>
//         <div className="my-4 lg:p-5 p-3 w-full lg:w-8/12 bg-enquiry rounded-[10px] mx-auto">
//           <form action="" className="flex flex-wrap">
//             <div className="w-full md:w-6/12 p-2">
//               <div>
//                 <label
//                   htmlFor="name"
//                   className="block font-poppins font-medium text-lg mb-1"
//                 >
//                   Name
//                 </label>
//                 <input
//                   type="text"
//                   className="w-full p-2 border-1 border-enquiry-border rounded-[10px] bg-transparent font-poppins font-normal text-md text-dark-gray"
//                   placeholder="Name"
//                 />
//               </div>
//             </div>
//             <div className="w-full md:w-6/12 p-2">
//               <div>
//                 <label
//                   htmlFor="name"
//                   className="block font-poppins font-medium text-lg mb-1"
//                 >
//                   Phone No.
//                 </label>
//                 <input
//                   type="text"
//                   className="w-full p-2 border-1 border-enquiry-border rounded-[10px] bg-transparent font-poppins font-normal text-md text-dark-gray"
//                   placeholder="Phone no"
//                 />
//               </div>
//             </div>
//             <div className="w-full md:w-6/12 p-2">
//               <div>
//                 <label
//                   htmlFor="name"
//                   className="block font-poppins font-medium text-lg mb-1"
//                 >
//                   Email
//                 </label>
//                 <input
//                   type="text"
//                   className="w-full p-2 border-1 border-enquiry-border rounded-[10px] bg-transparent font-poppins font-normal text-md text-dark-gray"
//                   placeholder="Email"
//                 />
//               </div>
//             </div>
//             <div className="w-full md:w-6/12 p-2">
//               <div>
//                 <label
//                   htmlFor="name"
//                   className="block font-poppins font-medium text-lg mb-1"
//                 >
//                   Address
//                 </label>
//                 <input
//                   type="text"
//                   className="w-full p-2 border-1 border-enquiry-border rounded-[10px] bg-transparent font-poppins font-normal text-md text-dark-gray"
//                   placeholder="Address"
//                 />
//               </div>
//             </div>
//             <div className="w-full p-2">
//               <div>
//                 <label
//                   htmlFor="name"
//                   className="block font-poppins font-medium text-lg mb-1"
//                 >
//                   Message
//                 </label>
//                 <textarea
//                   type="text"
//                   className="w-full p-2 border-1 border-enquiry-border rounded-[10px] bg-transparent font-poppins font-normal text-md text-dark-gray"
//                   placeholder="Message"
//                 >
//                   {" "}
//                 </textarea>
//               </div>
//             </div>
//             <div className="p-2">
//               <button className="rounded-[10px] bg-enquiry-button font-poppins font-medium text-white text-lg py-1 px-4">
//                 Send
//               </button>
//             </div>
//           </form>
//         </div>
//       </div>

//       <Footer />
//     </>
//   );
// };

// export default ToolRoom;


import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link, useSearchParams } from "react-router-dom";
import EnquiryForm from "./EnquiryForm";
import { useAuth } from "../../AuthContext";
import axios from 'axios';

const ToolRoom = () => {
  const { apipath, filepath } = useAuth();
  const [toolData, setToolData] = useState(null);
  const [searchParams] = useSearchParams();
  const pageType = searchParams.get("pageType") || "Default";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apipath}/manufacturingcms/content/${pageType}`);
        // Log the data portion of the response
        //  console.log('Response Data:', response.data);
        setToolData(response.data);
      } catch (error) {
        console.error('Error fetching about data:', error);
      }
    };

    fetchData();
  }, [pageType]);

  if (!toolData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Navbar />

      <Link to="/" className="float">
        <img src="imgs-alu/whtsp.png" alt="" />
      </Link>

      <div className="container-fluid main-banner">
        <div className="heading-main">
          <h1 className="text-[48px] text-white font-candal text-center">
            Tool Room
          </h1>
          <h4 className="text-[17px] font-exo text-white font-medium text-center">
            Home{" "}
            <i className="bi bi-chevron-double-right text-light-yellow"></i>
            Tool Room
          </h4>
        </div>
      </div>

      <div className="container py-5">
        <img src={filepath + toolData.bannerImage} alt="" className="w-full" />
      </div>

      <div className="container-fluid py-5 my-3 bg-yellow-2">
        <div className="main-heading">
          <h1>
          {toolData.maintitle}
          </h1>
        </div>
        <div className="container">
          <div className="w-full lg:w-9/12 mx-auto">
            <p className="font-nunito font-normal text-[20px] leading-9 text-regal-blue">
            {toolData.headContent}
            </p>
          </div>
        </div>
      </div>

      <div className="container py-4">
        <div className="flex flex-wrap py-3">
          <div className="w-full md:w-6/12">
            <div className="p-3">
              <h2 className="font-raleway font-semibold text-[24px] text-dark-yellow">
              {toolData.body1title}
              </h2>

              <p className="pt-1 font-nunito font-normal text-lg text-dark-gray leading-8">
                {toolData.body1content[0].content}
              </p>
              <ul className="list-disc">
                <li>
                  {" "}
                  <b>{toolData.body1content[1].content}</b>
                  <p className="font-nunito font-normal text-lg text-dark-gray leading-8">
                  {toolData.body1content[2].content}
                  </p>
                </li>
                <li>
                  {" "}
                  <b>{toolData.body1content[3].content}</b>
                  <p className="font-nunito font-normal text-lg text-dark-gray leading-8">
                  {toolData.body1content[4].content}
                  </p>
                </li>
                <li>
                  {" "}
                  <b>{toolData.body1content[5].content}</b>
                  <p className="font-nunito font-normal text-lg text-dark-gray leading-8">
                  {toolData.body1content[6].content}
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="w-full md:w-6/12 flex items-center">
            <div className="p-3">
              <img className="w-full" src={filepath + toolData.sideImage} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="container pb-4">
        <div className="flex flex-wrap">
        {toolData.body2card.slice(0, 2).map((item, index) => (
          <div className="w-full lg:w-1/2 p-2" key={index}>
            <div className="shadow-custom-4 rounded-[16px] bg-white p-4">
              <h2 className="font-raleway font-semibold text-[22px] text-dark-yellow mb-3">
              {item.cardTitle}
              </h2>
              <p className="font-nunito font-normal text-base text-dark-gray leading-6">
              {item.cardContent || ""}
              </p>
              <div className="list-none">
              {item.bulletins.map((bulletin, index) => (
                <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1" key={index}>
                  <div>
                    <img
                      src="imgs-alu/material-symbols_point-scan.png"
                      alt=""
                      className="mr-2"
                    />
                  </div>{" "}
                  {bulletin}
                </li>
              ))}
              </div>
            </div>
          </div>
        ))}
        {toolData.body2card.slice(2).map((item, index) => (
          <div className="w-full lg:w-8/12 p-2 mt-3 mx-auto">
            <div className="shadow-custom-4 rounded-[16px] bg-white p-4">
              <h2 className="font-raleway font-semibold text-[22px] text-dark-yellow mb-3">
              {item.cardTitle}
              </h2>
              <p className="font-nunito font-normal text-base text-dark-gray leading-6">
              {item.cardContent || ""}
              </p>
              <div className="list-none">
              {item.bulletins.map((bulletin, index) => (
                <li className="font-exo font-normal text-lg text-dark-gray mb-1" key={index}>
                  <div className="flex items-center">
                    <div>
                      <img
                        src="imgs-alu/material-symbols_point-scan.png"
                        alt=""
                        className="mr-2"
                      />
                    </div>{" "}
                  <p className="font-nunito font-normal text-base text-dark-gray leading-6 ml-5">
                    {bulletin}
                  </p>
                  </div>
                </li>
              ))}
              </div>
            </div>
          </div>
        ))}
        </div>
      </div>

      <div className="container py-4">
        <p className="font-nunito font-normal text-dark-gray text-lg ">
          {toolData.bottomstatement}
        </p>
      </div>

      <EnquiryForm />

      <Footer />
    </>
  );
};

export default ToolRoom;
