import React, { useEffect, useRef, useState } from 'react'
import AdminSidebar from './AdminSidebar'
import axios from 'axios';
import $ from 'jquery';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext';

const PlanPriceDetails = () => {

    const navigate = useNavigate();
    const { apipath } = useAuth();
    const [planPrice, setPlanPrice] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const dataTableRef = useRef();
  
    useEffect(() => {
      fetchUsers();
    }, []);
  
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${apipath}/plansPrice/details`);
        setPlanPrice(response.data.plansPrice);
        $(dataTableRef.current).DataTable();
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };
  
    const handleDelete = async (planId) => {
      try {
        setIsLoading(true);
        await axios.delete(`${apipath}/plansPrice/details/${planId}`);
        await fetchUsers(); // Refresh courses after deletion
        setIsLoading(false);
      } catch (error) {
        console.error('Error deleting course:', error);
        setIsLoading(false);
      }
    };

    const handleUpdate = async (planId) => {
        try {
            // Redirect to the user details page with the productId as a parameter
            navigate(`/planPriceEdit/${planId}`);
        } catch (error) {
            console.log(error);
        }
    };

  return (
    <>
        <AdminSidebar />
        <div className="home">
        <div class="toggle-sidebar" style={{ display: "none" }}>
          <i class="bi bi-menu"></i>
        </div>
        <div className="dashboard">
          <div className="dashboard-header">
            <h1>Plan-Price Management</h1>
          </div>

          <div className="container-fluid">
          <div className='filter-flex'>
            <div class="input-group search-group mt-2">
              <div class="input-group-prepend search-prepend">
                <span class="input-group-text search-text" id="basic-addon1">
                  <i className="bi bi-search"></i>
                </span>
              </div>
              <input
                type="text"
                class="form-control search-control"
                placeholder="Search by name"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
              
            </div>
            <div><Link to="/planPriceAdd"> <button className="btn btn-primary"> Add PlanPrice
              </button></Link></div>
            </div>
            <div className="row foot-tablerow">
              <div className="col-lg-11 maintable-column">
                <div className="container mt-4">
                  <table id="datatable" className="table">
                  {/* <table id="datatable" className="table" ref={dataTableRef}> */}
                    <thead>
                      <tr className="tr1">
                        <th>Plans Id</th>
                        <th>Plans Name</th>
                        <th>Plans Price</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {planPrice.map((planPrices, index) => (
                      <tr key={planPrices._id}>
                        <td>{index + 1}</td>                        
                        <td>{planPrices.plansHeading}</td>
                        <td>${" "}{planPrices.plansPrice}</td>
                        <td>
                          <button className="table-btn" onClick={() => handleUpdate(planPrices._id)}><i className="fa-regular fa-pen-to-square"></i></button> &nbsp;                          
                        <button className="table-btn" onClick={() => handleDelete(planPrices._id)}><i className="fa-solid fa-trash"></i> </button>
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PlanPriceDetails