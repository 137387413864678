// import React from "react";
// import Navbar from "./Navbar";
// import Footer from "./Footer";
// import { Link } from "react-router-dom";
// import EnquiryForm from "./EnquiryForm";

// const WhyUs = () => {
//   return (
//     <>
//       <Navbar />

//       <Link to="/" className="float">
//         <img src="imgs-alu/whtsp.png" alt="" />
//       </Link>

//       <Link to="/" className="float2" title="Help">
//         <img src="imgs-alu/help.png" alt="" />
//       </Link>

//       <div className="container-fluid main-banner">
//         <div className="heading-main">
//           <h1 className="text-[48px] text-white font-candal text-center">
//             Why Choose Us
//           </h1>
//           <h4 className="text-[17px] font-exo text-white font-medium text-center">
//             Home{" "}
//             <i className="bi bi-chevron-double-right text-light-yellow"></i> Why
//             Choose Us
//           </h4>
//         </div>
//       </div>

//       <div className="container py-5">
//         <img src="imgs-alu/why.png" alt="" className="w-full" />
//       </div>

//       <div className="container py-3">
//         <div className="main-heading">
//           <h1>
//             W<span>hy should you Choose U</span>s
//           </h1>
//         </div>
//         <div className="flex flex-wrap py-3">
//           <div className="w-full md:w-6/12 lg:w-4/12 p-2">
//             <div className="p-4 bg-light-gray shadow-custom-2 rounded-md">
//               <div className="flex justify-center">
//                 <img src="imgs-alu/innovation 1.png" alt="" />
//               </div>
//               <h5 className="font-raleway font-semibold text-xl text-regal-blue text-center py-2">
//                 The latest advancements in technology in the sector:
//               </h5>
//               <p className="font-nunito font-normal text-md text-dark-gray text-center leading-5">
//                 Narayan Aluminium Alloys Pvt. Ltd creates high-quality items
//                 with the most advanced technologies on the market. A team has
//                 been created to investigate this matter and guide us in the
//                 right direction.
//               </p>
//             </div>
//           </div>
//           <div className="w-full md:w-6/12 lg:w-4/12 p-2">
//             <div className="p-4 bg-light-gray shadow-custom-2 rounded-md">
//               <div className="flex justify-center">
//                 <img src="imgs-alu/innovation 1.png" alt="" />
//               </div>
//               <h5 className="font-raleway font-semibold text-xl text-regal-blue text-center py-2">
//                 The latest advancements in technology in the sector:
//               </h5>
//               <p className="font-nunito font-normal text-md text-dark-gray text-center leading-5">
//                 Narayan Aluminium Alloys Pvt. Ltd creates high-quality items
//                 with the most advanced technologies on the market. A team has
//                 been created to investigate this matter and guide us in the
//                 right direction.
//               </p>
//             </div>
//           </div>
//           <div className="w-full md:w-6/12 lg:w-4/12 p-2">
//             <div className="p-4 bg-light-gray shadow-custom-2 rounded-md">
//               <div className="flex justify-center">
//                 <img src="imgs-alu/innovation 1.png" alt="" />
//               </div>
//               <h5 className="font-raleway font-semibold text-xl text-regal-blue text-center py-2">
//                 The latest advancements in technology in the sector:
//               </h5>
//               <p className="font-nunito font-normal text-md text-dark-gray text-center leading-5">
//                 Narayan Aluminium Alloys Pvt. Ltd creates high-quality items
//                 with the most advanced technologies on the market. A team has
//                 been created to investigate this matter and guide us in the
//                 right direction.
//               </p>
//             </div>
//           </div>
//           <div className="w-full md:w-6/12 lg:w-4/12 p-2">
//             <div className="p-4 bg-light-gray shadow-custom-2 rounded-md">
//               <div className="flex justify-center">
//                 <img src="imgs-alu/innovation 1.png" alt="" />
//               </div>
//               <h5 className="font-raleway font-semibold text-xl text-regal-blue text-center py-2">
//                 The latest advancements in technology in the sector:
//               </h5>
//               <p className="font-nunito font-normal text-md text-dark-gray text-center leading-5">
//                 Narayan Aluminium Alloys Pvt. Ltd creates high-quality items
//                 with the most advanced technologies on the market. A team has
//                 been created to investigate this matter and guide us in the
//                 right direction.
//               </p>
//             </div>
//           </div>
//           <div className="w-full md:w-6/12 lg:w-4/12 p-2">
//             <div className="p-4 bg-light-gray shadow-custom-2 rounded-md">
//               <div className="flex justify-center">
//                 <img src="imgs-alu/innovation 1.png" alt="" />
//               </div>
//               <h5 className="font-raleway font-semibold text-xl text-regal-blue text-center py-2">
//                 The latest advancements in technology in the sector:
//               </h5>
//               <p className="font-nunito font-normal text-md text-dark-gray text-center leading-5">
//                 Narayan Aluminium Alloys Pvt. Ltd creates high-quality items
//                 with the most advanced technologies on the market. A team has
//                 been created to investigate this matter and guide us in the
//                 right direction.
//               </p>
//             </div>
//           </div>
//           <div className="w-full md:w-6/12 lg:w-4/12 p-2">
//             <div className="p-4 bg-light-gray shadow-custom-2 rounded-md">
//               <div className="flex justify-center">
//                 <img src="imgs-alu/innovation 1.png" alt="" />
//               </div>
//               <h5 className="font-raleway font-semibold text-xl text-regal-blue text-center py-2">
//                 The latest advancements in technology in the sector:
//               </h5>
//               <p className="font-nunito font-normal text-md text-dark-gray text-center leading-5">
//                 Narayan Aluminium Alloys Pvt. Ltd creates high-quality items
//                 with the most advanced technologies on the market. A team has
//                 been created to investigate this matter and guide us in the
//                 right direction.
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>

//       <EnquiryForm/>

//       <Footer />
//     </>
//   );
// };

// export default WhyUs;


import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link, useSearchParams } from "react-router-dom";
import EnquiryForm from "./EnquiryForm";
import { useAuth } from "../../AuthContext";
import axios from 'axios';

const WhyUs = () => {
  const { apipath, filepath } = useAuth();
  const [whyusData, setWhyusData] = useState(null);
  const [searchParams] = useSearchParams();
  const pageType = searchParams.get("pageType") || "Default";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apipath}/aboutcms/content/${pageType}`);
        // Log the data portion of the response
        //  console.log('Response Data:', response.data);
        setWhyusData(response.data);
      } catch (error) {
        console.error('Error fetching about data:', error);
      }
    };

    fetchData();
  }, [pageType]);

  if (!whyusData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Navbar />

      <Link to="/" className="float">
        <img src="imgs-alu/whtsp.png" alt="" />
      </Link>

      <Link to="/" className="float2" title="Help">
        <img src="imgs-alu/help.png" alt="" />
      </Link>

      <div className="container-fluid main-banner">
        <div className="heading-main">
          <h1 className="text-[48px] text-white font-candal text-center">
            Why Choose Us
          </h1>
          <h4 className="text-[17px] font-exo text-white font-medium text-center">
            Home{" "}
            <i className="bi bi-chevron-double-right text-light-yellow"></i> Why Choose Us
          </h4>
        </div>
      </div>

      <div className="container py-5">
        {/* <img src="imgs-alu/why.png" alt="" className="w-full" /> */}
        <img src={filepath + whyusData.bannerImage} alt="" className="w-full" />
      </div>

      <div className="container py-3">
        <div className="main-heading">
          <h1>
            <span>{whyusData.maintitle}</span>
          </h1>
        </div>
        <div className="flex flex-wrap py-3">
          {whyusData.body1card.map((item, index) => (
            <div className="w-full md:w-6/12 lg:w-4/12 p-2" key={index}>
              <div className="p-4 bg-light-gray shadow-custom-2 rounded-md">
                <div className="flex justify-center">
                  <img src={filepath + item.cardimage} alt="" />
                </div>
                <h5 className="font-raleway font-semibold text-xl text-regal-blue text-center py-2">
                  {item.cardtitle}
                </h5>
                <p className="font-nunito font-normal text-md text-dark-gray text-center leading-5">
                  {item.cardcontent}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <EnquiryForm />

      <Footer />
    </>
  );
};

export default WhyUs;
