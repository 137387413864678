// import React from "react";
// import { Link } from "react-router-dom";
// import Navbar from "./Navbar";
// import Footer from "./Footer";

// const BillSummary = () => {
//   return (
//     <>
//       <Navbar />

//       <Link to="/" className="float">
//         <img src="imgs-alu/whtsp.png" alt="" />
//       </Link>

//       <div className="container-fluid main-banner">
//         <div className="heading-main">
//           <h1 className="text-[48px] text-white font-candal text-center">
//             Billing Summary
//           </h1>
//           <h4 className="text-[17px] font-exo text-white font-medium text-center">
//             Home{" "}
//             <i className="bi bi-chevron-double-right text-light-yellow"></i>
//             Billing Summary
//           </h4>
//         </div>
//       </div>

//       <div className="container py-4">
//         <div className="w-full lg:w-8/12 p-5 my-4 mx-auto bg-light-blue">
//             <h3 className="font-poppins font-medium text-[18px] mb-3">Order details :</h3>
//             <div className="flex justify-between py-2 border-b border-[#A1A1A1] font-inter font-normal tex-base">
//                 <span>Product Quantity : </span>
//                 <span>1</span>
//             </div>
//             <div className="flex justify-between py-2 border-b border-[#A1A1A1] font-inter font-normal tex-base">
//                 <span>Price : </span>
//                 <span>₹ 2500</span>
//             </div>
//             <div className="flex justify-between py-2 border-b border-[#A1A1A1] font-inter font-normal tex-base">
//                 <span>Shipping Charges : </span>
//                 <span>₹ 500</span>
//             </div>
//             <div className="flex justify-between py-2 border-b border-[#A1A1A1] font-inter font-normal tex-base">
//                 <span>Total : </span>
//                 <span>₹ 3000</span>
//             </div>

//         </div>
//         <div className="flex justify-center my-14">
//           <Link to="">
//             <button className="py-2 px-12 rounded-lg bg-enquiry-button font-poppins font-medium text-white text-lg border-1 border-enquiry-button hover:bg-transparent hover:!text-enquiry-button hover:ease-in-out duration-300">
//               Continue Payment
//             </button>
//           </Link>
//         </div>
//       </div>

//       <Footer />
//     </>
//   );
// };

// export default BillSummary;


// import React from "react";
// import { Link, useLocation } from "react-router-dom";
// import Navbar from "./Navbar";
// import Footer from "./Footer";

// const BillSummary = () => {
//   const location = useLocation();
//   const { cart } = location.state || { cart: [], formData: {} };

//   const calculateTotal = () => {
//     const productQuantity = cart.reduce((acc, item) => acc + item.quantity, 0);
//     const price = cart.reduce((acc, item) => acc + item.price, 0);
//     const shippingCharges = 0;
//     const total = price + shippingCharges;

//     return { productQuantity, price, shippingCharges, total };
//   };

//   const { productQuantity, price, shippingCharges, total } = calculateTotal();

//   return (
//     <>
//       <Navbar />

//       <Link to="/" className="float">
//         <img src="imgs-alu/whtsp.png" alt="" />
//       </Link>

//       <div className="container-fluid main-banner">
//         <div className="heading-main">
//           <h1 className="text-[48px] text-white font-candal text-center">
//             Billing Summary
//           </h1>
//           <h4 className="text-[17px] font-exo text-white font-medium text-center">
//             Home{" "}
//             <i className="bi bi-chevron-double-right text-light-yellow"></i>
//             Billing Summary
//           </h4>
//         </div>
//       </div>

//       <div className="container py-4">
//         <div className="w-full lg:w-8/12 p-5 my-4 mx-auto bg-light-blue">
//             <h3 className="font-poppins font-medium text-[18px] mb-3">Order details :</h3>
//             <div className="flex justify-between py-2 border-b border-[#A1A1A1] font-inter font-normal tex-base">
//                 <span>Product Quantity : </span>
//                 <span>{productQuantity}</span>
//             </div>
//             <div className="flex justify-between py-2 border-b border-[#A1A1A1] font-inter font-normal tex-base">
//                 <span>Price : </span>
//                 <span>₹ {price}</span>
//             </div>
//             <div className="flex justify-between py-2 border-b border-[#A1A1A1] font-inter font-normal tex-base">
//                 <span>Shipping Charges : </span>
//                 <span>₹ {shippingCharges}</span>
//             </div>
//             <div className="flex justify-between py-2 border-b border-[#A1A1A1] font-inter font-normal tex-base">
//                 <span>Total : </span>
//                 <span>₹ {total}</span>
//             </div>

//         </div>
//         <div className="flex justify-center my-14">
//           <Link to="">
//             <button className="py-2 px-12 rounded-lg bg-enquiry-button font-poppins font-medium text-white text-lg border-1 border-enquiry-button hover:bg-transparent hover:!text-enquiry-button hover:ease-in-out duration-300">
//               Continue Payment
//             </button>
//           </Link>
//         </div>
//       </div>

//       <Footer />
//     </>
//   );
// };

// export default BillSummary;


import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useAuth } from "../../AuthContext";

const BillSummary = () => {
  const { apipath } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { cart, formData } = location.state || { cart: [], formData: {} };

  useEffect(() => {
    // Load Razorpay script dynamically
    const script = document.createElement('script');
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const calculateTotal = () => {
    const productQuantity = cart.reduce((acc, item) => acc + item.quantity, 0);
    const price = cart.reduce((acc, item) => acc + item.price * item.quantity, 0);
    const shippingCharges = 0; // Assuming a flat shipping charge
    const total = price + shippingCharges;
    return { productQuantity, price, shippingCharges, total };
  };

  const { productQuantity, price, shippingCharges, total } = calculateTotal();

  const handlePayment = async () => {
    try {
      const token = localStorage.getItem('token');
      const { data } = await axios.post(`${apipath}/order/create-order`, {
        amount: total,
        currency: 'INR',
        orderDetails: { productQuantity, price, shippingCharges, total, cart, formData },
        billingDetails: formData
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });

      const options = {
        key: '', // Leave empty since we don't want to expose it
        amount: total * 100, // Amount in paise
        currency: 'INR',
        name: 'Narayan Aluminuium',
        description: 'Test Transaction',
        order_id: data.orderId,
        handler: async (response) => {
          try {
            const paymentData = {
              razorpayPaymentId: response.razorpay_payment_id,
              razorpayOrderId: response.razorpay_order_id,
              razorpaySignature: response.razorpay_signature,
            };
            await axios.post(`${apipath}/order/payment-success`, paymentData, {
              headers: {
                Authorization: `Bearer ${token}`
              }
            });
            navigate('/thanks');
            // alert('Payment Successful');
          } catch (error) {
            console.error(error);
            navigate('/cart');
            alert('Payment Failed');
          }
        },
        prefill: {
          name: formData.name || "Your Name",
          email: formData.email || "your.email@example.com",
          contact: formData.phone || "9999999999"
        },
        theme: {
          color: "#3399cc"
        }
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    } catch (error) {
      console.error('Error in payment:', error);
      alert('Payment Failed');
    }
  };

  return (
    <>
      <Navbar />

      <Link to="/" className="float">
        <img src="imgs-alu/whtsp.png" alt="" />
      </Link>

      <div className="container-fluid main-banner">
        <div className="heading-main">
          <h1 className="text-[48px] text-white font-candal text-center">
            Billing Summary
          </h1>
          <h4 className="text-[17px] font-exo text-white font-medium text-center">
            Home <i className="bi bi-chevron-double-right text-light-yellow"></i> Billing Summary
          </h4>
        </div>
      </div>

      <div className="container py-4">
        <div className="w-full lg:w-8/12 p-5 my-4 mx-auto bg-light-blue">
          <h3 className="font-poppins font-medium text-[18px] mb-3">Order details :</h3>
          <div className="flex justify-between py-2 border-b border-[#A1A1A1] font-inter font-normal tex-base">
            <span>Product Quantity : </span>
            <span>{productQuantity}</span>
          </div>
          <div className="flex justify-between py-2 border-b border-[#A1A1A1] font-inter font-normal tex-base">
            <span>Price : </span>
            <span>₹ {price}</span>
          </div>
          <div className="flex justify-between py-2 border-b border-[#A1A1A1] font-inter font-normal tex-base">
            <span>Shipping Charges : </span>
            <span>₹ {shippingCharges}</span>
          </div>
          <div className="flex justify-between py-2 border-b border-[#A1A1A1] font-inter font-normal tex-base">
            <span>Total : </span>
            <span>₹ {total}</span>
          </div>
        </div>
        <div className="flex justify-center my-14">
          <button
            className="py-2 px-12 rounded-lg bg-enquiry-button font-poppins font-medium text-white text-lg border-1 border-enquiry-button hover:bg-transparent hover:!text-enquiry-button hover:ease-in-out duration-300"
            onClick={handlePayment}
          >
            Continue Payment
          </button>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default BillSummary;
