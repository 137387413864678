// import React from "react";
// import Navbar from "./Navbar";
// import Footer from "./Footer";
// import { Link } from "react-router-dom";

// const About = () => {
//   return (
//     <>
//       <Navbar />

//       <Link to="/" className="float">
//       <img src="imgs-alu/whtsp.png" alt="" />
//   </Link>

//       <div className="container-fluid main-banner">
//         <div className="heading-main">
//           <h1 className="text-[48px] text-white font-candal text-center">
//             About Us
//           </h1>
//           <h4 className="text-[17px] font-exo text-white font-medium text-center">
//             Home{" "}
//             <i className="bi bi-chevron-double-right text-light-yellow"></i>{" "}
//             About Us
//           </h4>
//         </div>
//       </div>

//       <div className="container my-5 shadow-custom p-4 rounded-[25px]">
//         <p className="font-nunito text-lg text-center font-medium">
//           In all significant aluminum markets, aluminum is our fundamental
//           strength in the automotive, construction, home appliances, and
//           packaging industries. Our company's basic values are safety and
//           sustainability, and the protection of employees and the environment is
//           our top concern. We are committed to producing goods that are of value
//           and innovation. Principles like leadership, quality, and
//           sustainability, guide us.
//         </p>
//       </div>

//       <div className="container py-4">
//         <div className="main-heading">
//           <h1>
//             M<span>anagement & Risk</span>s
//           </h1>
//         </div>
//         <div className="flex flex-wrap py-3">
//           <div className="w-full md:w-6/12">
//             <div className="p-3">
//               <h2 className="font-raleway font-semibold text-[24px] text-dark-yellow">
//                 Management :
//               </h2>

//               <p className="py-3 font-nunito font-normal text-lg text-dark-gray leading-9">
//                 Good management, building a culture of compliance and addressing
//                 business risks, and fostering an environment of ethics are among
//                 the key values of Narayan Aluminium Alloys Pvt. Ltd. The success
//                 of the company depends on our capacity to act responsibly, show
//                 respect for our coworkers and stakeholders, and pay attention to
//                 and address their issues.
//               </p>

//               <h2 className="font-raleway font-semibold text-[24px] text-dark-yellow">
//                 Risks :
//               </h2>

//               <p className="py-3 font-nunito font-normal text-lg text-dark-gray leading-9">
//                 Identifying, monitoring, and eliminating our risks while taking
//                 into account our obligations to all stakeholders are the key to
//                 our effective risk management system. Our Audit and Risk
//                 Committee also monitors and evaluates our framework and
//                 methodology for risk management.
//               </p>
//             </div>
//           </div>
//           <div className="w-full md:w-6/12">
//             <div className="p-3">
//               <img className="w-full" src="imgs-alu/a1.png" alt="" />
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="container py-4">
//         <div className="main-heading">
//           <h1>
//             R<span>esearch & Desi</span>gn
//           </h1>
//         </div>
//         <div className="flex flex-wrap  py-3">
//           <div className="w-full md:w-6/12">
//             <div className="p-3">
//               <img className="w-full" src="imgs-alu/a2.png" alt="" />
//             </div>
//           </div>
//           <div className="w-full md:w-6/12">
//             <div className="p-3">
//               <p className="font-nunito font-normal text-lg text-dark-gray leading-9">
//                 Due to its innovation, Narayan Aluminium Alloys Pvt. Ltd is a
//                 market leader. With the help of our determined staff, we aim
//                 towards an environmentally sustainable world where our aluminum
//                 proves to be a key component.
//               </p>

//               <p className="pb-3 font-nunito font-normal text-lg text-dark-gray leading-9">
//                 The technological foundation for Narayan Aluminium Alloys Pvt.
//                 Ltd commercial success is provided by our technical team
//                 division. The team is assisting us in setting the standard for
//                 creating goods and procedures that support sustainable lives
//                 through creative thinking, improved customer interactions,
//                 continual improvement, and ground-breaking technological
//                 advancements
//               </p>

//               <h2 className="font-raleway font-semibold text-[24px] text-dark-yellow">
//                 Here, value generation is modeled after the following:
//               </h2>
//               <div className="flex">
//                 <div className="w-4/12 list-none">
//                   <li className="flex items-center font-nunito font-medium text-lg py-2">
//                     <div className="me-2">
//                     <img src="imgs-alu/material-symbols_point-scan.png" alt=""/>{" "}
//                     </div>
//                     Powder Coating
//                   </li>
//                   <li className="flex items-center font-nunito font-medium text-lg py-2">
//                     <div className="me-2">
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                     />{" "}
//                     </div>
//                     Anodizing
//                   </li>
//                   <li className="flex items-center font-nunito font-medium text-lg py-2">
//                     <div className="me-2">
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                     />{" "}
//                     </div>
//                     Fabrication
//                   </li>

//                 </div>
//                 <div className="w-4/12 list-none">
//                 <li className="flex items-center font-nunito font-medium text-lg py-2">
//                     <div className="me-2">
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                     />{" "}
//                     </div>
//                     Making Die
//                   </li>
//                   <li className="flex items-center font-nunito font-medium text-lg py-2">
//                     <div className="me-2">
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                     />{" "}
//                     </div>
//                     Wooden Sublimation
//                   </li>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <Footer />
//     </>
//   );
// };

// export default About;


import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link } from 'react-router-dom';
import { useAuth } from "../../AuthContext";
import axios from 'axios'; // Make sure to install axios

const About = () => {
  const { apipath, filepath } = useAuth();
  const [aboutData, setAboutData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apipath}/about-cms/details`); // Adjust the URL as needed
        setAboutData(response.data.data);
      } catch (error) {
        console.error('Error fetching about data:', error);
      }
    };

    fetchData();
  }, []);

  if (!aboutData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Navbar />

      <Link to="/" className="float">
        <img src="imgs-alu/whtsp.png" alt="" />
      </Link>

      <div className="container-fluid main-banner">
        <div className="heading-main">
          <h1 className="text-[48px] text-white font-candal text-center">
            About Us
          </h1>
          <h4 className="text-[17px] font-exo text-white font-medium text-center">
            Home{" "}
            <i className="bi bi-chevron-double-right text-light-yellow"></i>{" "}
            About Us
          </h4>
        </div>
      </div>

      <div className="container my-5 shadow-custom p-4 rounded-[25px]">
        <p className="font-nunito text-lg text-center font-medium">
          {aboutData.cardContent}
        </p>
      </div>

      {aboutData.body1.map((item, index) => (
        <div className="container py-4" key={index}>
          <div className="main-heading">
            <h1>
              {aboutData.heading1.split(' ').map((word, i) => (
                <span key={i} className={i % 2 === 0 ? '' : 'font-bold'}>{word} </span>
              ))}
            </h1>
          </div>
          <div className="flex flex-wrap py-3">
            <div className="w-full md:w-6/12">
              <div className="p-3">
                <h2 className="font-raleway font-semibold text-[24px] text-dark-yellow">
                  {item.subheading1}
                </h2>
                <p className="py-3 font-nunito font-normal text-lg text-dark-gray leading-9">
                  {item.content1}
                </p>
                <h2 className="font-raleway font-semibold text-[24px] text-dark-yellow">
                  {item.subheading2}
                </h2>
                <p className="py-3 font-nunito font-normal text-lg text-dark-gray leading-9">
                  {item.content2}
                </p>
              </div>
            </div>
            <div className="w-full md:w-6/12">
              <div className="p-3">
                <img src={filepath + aboutData.image1} alt="" />
              </div>
            </div>
          </div>
        </div>
      ))}

      {aboutData.body2.map((item, index) => (
        <div className="container py-4" key={index}>
          <div className="main-heading">
            <h1>
              {aboutData.heading2.split(' ').map((word, i) => (
                <span key={i} className={i % 2 === 0 ? '' : 'font-bold'}>{word} </span>
              ))}
            </h1>
          </div>
          <div className="flex flex-wrap py-3">
            <div className="w-full md:w-6/12">
              <div className="p-3">
              <img src={filepath + aboutData.image1} alt="" />
              </div>
            </div>
            <div className="w-full md:w-6/12">
              <div className="p-3">
                <p className="font-nunito font-normal text-lg text-dark-gray leading-9">
                  {item.content1}
                </p>
                <h2 className="font-raleway font-semibold text-[24px] text-dark-yellow">
                  {item.subheading2}
                </h2>
                <div className="flex flex-wrap">
                  <div className="w-6/12 p-2">
                    {item.bulletins && item.bulletins.slice(0, Math.ceil(item.bulletins.length / 2)).map((bulletin, bulletIndex) => (
                      <li className="flex items-center font-nunito font-medium text-lg py-2" key={bulletIndex}>
                        <div className="me-2">
                          <img src="imgs-alu/material-symbols_point-scan.png" alt="" />{" "}
                        </div>
                        {bulletin}
                      </li>
                    ))}
                  </div>
                  <div className="w-6/12 p-2">
                    {item.bulletins && item.bulletins.slice(Math.ceil(item.bulletins.length / 2)).map((bulletin, bulletIndex) => (
                      <li className="flex items-center font-nunito font-medium text-lg py-2" key={bulletIndex}>
                        <div className="me-2">
                          <img src="imgs-alu/material-symbols_point-scan.png" alt="" />{" "}
                        </div>
                        {bulletin}
                      </li>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

      <Footer />
    </>
  );
};

export default About;
