// import React from "react";
// import Navbar from "./Navbar";
// import Footer from "./Footer";
// import { Link } from "react-router-dom";
// import EnquiryForm from "./EnquiryForm";

// const Company = () => {
//   return (
//     <>
//       <Navbar />

//       <Link to="/" className="float">
//         <img src="imgs-alu/whtsp.png" alt="" />
//       </Link>

//       <Link to="/" className="float2" title="Help">
//         <img src="imgs-alu/help.png" alt="" />
//       </Link>

//       <div className="container-fluid main-banner">
//         <div className="heading-main">
//           <h1 className="text-[48px] text-white font-candal text-center">
//             Our Company
//           </h1>
//           <h4 className="text-[17px] font-exo text-white font-medium text-center">
//             Home{" "}
//             <i className="bi bi-chevron-double-right text-light-yellow"></i> 
//             Our Company
//           </h4>
//         </div>
//       </div>

//       <div className="container py-5">
//         <img src="imgs-alu/company.png" alt="" className="w-full" />
//       </div>

//       <div className="container py-3">
//         <div className="main-heading">
//           <h1>
//             O<span>ur Compan</span>y
//           </h1>
//         </div>
//         <div className="my-4 w-full md:w-9/12 mx-auto">
//           <p className="font-nunito font-normal text-xl text-dark-gray leading-9">
//             Narayan Aluminium Alloys Pvt. Ltd has a distinctive corporate
//             structure and a successful track record in project management and
//             creative problem-solving. The recurring business is proof that we
//             consistently produce high-quality work on time.
//           </p>
//           <p className="font-nunito font-normal text-xl text-dark-gray leading-9">
//             The company is acknowledged that it strengthens our country and
//             enables development in a variety of core areas. We work to improve
//             the lives of as many people as we can through all of our development
//             initiatives. We have a strong governance system and structure that
//             perfectly balances entrepreneurship, experience, and skill set. We
//             sustainably provide superior returns.  The motto of our company
//             reflects the idea of putting people before profits and serves
//           </p>
//         </div>
//       </div>

//       <EnquiryForm/>

//       <Footer />
//     </>
//   );
// };

// export default Company;


import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link, useSearchParams } from "react-router-dom";
import EnquiryForm from "./EnquiryForm";
import { useAuth } from "../../AuthContext";
import axios from 'axios';

const Company = () => {
  const { apipath, filepath } = useAuth();
  const [comapnyData, setCompanyData] = useState(null);
  const [searchParams] = useSearchParams();
  const pageType = searchParams.get("pageType") || "Default";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apipath}/aboutcms/content/${pageType}`);
        setCompanyData(response.data);
      } catch (error) {
        console.error('Error fetching about data:', error);
      }
    };

    fetchData();
  }, [pageType]);

  if (!comapnyData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Navbar />

      <Link to="/" className="float">
        <img src="imgs-alu/whtsp.png" alt="" />
      </Link>

      <Link to="/" className="float2" title="Help">
        <img src="imgs-alu/help.png" alt="" />
      </Link>

      <div className="container-fluid main-banner">
        <div className="heading-main">
          <h1 className="text-[48px] text-white font-candal text-center">
            Our Company
          </h1>
          <h4 className="text-[17px] font-exo text-white font-medium text-center">
            Home{" "}
            <i className="bi bi-chevron-double-right text-light-yellow"></i>
            Our Company
          </h4>
        </div>
      </div>

      <div className="container py-5">
        <img src={filepath + comapnyData.bannerImage} alt="" className="w-full" />
      </div>

      <div className="container py-3">
        <div className="main-heading">
          <h1>
            <span>{comapnyData.maintitle}</span>
          </h1>
        </div>
        <div className="my-4 w-full md:w-9/12 mx-auto">
          {comapnyData.body1.map((item, index) => (
            <div key={index}>
              <p className="font-nunito font-normal text-xl text-dark-gray leading-9">
                {item.content}
              </p>
            </div>
          ))}
        </div>
      </div>

      <EnquiryForm />

      <Footer />
    </>
  );
};

export default Company;
