import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AdminSidebar from './AdminSidebar';
import axios from 'axios';
import { useAuth } from '../../AuthContext';

const BillingEdit = () => {
  const { billingId } = useParams();
  const navigate = useNavigate();
  const { apipath, filepath } = useAuth();
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    const fetchBillingDetails = async () => {
      try {
        const response = await axios.get(`${apipath}/billing/details/${billingId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        console.log('Full response:', response);
        setFormData(response.data); // Directly use response.data
      } catch (error) {
        console.error("Error fetching Billing Details:", error);
      }
    };
    fetchBillingDetails();
  }, [billingId]);

  if (!formData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <AdminSidebar />
      <div className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>
        <div className="dashboard">
          <div className="dashboard-header">
            <h1>Billing Details</h1>
          </div>
          <div className="container-fluid mt-3">
            <form className="row login-form">
              <div className="mb-3 col-lg-6">
                <label htmlFor="userName" className='mb-2'>User Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="userName"
                  value={formData.userName || ''} // Ensure value is a string
                  readOnly
                />
              </div>
              <div className="mb-3 col-lg-6">
                <label htmlFor="name" className='mb-2'>Billing Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={formData.name || ''} // Ensure value is a string
                  readOnly
                />
              </div>
              <div className="mb-3 col-lg-6">
                <label htmlFor="phone" className='mb-2'>Phone Number</label>
                <input
                  type="text"
                  className="form-control"
                  name="phone"
                  value={formData.phone || ''} // Ensure value is a string
                  readOnly
                />
              </div>
              <div className="mb-3 col-lg-6">
                <label htmlFor="email" className='mb-2'>Email</label>
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  value={formData.email || ''} // Ensure value is a string
                  readOnly
                />
              </div>
              <div className="mb-3 col-lg-6">
                <label htmlFor="address" className='mb-2'>Address</label>
                <input
                  type="text"
                  className="form-control"
                  name="address"
                  value={formData.address || ''} // Ensure value is a string
                  readOnly
                />
              </div>
              <div className="mb-3 col-lg-6">
                <label htmlFor="city" className='mb-2'>City</label>
                <input
                  type="text"
                  className="form-control"
                  name="city"
                  value={formData.city || ''} // Ensure value is a string
                  readOnly
                />
              </div>
              <div className="mb-3 col-lg-6">
                <label htmlFor="state" className='mb-2'>State</label>
                <input
                  type="text"
                  className="form-control"
                  name="state"
                  value={formData.state || ''} // Ensure value is a string
                  readOnly
                />
              </div>
              <div className="mb-3 col-lg-6">
                <label htmlFor="country" className='mb-2'>Country</label>
                <input
                  type="text"
                  className="form-control"
                  name="country"
                  value={formData.country || ''} // Ensure value is a string
                  readOnly
                />
              </div>
              <div className="mb-3 col-lg-6">
                <label htmlFor="pinCode" className='mb-2'>Pin Code</label>
                <input
                  type="text"
                  className="form-control"
                  name="pinCode"
                  value={formData.pinCode || ''} // Ensure value is a string
                  readOnly
                />
              </div>
              <div className="mb-3 col-lg-12">
                <label htmlFor="message" className='mb-2'>Message</label>
                <textarea
                  className="form-control"
                  name="message"
                  value={formData.message || ''} // Ensure value is a string
                  readOnly
                />
              </div>
              <div className="mb-3 col-lg-12">
                <label htmlFor="products" className='mb-2'>Products</label>
                {formData.products && formData.products.length > 0 ? (
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Product Name</th>
                        <th>Price</th>
                        <th>Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {formData.products.map((product, index) => (
                        <tr key={index}>
                          <td>{product.productName}</td>
                          <td>{product.price}</td>
                          <td>{product.quantity}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p>No products found.</p>
                )}
              </div>
              <div className="submit-box pt-4">
                <button type="button" className="btn btn-primary" onClick={() => navigate('/billingdetails')}>
                  Back to Billing Details
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default BillingEdit;
