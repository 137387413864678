// import React, { useEffect } from "react";
// import Navbar from "./Navbar";
// import Footer from "./Footer";
// import { Link } from "react-router-dom";

// const Fabrication = () => {
//   useEffect(() => {
//     window.scrollTo({
//       top: 0,
//       left: 0,
//       behavior: "auto",
//     });
//   }, []);

//   return (
//     <>
//       <Navbar />

//       <Link to="/" className="float">
//         <img src="imgs-alu/whtsp.png" alt="" />
//       </Link>

//       <div className="container-fluid main-banner">
//         <div className="heading-main">
//           <h1 className="text-[48px] text-white font-candal text-center">
//             Fabrication
//           </h1>
//           <h4 className="text-[17px] font-exo text-white font-medium text-center">
//             Home{" "}
//             <i className="bi bi-chevron-double-right text-light-yellow"></i>
//             Fabrication
//           </h4>
//         </div>
//       </div>

//       <div className="container py-5">
//         <img src="imgs-alu/designing.png" alt="" className="w-full" />
//       </div>

//       <div className="container-fluid py-5 my-3 bg-yellow-2">
//         <div className="main-heading">
//           <h1>
//             F<span>abricatio</span>n
//           </h1>
//         </div>
//         <div className="container">
//           <div className="w-full lg:w-9/12 mx-auto">
//             <p className="font-nunito font-normal text-[20px] leading-9 text-regal-blue">
//               Streamlined procedure for converting metal into desired shapes
//               based on your specifications. With years of experience, Swain
//               Aluminum provides complete Fabrication products and services to
//               satisfy your business demands and fulfill them on time. Sections
//               and components such as bolts and protective coatings are available
//               from our specialist suppliers. Many sectors rely on us because of
//               our high-quality goods and dedication to excellence.
//             </p>
//             <p className="font-nunito font-normal text-[20px] leading-9 text-regal-blue">
//               Fabrication is a necessary step in the manufacturing process. A
//               technique that converts raw metal into a fully finished product
//               that is utilized in every sector to manufacture different tools,
//               design various commodities, and other key pieces of various types
//               of equipment.
//             </p>
//           </div>
//         </div>
//       </div>

//       <div className="container py-4">
//         <div className="flex flex-wrap py-3">
//           <div className="w-full md:w-6/12">
//             <div className="p-3">
//               <h2 className="font-raleway font-semibold text-[24px] text-dark-yellow">
//                 Fabrication Process main Equipment:
//               </h2>

//               <p className="pt-1 font-nunito font-normal text-lg text-dark-gray leading-8">
//                 <b>A high-quality and convenient option for your company</b>{" "}
//                 <br />
//                 Our aluminum fabrication technology is constantly improving
//                 across a wide range of procedures. Fabrication technologies like
//                 plasma cutting and robotics produce precise, high-quality
//                 products. On-site aluminum fabrication is more precise and
//                 faster, which helps both the client and the contractor. <br />{" "}
//                 Our highly skilled workforce and design engineers help to
//                 increase the quality of our fabrication services.
//               </p>
//             </div>
//           </div>
//           <div className="w-full md:w-6/12 flex items-center">
//             <div className="p-3">
//               <img className="w-full" src="imgs-alu/fabrication.png" alt="" />
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="container pb-4">
//         <div className="flex flex-wrap">
//           <div className="w-full lg:w-1/2 p-2">
//             <div className="shadow-custom-4 rounded-[16px] bg-white p-4">
//               <h2 className="font-raleway font-semibold text-[22px] text-dark-yellow mb-3">
//                 Fabrication has a Lot of Advantages for you:
//               </h2>

//               <div className="list-none">
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-4"
//                     />
//                   </div>{" "}
//                   Products that are visually appealing.
//                 </li>
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-4"
//                     />
//                   </div>{" "}
//                   Spend less money.
//                 </li>
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-4"
//                     />
//                   </div>{" "}
//                   Maximum precision.
//                 </li>
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-4"
//                     />
//                   </div>{" "}
//                   The well-made finished product.
//                 </li>
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-4"
//                     />
//                   </div>{" "}
//                   Simple to repair.
//                 </li>
//               </div>
//             </div>
//           </div>
//           <div className="w-full lg:w-1/2 p-2">
//             <div className="shadow-custom-4 rounded-[16px] bg-white p-4">
//               <h2 className="font-raleway font-semibold text-[22px] text-dark-yellow mb-3">
//                 Unprecedented Advantages
//               </h2>
//               <div className="list-none">
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-4"
//                     />
//                   </div>{" "}
//                   Harder and stronger metal
//                 </li>
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-4"
//                     />
//                   </div>{" "}
//                   Heat Resistant
//                 </li>
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-4"
//                     />
//                   </div>{" "}
//                   Modern Finish
//                 </li>
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-4"
//                     />
//                   </div>{" "}
//                   Durable and Malleable
//                 </li>
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-4"
//                     />
//                   </div>{" "}
//                   Cost-Effective
//                 </li>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="container py-4">
//         <p className="font-nunito font-normal text-dark-gray text-lg ">
//           Swain Aluminum specializes in a variety of metal fabrication
//           techniques. As a result, we seek to assist you to understand how each
//           procedure is carried out from beginning to end. Our relationship with
//           diverse raw material vendors and metal providers reduces or eliminates
//           the need for additional costs when sourcing raw metals for
//           manufacturing.
//         </p>
//         <p className="font-nunito font-normal text-dark-gray text-lg ">
//           If you have any concerns regarding any of our metal fabrication
//           techniques or what would be ideal for your project, please contact us
//           without any hesitation.
//         </p>
//       </div>

//       <div className="container enquiry">
//         <div className="main-heading">
//           <h1>
//             E<span>nquire No</span>w
//           </h1>
//         </div>
//         <div className="my-4 lg:p-5 p-3 w-full lg:w-8/12 bg-enquiry rounded-[10px] mx-auto">
//           <form action="" className="flex flex-wrap">
//             <div className="w-full md:w-6/12 p-2">
//               <div>
//                 <label
//                   htmlFor="name"
//                   className="block font-poppins font-medium text-lg mb-1"
//                 >
//                   Name
//                 </label>
//                 <input
//                   type="text"
//                   className="w-full p-2 border-1 border-enquiry-border rounded-[10px] bg-transparent font-poppins font-normal text-md text-dark-gray"
//                   placeholder="Name"
//                 />
//               </div>
//             </div>
//             <div className="w-full md:w-6/12 p-2">
//               <div>
//                 <label
//                   htmlFor="name"
//                   className="block font-poppins font-medium text-lg mb-1"
//                 >
//                   Phone No.
//                 </label>
//                 <input
//                   type="text"
//                   className="w-full p-2 border-1 border-enquiry-border rounded-[10px] bg-transparent font-poppins font-normal text-md text-dark-gray"
//                   placeholder="Phone no"
//                 />
//               </div>
//             </div>
//             <div className="w-full md:w-6/12 p-2">
//               <div>
//                 <label
//                   htmlFor="name"
//                   className="block font-poppins font-medium text-lg mb-1"
//                 >
//                   Email
//                 </label>
//                 <input
//                   type="text"
//                   className="w-full p-2 border-1 border-enquiry-border rounded-[10px] bg-transparent font-poppins font-normal text-md text-dark-gray"
//                   placeholder="Email"
//                 />
//               </div>
//             </div>
//             <div className="w-full md:w-6/12 p-2">
//               <div>
//                 <label
//                   htmlFor="name"
//                   className="block font-poppins font-medium text-lg mb-1"
//                 >
//                   Address
//                 </label>
//                 <input
//                   type="text"
//                   className="w-full p-2 border-1 border-enquiry-border rounded-[10px] bg-transparent font-poppins font-normal text-md text-dark-gray"
//                   placeholder="Address"
//                 />
//               </div>
//             </div>
//             <div className="w-full p-2">
//               <div>
//                 <label
//                   htmlFor="name"
//                   className="block font-poppins font-medium text-lg mb-1"
//                 >
//                   Message
//                 </label>
//                 <textarea
//                   type="text"
//                   className="w-full p-2 border-1 border-enquiry-border rounded-[10px] bg-transparent font-poppins font-normal text-md text-dark-gray"
//                   placeholder="Message"
//                 >
//                   {" "}
//                 </textarea>
//               </div>
//             </div>
//             <div className="p-2">
//               <button className="rounded-[10px] bg-enquiry-button font-poppins font-medium text-white text-lg py-1 px-4">
//                 Send
//               </button>
//             </div>
//           </form>
//         </div>
//       </div>

//       <Footer />
//     </>
//   );
// };

// export default Fabrication;


import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link, useSearchParams } from "react-router-dom";
import EnquiryForm from "./EnquiryForm";
import { useAuth } from "../../AuthContext";
import axios from 'axios';

const Fabrication = () => {
  const { apipath, filepath } = useAuth();
  const [fabricationData, setFabricationData] = useState(null);
  const [searchParams] = useSearchParams();
  const pageType = searchParams.get("pageType") || "Default";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apipath}/manufacturingcms/content/${pageType}`);
        // Log the data portion of the response
        //  console.log('Response Data:', response.data);
        setFabricationData(response.data);
      } catch (error) {
        console.error('Error fetching about data:', error);
      }
    };

    fetchData();
  }, [pageType]);

  if (!fabricationData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Navbar />

      <Link to="/" className="float">
        <img src="imgs-alu/whtsp.png" alt="" />
      </Link>

      <div className="container-fluid main-banner">
        <div className="heading-main">
          <h1 className="text-[48px] text-white font-candal text-center">
            Fabrication
          </h1>
          <h4 className="text-[17px] font-exo text-white font-medium text-center">
            Home{" "}
            <i className="bi bi-chevron-double-right text-light-yellow"></i>
            Fabrication
          </h4>
        </div>
      </div>

      <div className="container py-5">
        <img src={filepath + fabricationData.bannerImage} alt="" className="w-full" />
      </div>

      <div className="container-fluid py-5 my-3 bg-yellow-2">
        <div className="main-heading">
          <h1>
          {fabricationData.maintitle}
          </h1>
        </div>
        <div className="container">
          <div className="w-full lg:w-9/12 mx-auto">
            <p className="font-nunito font-normal text-[20px] leading-9 text-regal-blue">
            {fabricationData.headContent}
            </p>
          </div>
        </div>
      </div>

      <div className="container py-4">
        <div className="flex flex-wrap py-3">
          <div className="w-full md:w-6/12">
            <div className="p-3">
              <h2 className="font-raleway font-semibold text-[24px] text-dark-yellow">
              {fabricationData.body1title}
              </h2>

              <p className="pt-1 font-nunito font-normal text-lg text-dark-gray leading-8">
                <b>{fabricationData.body1content[0].content}</b>{" "}
                <br />
                {fabricationData.body1content[1].content}
              </p>
            </div>
          </div>
          <div className="w-full md:w-6/12 flex items-center">
            <div className="p-3">
              <img className="w-full" src={filepath + fabricationData.sideImage} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="container pb-4">
        <div className="flex flex-wrap">
        {fabricationData.body2card.map((item, index) => (
          <div className="w-full lg:w-1/2 p-2" key={index}>
            <div className="shadow-custom-4 rounded-[16px] bg-white p-4">
              <h2 className="font-raleway font-semibold text-[22px] text-dark-yellow mb-3">
              {item.cardTitle}
              </h2>

              <div className="list-none">
              {item.bulletins.map((bulletin, index) => (
                <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1" key={index}>
                  <div>
                    <img
                      src="imgs-alu/material-symbols_point-scan.png"
                      alt=""
                      className="mr-4"
                    />
                  </div>{" "}
                  {bulletin}
                </li>
              ))}
              </div>
            </div>
          </div>
        ))}
        </div>
      </div>

      <div className="container py-4">
        <p className="font-nunito font-normal text-dark-gray text-lg ">
          {fabricationData.bottomstatement}
        </p>
      </div>

      <EnquiryForm />

      <Footer />
    </>
  );
};

export default Fabrication;
