import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../pages/Navbar";
import Footer from "../pages/Footer";
import iziToast from "izitoast";
import { useAuth } from "../../AuthContext";

const Forgot = () => {

  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false); // State to track if OTP has been sent
  const { apipath } = useAuth();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  };

  const handleResendOtp = (event) => {
    event.preventDefault();
    // Send request to resend OTP
    fetch(apipath + '/mail/forgot-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email }),
    })
      .then(response => {
        if (!response.ok) {
          return response.json().then(err => {
            throw new Error(err.message || 'Incorrect email');
          });
        }
        return response.json();
      })
      .then(data => {
        iziToast.success({
          title: 'Success',
          message: 'OTP sent successfully',
          position: 'topCenter',
        });
        setOtpSent(true); // Set OTP sent state to true
      })
      .catch(error => {
        console.error('Error:', error);
        iziToast.error({
          title: 'Error',
          message: error.message || 'Failed to resend OTP, Please check your Email id',
          position: 'topCenter',
        });
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    fetch(apipath + '/mail/verify-otp', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email, otp }),
    })
      .then(response => {
        if (!response.ok) {
          return response.json().then(err => {
            throw new Error(err.message || 'Failed to verify OTP');
          });
        }
        return response.json();
      })
      .then(data => {
        iziToast.success({
          title: 'Success',
          message: data.message || 'OTP verified successfully',
          position: 'topCenter',
        });
        navigate("/reset", { state: { email } }); // Pass email to the reset page
      })
      .catch(error => {
        console.error('Error:', error);
        iziToast.error({
          title: 'Error',
          message: error.message || 'Failed to verify OTP',
          position: 'topCenter',
        });
      });
  };

  return (
    <>
      <Navbar />

      <div className="container my-5 p-2 bg-login-blue shadow-custom-login rounded-2xl flex flex-wrap">
        <div className="w-full lg:w-6/12">
          <img src="imgs-alu/login.png" alt="" className="w-full" />
        </div>
        <div className="w-full lg:w-1/2 flex flex-col justify-center items-center">
          <div className="flex items-center">
            <div>
              <img src="imgs-alu/waving-hand.png" alt="" />
            </div>
            <h1 className="font-montserrat font-semibold text-[36px] text-m-blue ml-3">
              Forgot Password ?
            </h1>
          </div>
          <p className="font-poppins font-medium text-lg text-dark-gray">
            Please enter your email
          </p>
          <form action="" className="w-full" onSubmit={handleSubmit}>
            <div className="w-full lg:w-8/12 mx-auto mt-3">
              <label
                htmlFor=""
                className="block font-poppins font-medium text-base mb-1"
              >
                Email
              </label>
              <input
                type="email"
                className="w-full p-2 bg-login-input-blue rounded-[10px] focus:outline-none font-normal font-poppins text-sm text-gray-3"
                placeholder="Enter Your email"
                value={email}
                onChange={handleEmailChange}
                readOnly={otpSent}
                required
              />
            </div>
            {otpSent && (
              <div className="w-full lg:w-8/12 mx-auto pt-4">
                <label htmlFor="otp">OTP</label>
                <input
                  type="text"
                  className="w-full p-2 bg-login-input-blue rounded-[10px] focus:outline-none font-normal font-poppins text-sm text-gray-3"
                  id="otp"
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={handleOtpChange}
                />
              </div>
            )}
            {!otpSent && (
              <div className="w-full lg:w-8/12 mx-auto pt-4">
                <button className="w-full py-[10px] bg-m-blue text-white rounded-[10px] text-center font-Poppins text-lg font-medium"
                  onClick={handleResendOtp}>
                  Send OTP <i className="bi bi-arrow-right"></i>
                </button>
              </div>
            )}
            {otpSent && (
              <div className="w-full lg:w-8/12 mx-auto pt-4">
                <button className="w-full py-[10px] bg-m-blue text-white rounded-[10px] text-center font-Poppins text-lg font-medium">
                  Continue
                </button>
              </div>
            )}
          </form>
          <p className="text-black text-center font-poppins text-base font-medium leading-8">
            Remembered your Password ?{" "}
            <Link to="/login" className="no-underline">
              <span className="text-m-blue">Login</span>
            </Link>
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Forgot;