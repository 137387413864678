import React, { createContext, useContext, useReducer, useEffect } from 'react';

const CartContext = createContext();

const cartReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_TO_CART':
      const existingItemIndex = state.findIndex(item => item.productId === action.product.productId);
      if (existingItemIndex !== -1) {
        const updatedCart = state.map((item, index) => 
          index === existingItemIndex ? { ...item, quantity: item.quantity + action.product.quantity } : item
        );
        return updatedCart;
      }
      return [...state, action.product];
    case 'REMOVE_FROM_CART':
      return state.filter((item, index) => index !== action.index);
    case 'UPDATE_QUANTITY':
      return state.map((item, index) => 
        index === action.index ? { ...item, quantity: action.quantity } : item
      );
    case 'SET_CART':
      return action.cart;
    default:
      return state;
  }
};

export const CartProvider = ({ children }) => {
  const [cart, dispatch] = useReducer(cartReducer, [], () => {
    const localData = localStorage.getItem('cart');
    return localData ? JSON.parse(localData) : [];
  });

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  return (
    <CartContext.Provider value={{ cart, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
