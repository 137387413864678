import React, { useEffect, useState } from 'react';
import AdminSidebar from './AdminSidebar';
import iziToast from "izitoast";
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useAuth } from '../../AuthContext';

const PlanPriceEdit = () => {
    const { planId } = useParams();
    const { apipath } = useAuth();
    const navigate = useNavigate();
    const [formData, setPlanEdit] = useState({
        plansHeading: "",
        plansText: "",
        plansPrice: "",
    });

    useEffect(() => {
        fetchPlanEdit();
    }, []);

    const fetchPlanEdit = async () => {
        try {
            const response = await axios.get(`${apipath}/plansPrice/details/${planId}`);
            setPlanEdit(response.data.plansPrice);
        } catch (error) {
            console.error("Error fetching Plan Details:", error);
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            await axios.put(
                `${apipath}/plansPrice/details/${planId}`,
                formData
            );
            iziToast.success({
                message: "Plan update successful",
                position: "topCenter",
            });
            navigate("/planPriceDetails");
        } catch (error) {
            console.error("Error updating Plan Details details:", error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPlanEdit((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
    };

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setPlanEdit((prevDetails) => ({
            ...prevDetails,
            plansText: data,
        }));
    };

    return (
        <>
            <AdminSidebar />
            <div className="home">
                <div className="toggle-sidebar" style={{ display: "none" }}>
                    <i className="bi bi-menu"></i>
                </div>
                <div className="dashboard">
                    <div className="dashboard-header">
                        <h1>Edit Plan</h1>
                    </div>
                    <div className="container-fluid mt-3">
                        <form className="row login-form" onSubmit={handleUpdate}>
                            <div className="mb-3 col-lg-8">
                                <label htmlFor="plansHeading" className='mb-2'>Plan Heading</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter plan Heading"
                                    name="plansHeading"
                                    value={formData.plansHeading}
                                    onChange={handleChange}
                                    maxLength="100"
                                    required
                                />
                            </div>
                            <div className="mb-3 col-lg-8">
                                <label htmlFor="plansPrice" className='mb-2'>Plan Price</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter plans price"
                                    name="plansPrice"
                                    value={formData.plansPrice}
                                    onChange={handleChange}
                                    maxLength="1000"
                                    required
                                />
                            </div>

                            <div className="mb-3 col-lg-8">
                                <label htmlFor="plansText" className='mb-2'>Plan Text</label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={formData.plansText}
                                    onChange={handleEditorChange}
                                />
                            </div>
                            <div className="submit-box pt-4">
                                <button className="btn btn-primary" type="submit">Update Plan</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PlanPriceEdit;