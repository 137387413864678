// import React, { useEffect } from "react";
// import Navbar from "./Navbar";
// import Footer from "./Footer";

// const Refund = () => {

//   useEffect(() => {
//     window.scrollTo({
//       top: 0,
//       left: 0,
//       behavior: "auto",
//     });
//   }, []);

//   return (
//     <>
//       <Navbar />

//       <div className="container privacy">
//         <h1>Refund Policy</h1>
//         <p>
//           We respect your privacy. When you give us your personal information,
//           we use it only to fulfill the transaction or service you have
//           requested.
//         </p>

//         <p>
//           We do not subscribe you to marketing emails without your consent.{" "}
//           <br />
//           We do not sell or give away your contact information to any other
//           entities. <br />
//           We do not allow the vendors who help us process transactions to sell
//           or give away your information either. <br />
//           If you have questions about how we use your information please contact
//           privacy@nngroup.com.
//         </p>

//         <h1>Detailed Refund Policy</h1>
//         <h5>1. SCOPE</h5>
//         <p>
//           This policy applies to personal information collected on websites
//           owned or controlled by Nielsen Norman Group (collectively referred to
//           in this policy as “we”, "us" or "our"). <br /> The aim of this policy
//           is to tell you how we will use any personal information we collect or
//           you provide through our websites. Please read it carefully before you
//           proceed. The data controller in respect of this website is Nielsen
//           Norman Group.
//         </p>
//         <h5>2. WHAT PERSONAL INFORMATION DO WE COLLECT?</h5>
//         <p>
//           You do not have to give us any personal information in order to use
//           most of this website. <br />
//           However, if you wish to subscribe to our newsletter, attend the UX
//           Conference, attend or purchase an Online Seminar, purchase a Research
//           Report, or request further information, we may collect the following
//           personal information from you: name, address, phone number, email
//           address, employment details, and employer details. <br />
//           We will also keep a record of any financial transaction you make with
//           us but we do not directly collect, process or store your debit or
//           credit card information. Online payments made through this website are
//           processed securely by third party payment providers.
//         </p>
//         <p>
//           We use different processors for different types of transactions and to
//           manage support for different services. For more information about how
//           your data will be handled please refer to the respective service
//           provider's privacy policy: <br />
//           Research Report Purchases: FastSpring https://fastspring.com/privacy/{" "}
//           <br />
//           Online Seminars: Crowdcast, https://www.crowdcast.io/privacy-policy{" "}
//           <br />
//           UX Conference Payments: Stripe, https://stripe.com/us/privacy
//         </p>
//         <p>
//           In addition, we may automatically collect information about the
//           website that you came from or are going to. We also collect
//           information about the pages of this website which you visit, IP
//           addresses, the type of browser you use and the times you access this
//           website. However, this information is aggregated across all visitors
//           and we do not use it to identify you.
//         </p>
//       </div>

//       <Footer />
//     </>
//   );
// };

// export default Refund;


import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import axios from 'axios';
import { useAuth } from "../../AuthContext";

const Refund = () => {
  const { apipath } = useAuth();
  const [policyData, setPolicyData] = useState(null);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apipath}/policy/details`);
        // Log the data portion of the response
        // console.log('Response Data:', response.data);
        setPolicyData(response.data);
      } catch (error) {
        console.error('Error fetching about data:', error);
      }
    };

    fetchData();
  }, []);

  if (!policyData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Navbar />

      <div className="container privacy">
        <div dangerouslySetInnerHTML={{ __html: policyData.refundpolicyContent }} />
      </div>

      <Footer />
    </>
  );
};

export default Refund;
