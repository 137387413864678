import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";

const Product = () => {

  return (
    <>
      <Navbar />
      <div className="container-fluid main-banner">
        <div className="heading-main">
          <h1 className="text-[48px] text-white font-candal text-center">
            E-Catalogue
          </h1>
          <h4 className="text-[17px] font-exo text-white font-medium text-center">
            Home{" "}
            <i className="bi bi-chevron-double-right text-light-yellow"></i>
            E-catalogue
          </h4>
        </div>
      </div>

      {/* <div className="container-fluid pt-5 pb-2 bg-yellow-2">
        <div className="container">
          <div className="w-full">
            <h2 className="font-raleway text-2xl font-semibold text-left text-[#252525]">
              Search by Categories or Section :
            </h2>
            <form className="w-full flex flex-wrap my-5">
              <div className="w-full lg:w-1/2 pr-2 mb-2">
                <select
                  name=""
                  id=""
                  className="w-full py-2 px-3 bg-white opacity-[70%] rounded-[10px] font-nunito font-medium text-lg text-input-gray"
                >
                  <option value="category">Select Category</option>
                  <option value="category">Select Category</option>
                  <option value="category">Select Category</option>
                  <option value="category">Select Category</option>
                </select>
              </div>

              <div className="flex lg:w-1/2 w-full py-2 px-3 bg-white opacity-[70%] rounded-[10px] mb-2">
                <input
                  type="text"
                  className="w-full bg-transparent rounded-[10px] focus:outline-none font-normal font-nunito text-lg text-input-gray"
                  placeholder="Search"
                />
                <span>
                  <i className="bi bi-search"></i>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div> */}

      <div className="container py-5">
        <h2 className="font-raleway font-semibold text-2xl text-dark-yellow my-2">
          We are pleased to bring to you an industry first yet again!
        </h2>

        <p className="font-nunito font-normal text-dark-gray text-lg ">
          Spend a moment with our e-catalogue and you’ll notice the attention to
          detail; it’s unique shapes and combinations coming to life. The
          software is loaded with value-added features keeping YOU in mind. This
          software is available both in on-line and a self-updating offline
          module.
        </p>

        <h2 className="font-raleway font-semibold text-2xl text-dark-yellow my-4">
          Some of our Key Features :
        </h2>

        <p className="font-nunito font-normal text-dark-gray text-lg ">
          <li>View product images & description along with other details.</li>
          <li>Search by ‘section number’.</li>
          <li>
            Choose from our on-line as well as off-line module for on-the-go
            work.
          </li>
          <li>
            Get instant access to thousands of profiles listed by different
            categories/sub-categories.
          </li>
        </p>
      </div>
      <div className="flex justify-center mb-5">
        <Link to="/product-list">
          <button className="py-2 px-12 rounded-lg bg-enquiry-button font-poppins font-medium text-white text-lg border-1 border-enquiry-button hover:bg-transparent hover:!text-enquiry-button hover:ease-in-out duration-300">
            Check Products
          </button>
        </Link>
      </div>

      <Footer />
    </>
  );
};

export default Product;
