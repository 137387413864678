// import React from "react";
// import { Link } from "react-router-dom";

// const Footer = () => {
//   return (
//     <>
//       <div className="container-fluid footer">
//         <div className="container">
//           <div className="row footer-row">
//             <div className="col-lg-3">
//               <div className="footer-content">
//                 <div className="footer-logo">
//                   <img src={`${process.env.PUBLIC_URL}/imgs-alu/logo.png`} alt="" />
//                 </div>
//                 <p>
//                   Lorem ipsum dolor sit amet, consectetur theithis adipiscing
//                   elit.{" "}
//                 </p>
//                 <div className="footer-social">
//                   <i className="bi bi-facebook"></i>
//                   <i className="bi bi-twitter"></i>
//                   <i className="bi bi-instagram"></i>
//                 </div>
//               </div>
//             </div>
//             <div className="col-lg-2">
//               <div className="footer-list">
//                 <h3>Quick Links</h3>
//                 <li>About Us</li>
//                 <li>Products</li>
//                 <li>Manufacturing</li>
//                 <li>Career</li>
//                 <li>Catalogue</li>
//                 <li>Contact Us</li>
//               </div>
//             </div>
//             <div className="col-lg-4">
//               <div className="footer-list">
//                 <h3>Address</h3>
//                 <li>
//                   <i className="bi bi-geo-alt-fill"></i> Plot No.5/4,I/4/1,
//                   Sarua Industrial Estate, Khurda, Bhubaneswar, Odisha, 752055,
//                   India
//                 </li>
//                 <li>
//                   <i className="bi bi-telephone"></i> +91 811 484 8666
//                 </li>
//                 <li>
//                   <i className="bi bi-envelope"></i> sales@narayanaluminium.com
//                 </li>
//               </div>
//             </div>
//             <div className="col-lg-3">
//               <div className="footer-list">
//                 <h3>Policies</h3>
//                 <Link to="/privacy" className="text-decoration-none">
//                   <li>Privacy Policy</li>
//                 </Link>
//                 <Link to="/refund" className="text-decoration-none">
//                   <li>Refund Policy</li>
//                 </Link>
//                 <Link to="/terms" className="text-decoration-none">
//                   <li>Terms & Conditions</li>
//                 </Link>

//                 <h6>Have questions? We're here to assist you!</h6>
//                 <div className="input-group footer-group">
//                   <input
//                     type="text"
//                     className="form-control"
//                     placeholder="Write a message"
//                     aria-label="password"
//                     aria-describedby="basic-addon2"
//                   />

//                   <div className="input-group-append footer-append">
//                     <span className="input-group-text" id="basic-addon2">
//                       {" "}
//                       Send{" "}
//                     </span>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="row footer-row2">
//             <div className="col-lg-12">
//               <p>
//                 Copyrights © 2024{" "}
//                 <b>NARAYAN ALUMINIUM Alloys PVT. LTD, All Right Reserved</b>
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Footer;



import React, { useState, useEffect } from 'react';
import { useAuth } from "../../AuthContext";
import axios from 'axios';
import { Link } from "react-router-dom";

const Footer = () => {
  const { apipath, filepath } = useAuth();
  const [footerData, setFooterData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apipath}/footer/details`); // Adjust the URL as needed
        setFooterData(response.data);
      } catch (error) {
        console.error('Error fetching about data:', error);
      }
    };

    fetchData();
  }, []);

  if (!footerData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="container-fluid footer">
        <div className="container">
          <div className="row footer-row">
            <div className="col-lg-3">
              <div className="footer-content">
                <div className="footer-logo">
                  <img src={filepath + footerData.logo} alt="" />
                </div>
                <p>
                  {footerData.discription}{" "}
                </p>
                {/* <div className="footer-social">
                  <i className="bi bi-linkedin"></i>
                  <i className="bi bi-facebook"></i>
                  <i className="bi bi-twitter"></i>
                  <i className="bi bi-instagram"></i>
                </div> */}
                <div className="footer-social">
                  <a href="https://www.linkedin.com/in/narayan-aluminium/" target="_blank">
                    <i className="bi bi-linkedin"></i>
                  </a>
                  <a href="https://www.facebook.com" target="_blank">
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a href="https://twitter.com/n_a_pvt_ltd" target="_blank">
                    <i className="bi bi-twitter"></i>
                  </a>
                  <a href="https://www.instagram.com/narayanluminium" target="_blank">
                    <i className="bi bi-instagram"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="footer-list">
                <h3>Quick Links</h3>
                <Link to="/about" className="text-decoration-none">
                  <li>About Us</li>
                </Link>
                <Link to="/products" className="text-decoration-none">
                  <li>Products</li>
                </Link>
                <Link to="/manufacturing" className="text-decoration-none">
                  <li>Manufacturing</li>
                </Link>
                <Link to="/career" className="text-decoration-none">
                  <li>Career</li>
                </Link>
                <Link to="/product-list" className="text-decoration-none">
                  <li>Catalogue</li>
                </Link>
                <Link to="/contact" className="text-decoration-none">
                  <li>Contact Us</li>
                </Link>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="footer-list">
                <h3>Address</h3>
                <li>
                  <a href="https://maps.app.goo.gl/2RM82RgnL2V5e5XW6" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <i className="bi bi-geo-alt-fill"></i> {footerData.address}
                  </a>
                </li>
                <li>
                  <i className="bi bi-telephone"></i> {footerData.phonenumber}
                </li>
                <li>
                  <a href={`mailto:${footerData.email}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <i className="bi bi-envelope"></i> {footerData.email}
                  </a>
                </li>
                <li>
                  <a href={footerData.weblink} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <i className="bi bi-globe"></i> {footerData.weblink}
                  </a>
                </li>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="footer-list">
                <h3>Policies</h3>
                <Link to="/privacy" className="text-decoration-none">
                  <li>Privacy Policy</li>
                </Link>
                <Link to="/refund" className="text-decoration-none">
                  <li>Refund Policy</li>
                </Link>
                <Link to="/terms" className="text-decoration-none">
                  <li>Terms & Conditions</li>
                </Link>

                <h6>Have questions? Contact Us and we're here to assist you!</h6>
                {/* <div className="input-group footer-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Write a message"
                    aria-label="password"
                    aria-describedby="basic-addon2"
                  />

                  <div className="input-group-append footer-append">
                    <span className="input-group-text" id="basic-addon2">
                      {" "}
                      Send{" "}
                    </span>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="row footer-row2">
            <div className="col-lg-12">
              <p>
                {footerData.copyright}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
