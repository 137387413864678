import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import axios from "axios";
import iziToast from "izitoast";
import { useAuth } from "../../AuthContext";

const Career = () => {
  const navigate = useNavigate();
  const { apipath } = useAuth();
  const [formData, setFormData] = useState({
    name: '',
    phoneNo: '',
    email: '',
    category: '',
    message: '',
    resume: '',
  });

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    if (type === 'file') {
      const file = e.target.files[0];
      setFormData({
        ...formData,
        [name]: file,
        productPicPreview: URL.createObjectURL(file), // Set the preview URL
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    const formdata = new FormData();
    formdata.append('name', formData.name);
    formdata.append('phoneNo', formData.phoneNo);
    formdata.append('email', formData.email);
    formdata.append('category', formData.category);
    formdata.append('message', formData.message);
    formdata.append('resume', formData.resume);

    try {
      const response = await axios.post(`${apipath}/career/add`,
        formdata,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      setFormData({
        name: '',
        phoneNo: '',
        email: '',
        category: '',
        message: '',
        resume: '',
      });

      if (response.status === 201) {
        iziToast.success({
          message: 'Thank you for apply',
          position: 'topCenter',
        });
        navigate('/career');
      } else {
        iziToast.error({
          message: 'Apply failed',
          position: 'topCenter',
        });
      }
    } catch (error) {
      console.error('Error apply:', error);

      if (error.response && error.response.data && error.response.data.message) {
        const errorMessage = error.response.data.message;

        if (errorMessage.includes('E11000 duplicate key error collection')) {
          if (errorMessage.includes('phoneNo')) {
            iziToast.error({
              message: 'This phone number is already registered.',
              position: 'topCenter',
            });
          } else if (errorMessage.includes('email')) {
            iziToast.error({
              message: 'This email is already registered.',
              position: 'topCenter',
            });
          } else {
            iziToast.error({
              message: 'A duplicate key error occurred.',
              position: 'topCenter',
            });
          }
        } else {
          iziToast.error({
            message: errorMessage,
            position: 'topCenter',
          });
        }
      } else {
        iziToast.error({
          message: 'An error occurred',
          position: 'topCenter',
        });
      }
    }
  };



  return (
    <>
      <Navbar />

      <Link to="/" className="float">
        <img src="imgs-alu/whtsp.png" alt="" />
      </Link>

      <div className="container-fluid main-banner">
        <div className="heading-main">
          <h1 className="text-[48px] text-white font-candal text-center">
            Careers
          </h1>
          <h4 className="text-[17px] font-exo text-white font-medium text-center">
            Home{" "}
            <i className="bi bi-chevron-double-right text-light-yellow"></i>
            Careers
          </h4>
        </div>
      </div>

      <div className="container py-4">
        <div className="main-heading">
          <h1>
            C<span>areer</span>s
          </h1>
        </div>

        <div className="w-full lg:w-8/12 p-5 my-4 mx-auto bg-light-blue rounded-xl shadow-custom-contact">
          <form action="" className="flex flex-wrap" onSubmit={handleRegister}>
            <div className="w-full lg:w-1/2 p-2">
              <div>
                <label
                  htmlFor=""
                  className="block font-poppins font-medium text-base mb-1"
                >
                  Name
                </label>
                <input
                  type="text"
                  placeholder="Enter your name"
                  className="w-full p-2 bg-transparent border-1 rounded-lg border-input-border focus:outline-none font-normal font-poppins text-sm text-gray-3"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  maxLength="100"
                  required
                />
              </div>
            </div>
            <div className="w-full lg:w-1/2 p-2">
              <div>
                <label
                  htmlFor=""
                  className="block font-poppins font-medium text-base mb-1"
                >
                  Email
                </label>
                <input
                  type="email"
                  placeholder="narayanaliminum@xyz.com"
                  className="w-full p-2 bg-transparent border-1 rounded-lg border-input-border focus:outline-none font-normal font-poppins text-sm text-gray-3"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  maxLength="100"
                  required
                />
              </div>
            </div>
            <div className="w-full lg:w-1/2 p-2">
              <div>
                <label
                  htmlFor=""
                  className="block font-poppins font-medium text-base mb-1"
                >
                  Phone no.
                </label>
                <input
                  type="text"
                  placeholder="+91-00000-00000"
                  className="w-full p-2 bg-transparent border-1 rounded-lg border-input-border focus:outline-none font-normal font-poppins text-sm text-gray-3"
                  name="phoneNo"
                  value={formData.phoneNo}
                  onChange={handleChange}
                  maxLength="15"
                  autoComplete="off"
                  onKeyPress={(event) => {
                    if (event.charCode < 48 || event.charCode > 57 || event.target.value.length >= 15) {
                      event.preventDefault();
                    }
                  }}
                  required
                />
              </div>
            </div>
            <div className="w-full lg:w-1/2 p-2">
              <div>
                <label
                  htmlFor="category"
                  className="block font-poppins font-medium text-base mb-1"
                >
                  Select Category
                </label>
                <select
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                  required
                  className="w-full p-2 bg-transparent border-1 rounded-lg border-input-border focus:outline-none font-normal font-poppins text-sm text-gray-3"
                >
                  <option value="">Select a category</option>
                  <option value="Marketing">Marketing</option>
                  <option value="Office">Office</option>
                </select>
              </div>
            </div>

            <div className="w-full p-2">
              <div>
                <label
                  htmlFor=""
                  className="block font-poppins font-medium text-base mb-1"
                >
                  Message
                </label>
                <textarea
                  type="text"
                  placeholder="Enter Message"
                  className="w-full p-2 bg-transparent border-1 rounded-lg border-input-border focus:outline-none font-normal font-poppins text-sm text-gray-3"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  maxLength="100"
                  required
                >
                  {" "}
                </textarea>
              </div>
            </div>
            <div className="w-full p-2">
              <div>
              <label
                  htmlFor=""
                  className="block font-poppins font-medium text-base mb-1"
                >
                  Upload resume here:
                </label>
                <input
                  type="file"
                  id="fileInput"
                  name="resume"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="mt-4">
              <button className="py-2 px-12 rounded-pill bg-enquiry-button font-poppins font-medium text-white text-lg border-1 border-enquiry-button hover:bg-transparent hover:!text-enquiry-button hover:ease-in-out duration-300">
                Submit
              </button>
            </div>
          </form>
        </div>

      </div>

      <Footer />
    </>
  );
};

export default Career;
