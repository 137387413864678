import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link } from "react-router-dom";

const Catalogue = () => {
  return (
    <>
      <Navbar />

      <Link to="/" className="float">
        <img src="imgs-alu/whtsp.png" alt="" />
      </Link>

      <div className="container-fluid main-banner">
        <div className="heading-main">
          <h1 className="text-[48px] text-white font-candal text-center">
            Catalogue
          </h1>
          <h4 className="text-[17px] font-exo text-white font-medium text-center">
            Home{" "}
            <i className="bi bi-chevron-double-right text-light-yellow"></i>
            Catalogue
          </h4>
        </div>
      </div>

      <div className="container py-4">
        <div className="main-heading">
          <h1>
            C<span>atalogu</span>e
          </h1>
        </div>
      </div>

      <div className="container my-5 p-5 border-1 border-regal-blue rounded-lg flex flex-wrap">
        <div className="w-full lg:w-4/12 md:w-6/12 mb-3 lg:mb-0">
          <img src="imgs-alu/catalogue-2.png" alt="" className="w-full" />
        </div>
        <div className="w-full lg:w-8/12 md:w-6/12 px-3">
          <h1 className="font-raleway font-bold text-2xl">Product Name</h1>
          <div>
            <i className="bi bi-star-fill text-dark-yellow mx-1"></i>
            <i className="bi bi-star-fill text-dark-yellow mx-1"></i>
            <i className="bi bi-star-fill text-dark-yellow mx-1"></i>
            <i className="bi bi-star-fill text-dark-yellow mx-1"></i>
          </div>
          <p className="font-nunito font-normal text-lg leading-8 py-2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit{" "}
          </p>
          <h4 className="font-nunito font-semibold text-[20px]">$ 200</h4>
          <div className="mt-4">
            <button className="py-2 px-12 rounded-lg bg-enquiry-button font-poppins font-medium text-white text-lg border-1 border-enquiry-button hover:bg-transparent hover:!text-enquiry-button hover:ease-in-out duration-300">Buy Now</button>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Catalogue;
