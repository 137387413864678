// import React, { useEffect } from "react";
// import Navbar from "./Navbar";
// import Footer from "./Footer";
// import { Link } from "react-router-dom";

// const Anodizing = () => {
//   useEffect(() => {
//     window.scrollTo({
//       top: 0,
//       left: 0,
//       behavior: "auto",
//     });
//   }, []);

//   return (
//     <>
//       <Navbar />

//       <Link to="/" className="float">
//         <img src="imgs-alu/whtsp.png" alt="" />
//       </Link>

//       <div className="container-fluid main-banner">
//         <div className="heading-main">
//           <h1 className="text-[48px] text-white font-candal text-center">
//             Anodizing
//           </h1>
//           <h4 className="text-[17px] font-exo text-white font-medium text-center">
//             Home{" "}
//             <i className="bi bi-chevron-double-right text-light-yellow"></i>
//             Anodizing
//           </h4>
//         </div>
//       </div>

//       <div className="container py-5">
//         <img src="imgs-alu/anodizing.png" alt="" className="w-full" />
//       </div>

//       <div className="container-fluid py-5 my-3 bg-yellow-2">
//         <div className="main-heading">
//           <h1>
//             A<span>nodizin</span>g
//           </h1>
//         </div>
//         <div className="container">
//           <div className="w-full lg:w-9/12 mx-auto">
//             <p className="font-nunito font-normal text-[20px] leading-9 text-regal-blue">
//               Our anodizing method enhances the surface of the metal’s corrosion
//               and erosion resistance. Narayan Aluminium Alloys Pvt. Ltd is
//               determined to become a one-stop shop for aluminum, extrusion,
//               fabrication, anodizing, etc. with the goal of providing
//               manufacturing products and services to its customers. We offer
//               high-quality products and services with our expertise and
//               competence. Our trusted services provide on-time delivery and
//               outstanding solutions
//             </p>
//             <p className="font-nunito font-normal text-[20px] leading-9 text-regal-blue">
//               Anodizing, an electrochemical surface treatment, creates
//               anti-corrosive, durable, and dependable metal surfaces. Base
//               materials are visually appealing with an anodic oxide cover layer.
//               Aluminum and its alloys are typically anodized to provide an
//               aluminum oxide coating. Aluminum anodize results in the formation
//               of a thin oxide coating (anodic layer) on it or its alloys. The
//               surface thermal and electrical conductivity of metal is likewise
//               reduced.
//             </p>
//           </div>
//         </div>
//       </div>

//       <div className="container py-4">
//         <div className="flex flex-wrap py-3">
//           <div className="w-full md:w-6/12">
//             <div className="p-3">
//               <h2 className="font-raleway font-semibold text-[24px] text-dark-yellow">
//                 Our Anodizing Method :
//               </h2>

//               <p className="pt-1 font-nunito font-normal text-lg text-dark-gray leading-8">
//                 It is important to clean the surface of any dirt, grease, and
//                 other undesired particles. If you require your product for
//                 decorative purposes, we offer two pretreatment options: bright
//                 dipping to give the material a shining appearance and etching to
//                 achieve a matte smooth end finish.
//               </p>
//               <ul className="list-disc">
//                 <li className="font-nunito font-normal text-lg text-dark-gray leading-8">
//                   Aluminum or an alloy of aluminum is anodized by immersing it
//                   in an electrolytic solution, such as sulfuric acid, with a
//                   cathode.
//                 </li>
//                 <li className="font-nunito font-normal text-lg text-dark-gray leading-8">
//                   The aluminum alloy is completely dissolved in water, resulting
//                   in the formation of a cell via the flow of electricity. During
//                   this procedure, aluminum acts as the anode, easily oxidizing
//                   in an electrolytic solution.
//                 </li>
//                 <li className="font-nunito font-normal text-lg text-dark-gray leading-8">
//                   Anodizing produces a thicker, more efficient aluminum oxide
//                   layer (anodic layer) which usually occurs naturally.
//                 </li>
//                 <li className="font-nunito font-normal text-lg text-dark-gray leading-8">
//                   The electrolytic coloring procedure is used to give an
//                   anodized metal a colored appearance. Colorants are applied to
//                   metal parts in this way. Integral color is another way, but it
//                   is more expensive
//                 </li>
//               </ul>
//             </div>
//           </div>
//           <div className="w-full md:w-6/12 flex items-center">
//             <div className="p-3">
//               <img className="w-full" src="imgs-alu/anodizing2.png" alt="" />
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="container pb-4">
//         <div className="flex flex-wrap">
//           <div className="w-full lg:w-1/2 p-2">
//             <div className="shadow-custom-4 rounded-[16px] bg-white p-4">
//               <h2 className="font-raleway font-semibold text-[22px] text-dark-yellow mb-3">
//                 The most Popular Anodizing Applications:
//               </h2>

//               <div className="list-none">
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-4"
//                     />
//                   </div>{" "}
//                   Products for commercial and residential buildings.
//                 </li>
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-4"
//                     />
//                   </div>{" "}
//                   Food preparation tools.
//                 </li>
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-4"
//                     />
//                   </div>{" "}
//                   Sporting goods Furniture.
//                 </li>
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-4"
//                     />
//                   </div>{" "}
//                   Components of a motor vehicle.
//                 </li>
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-4"
//                     />
//                   </div>{" "}
//                   Jewelry Production.
//                 </li>
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-4"
//                     />
//                   </div>{" "}
//                   Jewelry Production.
//                 </li>
//               </div>
//             </div>
//           </div>
//           <div className="w-full lg:w-1/2 p-2">
//             <div className="shadow-custom-4 rounded-[16px] bg-white p-4">
//               <h2 className="font-raleway font-semibold text-[22px] text-dark-yellow mb-3">
//                 Advantage of Anodizing for your Business:
//               </h2>
//               <p className="font-nunito font-normal text-base text-dark-gray leading-2">
//                 Only the anodized finish fits all of the following criteria when
//                 it comes to selecting a high-performance aluminum finish:
//               </p>
//               <div className="list-none flex justify-around">
//                 <div>
//                   <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                     <div>
//                       <img
//                         src="imgs-alu/material-symbols_point-scan.png"
//                         alt=""
//                         className="mr-4"
//                       />
//                     </div>{" "}
//                     Long life expectancy.
//                   </li>
//                   <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                     <div>
//                       <img
//                         src="imgs-alu/material-symbols_point-scan.png"
//                         alt=""
//                         className="mr-4"
//                       />
//                     </div>{" "}
//                     Economic benefits.
//                   </li>
//                   <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                     <div>
//                       <img
//                         src="imgs-alu/material-symbols_point-scan.png"
//                         alt=""
//                         className="mr-4"
//                       />
//                     </div>{" "}
//                     Low maintenance fees
//                   </li>
//                   <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                     <div>
//                       <img
//                         src="imgs-alu/material-symbols_point-scan.png"
//                         alt=""
//                         className="mr-4"
//                       />
//                     </div>{" "}
//                     Significant operational savings.
//                   </li>
//                 </div>
//                 <div>
//                   <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                     <div>
//                       <img
//                         src="imgs-alu/material-symbols_point-scan.png"
//                         alt=""
//                         className="mr-4"
//                       />
//                     </div>{" "}
//                     Colors are maintained.
//                   </li>
//                   <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                     <div>
//                       <img
//                         src="imgs-alu/material-symbols_point-scan.png"
//                         alt=""
//                         className="mr-4"
//                       />
//                     </div>{" "}
//                     Ultraviolet ray resistance.
//                   </li>
//                   <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                     <div>
//                       <img
//                         src="imgs-alu/material-symbols_point-scan.png"
//                         alt=""
//                         className="mr-4"
//                       />
//                     </div>{" "}
//                     There is no peeling or chipping.
//                   </li>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="w-full lg:w-1/2 p-2 mx-auto my-3">
//             <div className="shadow-custom-4 rounded-[16px] bg-white p-4">
//               <h2 className="font-raleway font-semibold text-[22px] text-dark-yellow mb-3">
//                 Industries relying on our high-quality anodizing service:
//               </h2>
//               <div className="list-none">
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-4"
//                     />
//                   </div>{" "}
//                   Solar Power
//                 </li>
//                 <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1">
//                   <div>
//                     <img
//                       src="imgs-alu/material-symbols_point-scan.png"
//                       alt=""
//                       className="mr-4"
//                     />
//                   </div>{" "}
//                   Mechanical Hardware
//                 </li>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="container py-4">
//         <p className="font-nunito font-normal text-dark-gray text-lg ">
//           We provide anodizing services to create metal components that are
//           long-lasting, sturdy, high-quality, aesthetically appealing and
//           require little maintenance. On-time delivery and up-to-date shipping
//           notifications are also indicators of our performance. Many sectors
//           rely on us because of our unrivaled dedication and high-quality
//           products.
//         </p>
//         <p className="font-nunito font-normal text-dark-gray text-lg ">
//           Please contact Narayan Aluminium Alloys Pvt. Ltd if you would like
//           more information about anodizing or other procedures.
//         </p>
//       </div>

//       <div className="container enquiry">
//         <div className="main-heading">
//           <h1>
//             E<span>nquire No</span>w
//           </h1>
//         </div>
//         <div className="my-4 lg:p-5 p-3 w-full lg:w-8/12 bg-enquiry rounded-[10px] mx-auto">
//           <form action="" className="flex flex-wrap">
//             <div className="w-full md:w-6/12 p-2">
//               <div>
//                 <label
//                   htmlFor="name"
//                   className="block font-poppins font-medium text-lg mb-1"
//                 >
//                   Name
//                 </label>
//                 <input
//                   type="text"
//                   className="w-full p-2 border-1 border-enquiry-border rounded-[10px] bg-transparent font-poppins font-normal text-md text-dark-gray"
//                   placeholder="Name"
//                 />
//               </div>
//             </div>
//             <div className="w-full md:w-6/12 p-2">
//               <div>
//                 <label
//                   htmlFor="name"
//                   className="block font-poppins font-medium text-lg mb-1"
//                 >
//                   Phone No.
//                 </label>
//                 <input
//                   type="text"
//                   className="w-full p-2 border-1 border-enquiry-border rounded-[10px] bg-transparent font-poppins font-normal text-md text-dark-gray"
//                   placeholder="Phone no"
//                 />
//               </div>
//             </div>
//             <div className="w-full md:w-6/12 p-2">
//               <div>
//                 <label
//                   htmlFor="name"
//                   className="block font-poppins font-medium text-lg mb-1"
//                 >
//                   Email
//                 </label>
//                 <input
//                   type="text"
//                   className="w-full p-2 border-1 border-enquiry-border rounded-[10px] bg-transparent font-poppins font-normal text-md text-dark-gray"
//                   placeholder="Email"
//                 />
//               </div>
//             </div>
//             <div className="w-full md:w-6/12 p-2">
//               <div>
//                 <label
//                   htmlFor="name"
//                   className="block font-poppins font-medium text-lg mb-1"
//                 >
//                   Address
//                 </label>
//                 <input
//                   type="text"
//                   className="w-full p-2 border-1 border-enquiry-border rounded-[10px] bg-transparent font-poppins font-normal text-md text-dark-gray"
//                   placeholder="Address"
//                 />
//               </div>
//             </div>
//             <div className="w-full p-2">
//               <div>
//                 <label
//                   htmlFor="name"
//                   className="block font-poppins font-medium text-lg mb-1"
//                 >
//                   Message
//                 </label>
//                 <textarea
//                   type="text"
//                   className="w-full p-2 border-1 border-enquiry-border rounded-[10px] bg-transparent font-poppins font-normal text-md text-dark-gray"
//                   placeholder="Message"
//                 >
//                   {" "}
//                 </textarea>
//               </div>
//             </div>
//             <div className="p-2">
//               <button className="rounded-[10px] bg-enquiry-button font-poppins font-medium text-white text-lg py-1 px-4">
//                 Send
//               </button>
//             </div>
//           </form>
//         </div>
//       </div>

//       <Footer />
//     </>
//   );
// };

// export default Anodizing;


import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link, useSearchParams } from "react-router-dom";
import EnquiryForm from "./EnquiryForm";
import { useAuth } from "../../AuthContext";
import axios from 'axios';

const Anodizing = () => {
  const { apipath, filepath } = useAuth();
  const [anodizingData, setAnodizingData] = useState(null);
  const [searchParams] = useSearchParams();
  const pageType = searchParams.get("pageType") || "Default";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apipath}/manufacturingcms/content/${pageType}`);
        // Log the data portion of the response
        //  console.log('Response Data:', response.data);
        setAnodizingData(response.data);
      } catch (error) {
        console.error('Error fetching about data:', error);
      }
    };

    fetchData();
  }, [pageType]);

  if (!anodizingData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Navbar />

      <Link to="/" className="float">
        <img src="imgs-alu/whtsp.png" alt="" />
      </Link>

      <div className="container-fluid main-banner">
        <div className="heading-main">
          <h1 className="text-[48px] text-white font-candal text-center">
            Anodizing
          </h1>
          <h4 className="text-[17px] font-exo text-white font-medium text-center">
            Home{" "}
            <i className="bi bi-chevron-double-right text-light-yellow"></i>
            Anodizing
          </h4>
        </div>
      </div>

      <div className="container py-5">
        <img src={filepath + anodizingData.bannerImage} alt="" className="w-full" />
      </div>

      <div className="container-fluid py-5 my-3 bg-yellow-2">
        <div className="main-heading">
          <h1>
            {anodizingData.maintitle}
          </h1>
        </div>
        <div className="container">
          <div className="w-full lg:w-9/12 mx-auto">
            <p className="font-nunito font-normal text-[20px] leading-9 text-regal-blue">
              {anodizingData.headContent}
            </p>
          </div>
        </div>
      </div>

      <div className="container py-4">
        <div className="flex flex-wrap py-3">
          <div className="w-full md:w-6/12">
            <div className="p-3">
              <h2 className="font-raleway font-semibold text-[24px] text-dark-yellow">
                {anodizingData.body1title}
              </h2>

              <p className="pt-1 font-nunito font-normal text-lg text-dark-gray leading-8">
                {anodizingData.body1content[0].content}
              </p>
              <ul className="list-disc">
                {anodizingData.body1content.slice(1,).map((item, index) => (
                  <li className="font-nunito font-normal text-lg text-dark-gray leading-8" key={index}>
                    {item.content}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="w-full md:w-6/12 flex items-center">
            <div className="p-3">
              <img className="w-full" src={filepath + anodizingData.sideImage} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="container pb-4">
        <div className="flex flex-wrap">
          {anodizingData.body2card.slice(0, 1).map((item, index) => (
            <div className="w-full lg:w-1/2 p-2" key={index}>
              <div className="shadow-custom-4 rounded-[16px] bg-white p-4">
                <h2 className="font-raleway font-semibold text-[22px] text-dark-yellow mb-3">
                  {item.cardTitle}
                </h2>
                <p className="font-nunito font-normal text-base text-dark-gray leading-2">
                  {item.cardContent || ""}
                </p>
                <div className="list-none">
                  {item.bulletins.map((bulletin, index) => (
                    <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1" key={index}>
                      <div>
                        <img
                          src="imgs-alu/material-symbols_point-scan.png"
                          alt=""
                          className="mr-4"
                        />
                      </div>{" "}
                      {bulletin}
                    </li>
                  ))}
                </div>
              </div>
            </div>
          ))}
          {anodizingData.body2card.slice(1, 2).map((item, index) => (
          <div className="w-full lg:w-1/2 p-2">
            <div className="shadow-custom-4 rounded-[16px] bg-white p-4">
              <h2 className="font-raleway font-semibold text-[22px] text-dark-yellow mb-3">
              {item.cardTitle}
              </h2>
              <p className="font-nunito font-normal text-base text-dark-gray leading-2">
              {item.cardContent || ""}
              </p>
              <div className="list-none flex justify-around">
                <div>
                {item.bulletins.slice(0,4).map((bulletin, index) => (
                  <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1" key={index}>
                    <div>
                      <img
                        src="imgs-alu/material-symbols_point-scan.png"
                        alt=""
                        className="mr-4"
                      />
                    </div>{" "}
                    {bulletin}
                  </li>
                ))}
                </div>
                <div>
                {item.bulletins.slice(4, ).map((bulletin, index) => (
                  <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1" key={index}>
                    <div>
                      <img
                        src="imgs-alu/material-symbols_point-scan.png"
                        alt=""
                        className="mr-4"
                      />
                    </div>{" "}
                    {bulletin}
                  </li>
                ))}
                </div>
              </div>
            </div>
          </div>
          ))}
          {anodizingData.body2card.slice(2, ).map((item, index) => (
          <div className="w-full lg:w-1/2 p-2 mx-auto my-3" key={index}>
            <div className="shadow-custom-4 rounded-[16px] bg-white p-4">
              <h2 className="font-raleway font-semibold text-[22px] text-dark-yellow mb-3">
                {item.cardTitle}
              </h2>
              <p>
                {item.cardContent || ""}
              </p>
              <div className="list-none">
              {item.bulletins.map((bulletin, index) => (
                <li className="flex items-center font-exo font-normal text-lg text-dark-gray mb-1" key={index}>
                  <div>
                    <img
                      src="imgs-alu/material-symbols_point-scan.png"
                      alt=""
                      className="mr-4"
                    />
                  </div>{" "}
                  {bulletin}
                </li>
              ))}
              </div>
            </div>
          </div>
          ))}
        </div>
      </div>

      <div className="container py-4">
        <p className="font-nunito font-normal text-dark-gray text-lg ">
          {anodizingData.bottomstatement}
        </p>
      </div>

      <EnquiryForm />

      <Footer />
    </>
  );
};

export default Anodizing;
