import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import iziToast from 'izitoast';
import Navbar from "../pages/Navbar";
import Footer from "../pages/Footer";
import { useAuth } from "../../AuthContext";

const OtpVerification = () => {
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { email, phone } = location.state;
  const [showOTP, setShowOTP] = useState(false);
  const { apipath } = useAuth();

  const handleVisibility = () => {
    setShowOTP(!showOTP);
  };

  const handleChange = (e) => {
    setOtp(e.target.value);
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!otp) {
      setError('OTP is required');
      return;
    }

    try {
      const response = await fetch(`${apipath}/users/verify-otp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, otp }),
      });

      const data = await response.json();

      if (response.status === 200) {
        iziToast.success({
          title: 'Success',
          message: 'OTP verified successfully!',
          position: 'topCenter',
          timeout: 2000,
        });
        navigate('/login');
      } else {
        iziToast.error({
          title: 'Error',
          message: data.message || 'Invalid OTP. Please try again.',
          position: 'topCenter',
        });
      }
    } catch (error) {
      iziToast.error({
        title: 'Error',
        message: 'Verification failed. Please try again later.',
        position: 'topCenter',
      });
    }
  };

  return (

    <>
      <Navbar />

      <div className="container my-5 p-2 bg-login-blue shadow-custom-login rounded-2xl flex flex-wrap">
        <div className="w-full lg:w-6/12">
          <img src="imgs-alu/login.png" alt="" className="w-full" />
        </div>
        <div className="w-full lg:w-1/2 flex flex-col justify-center items-center">
          <div className="flex items-center">
            <div>
              <img src="imgs-alu/waving-hand.png" alt="" />
            </div>
          </div>
          <p className="font-poppins font-medium text-lg text-dark-gray">
            Please verify your Phone Number {phone}
          </p>
          <form action="" className="w-full" onSubmit={handleSubmit}>
            <div className="w-full lg:w-8/12 mx-auto my-3">
              <label
                htmlFor=""
                className="block font-poppins font-medium text-base mb-1"
              >
                Enter OTP
              </label>
              <div className="flex w-full p-2 bg-login-input-blue rounded-[10px]">
                <input
                  type={"text"}
                  className="w-full bg-login-input-blue rounded-[10px] focus:outline-none font-normal font-poppins text-sm text-gray-3"
                  placeholder="Enter your OTP"
                  name="password"
                  value={otp}
                  onChange={handleChange}
                  required
                  maxlength="6"
                />
              </div>
            </div>

            <div className="w-full lg:w-8/12 mx-auto pt-3">
              <button className="w-full py-[10px] bg-m-blue text-white rounded-[10px] text-center font-Poppins text-lg font-medium">
                Submit OTP
              </button>
            </div>
          </form>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default OtpVerification;
