// import React from "react";
// import Navbar from "./Navbar";
// import Footer from "./Footer";
// import { Link } from "react-router-dom";

// const Manufacturing = () => {
//   return (
//     <>
//       <Navbar />

//       <Link to="/" className="float">
//         <img src="imgs-alu/whtsp.png" alt="" />
//       </Link>

//       <div className="container-fluid main-banner">
//         <div className="heading-main">
//           <h1 className="text-[48px] text-white font-candal text-center">
//             Manufacturing
//           </h1>
//           <h4 className="text-[17px] font-exo text-white font-medium text-center">
//             Home{" "}
//             <i className="bi bi-chevron-double-right text-light-yellow"></i>
//             Manufacturing
//           </h4>
//         </div>
//       </div>

//       <div className="container py-5">
//         <div className="main-heading">
//           <h1>
//             E<span>xplore our Manufacturing Categorie</span>s
//           </h1>
//         </div>
//         <div className="flex flex-wrap">
//           <div className="w-full md:w-6/12 lg:w-4/12 p-2">
//             <div className="bg-m-blue bottom-5 border-b-dark-yellow shadow-custom-2 rounded-[10px]">
//               <div>
//                 <img src="imgs-alu/m1.png" alt="" className="w-full" />
//               </div>
//               <h3 className="font-candal text-white text-center py-3 text-[28px]">
//                 Extrusion
//               </h3>
//               <div className="flex justify-center pb-4">
//                 <Link to="/extrusion">
//                 <button className="border-2 border-white rounded-md px-[25px] py-1 font-poppins text-xl font-medium hover:bg-white hover:!text-m-blue text-white ease-in-out duration-300">
//                   View Details
//                 </button>
//                 </Link>
//               </div>
//             </div>
//           </div>
//           <div className="w-full md:w-6/12 lg:w-4/12 p-2">
//             <div className="bg-m-blue bottom-5 border-b-dark-yellow shadow-custom-2 rounded-[10px]">
//               <div>
//                 <img src="imgs-alu/m2.png" alt="" className="w-full" />
//               </div>
//               <h3 className="font-candal text-white text-center py-3 text-[28px]">
//                 Powder Coating
//               </h3>
//               <div className="flex justify-center pb-4">
//                 <Link to="/powder">
//                 <button className="border-2 border-white rounded-md px-[25px] py-1 font-poppins text-xl font-medium hover:bg-white hover:!text-m-blue text-white ease-in-out duration-300">
//                   View Details
//                 </button>
//                 </Link>
//               </div>
//             </div>
//           </div>
//           <div className="w-full md:w-6/12 lg:w-4/12 p-2">
//             <div className="bg-m-blue bottom-5 border-b-dark-yellow shadow-custom-2 rounded-[10px]">
//               <div>
//                 <img src="imgs-alu/m3.png" alt="" className="w-full" />
//               </div>
//               <h3 className="font-candal text-white text-center py-3 text-[28px]">
//                 Anodizing
//               </h3>
//               <div className="flex justify-center pb-4">
//                 <Link to="/anodizing">
//                 <button className="border-2 border-white rounded-md px-[25px] py-1 font-poppins text-xl font-medium hover:bg-white hover:!text-m-blue text-white ease-in-out duration-300">
//                   View Details
//                 </button>
//                 </Link>
//               </div>
//             </div>
//           </div>
//           <div className="w-full md:w-6/12 lg:w-4/12 p-2">
//             <div className="bg-m-blue bottom-5 border-b-dark-yellow shadow-custom-2 rounded-[10px]">
//               <div>
//                 <img src="imgs-alu/m4.png" alt="" className="w-full" />
//               </div>
//               <h3 className="font-candal text-white text-center py-3 text-[28px]">
//                 Fabrication
//               </h3>
//               <div className="flex justify-center pb-4">
//                 <Link to="/fabrication">
//                 <button className="border-2 border-white rounded-md px-[25px] py-1 font-poppins text-xl font-medium hover:bg-white hover:!text-m-blue text-white ease-in-out duration-300">
//                   View Details
//                 </button>
//                 </Link>
//               </div>
//             </div>
//           </div>
//           <div className="w-full md:w-6/12 lg:w-4/12 p-2">
//             <div className="bg-m-blue bottom-5 border-b-dark-yellow shadow-custom-2 rounded-[10px]">
//               <div>
//                 <img src="imgs-alu/m6.png" alt="" className="w-full" />
//               </div>
//               <h3 className="font-candal text-white text-center py-3 text-[28px]">
//                 Billet Making
//               </h3>
//               <div className="flex justify-center pb-4">
//                 <Link to="/billet">
//                 <button className="border-2 border-white rounded-md px-[25px] py-1 font-poppins text-xl font-medium hover:bg-white hover:!text-m-blue text-white ease-in-out duration-300">
//                   View Details
//                 </button>
//                 </Link>
//               </div>
//             </div>
//           </div>
//           <div className="w-full md:w-6/12 lg:w-4/12 p-2">
//             <div className="bg-m-blue bottom-5 border-b-dark-yellow shadow-custom-2 rounded-[10px]">
//               <div>
//                 <img src="imgs-alu/m5.png" alt="" className="w-full" />
//               </div>
//               <h3 className="font-candal text-white text-center py-3 text-[28px]">
//                 Tool Room
//               </h3>
//               <div className="flex justify-center pb-4">
//                 <Link to="/toolroom">
//                 <button className="border-2 border-white rounded-md px-[25px] py-1 font-poppins text-xl font-medium hover:bg-white hover:!text-m-blue text-white ease-in-out duration-300">
//                   View Details
//                 </button>
//                 </Link>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <Footer />
//     </>
//   );
// };

// export default Manufacturing;


import React, { useState, useEffect } from 'react';
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import axios from 'axios';

const Manufacturing = () => {
  const { apipath, filepath } = useAuth();
  const [imageData, setImageData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apipath}/manufacture/details`); // Adjust the URL as needed
                setImageData(response.data);
            } catch (error) {
                console.error('Error fetching about data:', error);
            }
        };

        fetchData();
    }, []);

    if (!imageData) {
        return <div>Loading...</div>;
    }
  return (
    <>
      <Navbar />

      <Link to="/" className="float">
        <img src="imgs-alu/whtsp.png" alt="" />
      </Link>

      <div className="container-fluid main-banner">
        <div className="heading-main">
          <h1 className="text-[48px] text-white font-candal text-center">
            Manufacturing
          </h1>
          <h4 className="text-[17px] font-exo text-white font-medium text-center">
            Home{" "}
            <i className="bi bi-chevron-double-right text-light-yellow"></i>
            Manufacturing
          </h4>
        </div>
      </div>

      <div className="container py-5">
        <div className="main-heading">
          <h1>
            <span>{imageData.Maintitle}</span>
          </h1>
        </div>
        <div className="flex flex-wrap">
          <div className="w-full md:w-6/12 lg:w-4/12 p-2">
            <div className="bg-m-blue bottom-5 border-b-dark-yellow shadow-custom-2 rounded-[10px]">
              <div>
                <img src={filepath + imageData.ExtrusionImage} alt="" className="w-full" />
              </div>
              <h3 className="font-candal text-white text-center py-3 text-[28px]">
                Extrusion
              </h3>
              <div className="flex justify-center pb-4">
                <Link to="/extrusion?pageType=Extrusion">
                <button className="border-2 border-white rounded-md px-[25px] py-1 font-poppins text-xl font-medium hover:bg-white hover:!text-m-blue text-white ease-in-out duration-300">
                  View Details
                </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="w-full md:w-6/12 lg:w-4/12 p-2">
            <div className="bg-m-blue bottom-5 border-b-dark-yellow shadow-custom-2 rounded-[10px]">
              <div>
                <img src={filepath + imageData.PowderCoatingImage} alt="" className="w-full" />
              </div>
              <h3 className="font-candal text-white text-center py-3 text-[28px]">
                Powder Coating
              </h3>
              <div className="flex justify-center pb-4">
                <Link to="/powder?pageType=Powder%20Coating">
                <button className="border-2 border-white rounded-md px-[25px] py-1 font-poppins text-xl font-medium hover:bg-white hover:!text-m-blue text-white ease-in-out duration-300">
                  View Details
                </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="w-full md:w-6/12 lg:w-4/12 p-2">
            <div className="bg-m-blue bottom-5 border-b-dark-yellow shadow-custom-2 rounded-[10px]">
              <div>
                <img src={filepath + imageData.AnodizingImage} alt="" className="w-full" />
              </div>
              <h3 className="font-candal text-white text-center py-3 text-[28px]">
                Anodizing
              </h3>
              <div className="flex justify-center pb-4">
                <Link to="/anodizing?pageType=Anodizing">
                <button className="border-2 border-white rounded-md px-[25px] py-1 font-poppins text-xl font-medium hover:bg-white hover:!text-m-blue text-white ease-in-out duration-300">
                  View Details
                </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="w-full md:w-6/12 lg:w-4/12 p-2">
            <div className="bg-m-blue bottom-5 border-b-dark-yellow shadow-custom-2 rounded-[10px]">
              <div>
                <img src={filepath + imageData.FabricationImage} alt="" className="w-full" />
              </div>
              <h3 className="font-candal text-white text-center py-3 text-[28px]">
                Fabrication
              </h3>
              <div className="flex justify-center pb-4">
                <Link to="/fabrication?pageType=Fabrication">
                <button className="border-2 border-white rounded-md px-[25px] py-1 font-poppins text-xl font-medium hover:bg-white hover:!text-m-blue text-white ease-in-out duration-300">
                  View Details
                </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="w-full md:w-6/12 lg:w-4/12 p-2">
            <div className="bg-m-blue bottom-5 border-b-dark-yellow shadow-custom-2 rounded-[10px]">
              <div>
                <img src={filepath + imageData.BilletMakingImage} alt="" className="w-full" />
              </div>
              <h3 className="font-candal text-white text-center py-3 text-[28px]">
                Billet Making
              </h3>
              <div className="flex justify-center pb-4">
                <Link to="/billet?pageType=Billet%20Making">
                <button className="border-2 border-white rounded-md px-[25px] py-1 font-poppins text-xl font-medium hover:bg-white hover:!text-m-blue text-white ease-in-out duration-300">
                  View Details
                </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="w-full md:w-6/12 lg:w-4/12 p-2">
            <div className="bg-m-blue bottom-5 border-b-dark-yellow shadow-custom-2 rounded-[10px]">
              <div>
                <img src={filepath + imageData.ToolRoomImage} alt="" className="w-full" />
              </div>
              <h3 className="font-candal text-white text-center py-3 text-[28px]">
                Tool Room
              </h3>
              <div className="flex justify-center pb-4">
                <Link to="/toolroom?pageType=Tool%20Room">
                <button className="border-2 border-white rounded-md px-[25px] py-1 font-poppins text-xl font-medium hover:bg-white hover:!text-m-blue text-white ease-in-out duration-300">
                  View Details
                </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="w-full md:w-6/12 lg:w-4/12 p-2">
            <div className="bg-m-blue bottom-5 border-b-dark-yellow shadow-custom-2 rounded-[10px]">
              <div>
                <img src={filepath + imageData.ColoringImage} alt="" className="w-full" />
              </div>
              <h3 className="font-candal text-white text-center py-3 text-[28px]">
                Coloring
              </h3>
              <div className="flex justify-center pb-4">
                <Link to="/coloring?pageType=Coloring">
                <button className="border-2 border-white rounded-md px-[25px] py-1 font-poppins text-xl font-medium hover:bg-white hover:!text-m-blue text-white ease-in-out duration-300">
                  View Details
                </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="w-full md:w-6/12 lg:w-4/12 p-2">
            <div className="bg-m-blue bottom-5 border-b-dark-yellow shadow-custom-2 rounded-[10px]">
              <div>
                <img src={filepath + imageData.DesigningImage} alt="" className="w-full" />
              </div>
              <h3 className="font-candal text-white text-center py-3 text-[28px]">
                Designing
              </h3>
              <div className="flex justify-center pb-4">
                <Link to="/designing?pageType=Designing">
                <button className="border-2 border-white rounded-md px-[25px] py-1 font-poppins text-xl font-medium hover:bg-white hover:!text-m-blue text-white ease-in-out duration-300">
                  View Details
                </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Manufacturing;
