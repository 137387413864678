import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Navbar from "../pages/Navbar";
import Footer from "../pages/Footer";
import iziToast from "izitoast";
import { useAuth } from "../../AuthContext";

const Reset = () => {
  const [showPassword, setShowPassword] = useState();

  const handleVisibility = (index) => {
    setShowPassword(showPassword === index ? null : index);
  };

  const location = useLocation();
  const navigate = useNavigate();
  const email = location.state?.email || ''; // Retrieve email passed from the previous component
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  // const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  // const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const { apipath } = useAuth();

  // const toggleNewPasswordVisibility = () => {
  //   setNewPasswordVisible(!newPasswordVisible);
  // };

  // const toggleConfirmPasswordVisibility = () => {
  //   setConfirmPasswordVisible(!confirmPasswordVisible);
  // };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (newPassword !== confirmPassword) {
      iziToast.error({
        title: 'Error',
        message: 'Passwords do not match',
        position: 'topCenter',
      });
      return;
    }

    fetch(apipath + '/mail/update-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email, password: newPassword }),
    })
      .then(response => {
        if (!response.ok) {
          return response.json().then(err => {
            throw new Error(err.message || 'Failed to update password');
          });
        }
        return response.json();
      })
      .then(data => {
        iziToast.success({
          title: 'Success',
          message: data.message || 'Password updated successfully',
          position: 'topCenter',
        });
        navigate("/login"); // Redirect to login page after successful password reset
      })
      .catch(error => {
        console.error('Error:', error);
        iziToast.error({
          title: 'Error',
          message: error.message || 'Failed to update password',
          position: 'topCenter',
        });
      });
  };

  return (
    <>
      <Navbar />

      <div className="container my-5 px-2 py-5 bg-login-blue shadow-custom-login rounded-2xl flex flex-wrap">
        <div className="w-full lg:w-6/12 flex items-center">
          <img src="imgs-alu/login.png" alt="" className="w-full" />
        </div>
        <div className="w-full lg:w-1/2 flex flex-col justify-center items-center">
          <div className="flex items-center">
            <div>
              <img src="imgs-alu/waving-hand.png" alt="" />
            </div>
            <h1 className="font-montserrat font-semibold text-[36px] text-m-blue ml-3">
              Reset Password
            </h1>
          </div>
          <p className="font-poppins font-medium text-lg text-dark-gray">
          Please Enter New Password
          </p>

          <form action="" className="w-full" onSubmit={handleSubmit}>
          <div className="w-full lg:w-8/12 mx-auto my-3">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    className="flex w-full p-2 bg-login-input-blue rounded-[10px]"
                    id="email"
                    value={email}
                    readOnly
                  />

                </div>
          <div className="w-full lg:w-8/12 mx-auto my-3">
            <label
              htmlFor=""
              className="block font-poppins font-medium text-base mb-1"
            >
              New Password
            </label>
            <div className="flex w-full p-2 bg-login-input-blue rounded-[10px]">
              <input
                type={showPassword === 1 ? "text" : "password"}
                className="w-full bg-login-input-blue rounded-[10px] focus:outline-none font-normal font-poppins text-sm text-gray-3"
               placeholder="Enter your new password"
                id="newPassword"
                      value={newPassword}
                      onChange={handleNewPasswordChange}
              />
              <span>
                <i
                  className={`cursor-pointer ${
                    showPassword === 1 ? "bi bi-eye-slash" : "bi bi-eye"
                  }`}
                  onClick={() => handleVisibility(1)}
                ></i>
              </span>
            </div>
          </div>
          <div className="w-full lg:w-8/12 mx-auto my-3">
            <label
              htmlFor=""
              className="block font-poppins font-medium text-base mb-1"
            >
                Confirm Password
            </label>
            <div className="flex w-full p-2 bg-login-input-blue rounded-[10px]">
              <input
                type={showPassword === 2 ? "text" : "password"}
                className="w-full bg-login-input-blue rounded-[10px] focus:outline-none font-normal font-poppins text-sm text-gray-3"
               placeholder="Enter your confirm password"
                id="confirmPassword"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
              />
              <span>
                <i
                  className={`cursor-pointer ${
                    showPassword === 2 ? "bi bi-eye-slash" : "bi bi-eye"
                  }`}
                  onClick={() => handleVisibility(2)}
                ></i>
              </span>
            </div>
          </div>

          <div className="w-full lg:w-8/12 mx-auto pt-3">
              <button className="w-full py-[10px] bg-m-blue text-white rounded-[10px] text-center font-Poppins text-lg font-medium">
                Continue
              </button>
          </div>
          </form>
          <p className="text-black text-center font-poppins text-base font-medium leading-8">
            Remembered your Password ?{" "}
            <Link to="/login" className="no-underline">
              <span className="text-m-blue">Login</span>
            </Link>
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Reset;