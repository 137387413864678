import React, { useState, useEffect } from 'react';
import AdminSidebar from '../admin/AdminSidebar';
import iziToast from 'izitoast';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../AuthContext";

const AboutCmsPageAdd = () => {
    const { apipath } = useAuth();
    const [formData, setFormData] = useState({
        pageType: '',
        bannerImage: null,
        headContent: '',
        maintitle: '',
        body1: [{ title: '', content: '' }],
        body1card: [{ cardtitle: '', cardcontent: '', cardimage: null }],
    });
    const [images, setImages] = useState({ bannerImage: null, cardImages: [] });
    const navigate = useNavigate();

    useEffect(() => {
        if (formData.pageType) {
            const fetchData = async () => {
                try {
                    const response = await fetch(`${apipath}/aboutcms/content/${formData.pageType}`);
                    if (response.ok) {
                        const result = await response.json();
                        setFormData({
                            ...result,
                            body1: result.body1 || [{ title: '', content: '' }],
                            body1card: result.body1card || [{ cardtitle: '', cardcontent: '', cardimage: null }],
                        });
                        setImages({
                            bannerImage: result.bannerImage ? { name: result.bannerImage } : null,
                            cardImages: result.body1card.map(card => card.cardimage ? { name: card.cardimage } : null),
                        });
                    } else {
                        iziToast.error({
                            message: 'Failed to load data',
                            position: 'topCenter',
                        });
                    }
                } catch (error) {
                    iziToast.error({
                        message: `An error occurred: ${error.message}`,
                        position: 'topCenter',
                    });
                }
            };

            fetchData();
        }
    }, [formData.pageType]);

    const handleChange = (e, field) => {
        const { value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    const handleBodyChange = (section, index, field, value) => {
        setFormData((prevData) => {
            const updatedSection = [...prevData[section]];
            updatedSection[index] = {
                ...updatedSection[index],
                [field]: value,
            };
            return { ...prevData, [section]: updatedSection };
        });
    };

    const handleAddBodyItem = (section) => {
        setFormData((prevData) => ({
            ...prevData,
            [section]: [...prevData[section], section === 'body1' ? { title: '', content: '' } : { cardtitle: '', cardcontent: '', cardimage: null }],
        }));
    };

    const handleRemoveBodyItem = (section, index) => {
        setFormData((prevData) => {
            const updatedSection = [...prevData[section]];
            updatedSection.splice(index, 1);
            return { ...prevData, [section]: updatedSection };
        });
    };

    const handleCardImageChange = (index, e) => {
        const file = e.target.files[0];

        if (file) {
            setImages((prevImages) => {
                const updatedCardImages = [...prevImages.cardImages];
                updatedCardImages[index] = file;
                return { ...prevImages, cardImages: updatedCardImages };
            });

            setFormData((prevData) => {
                const updatedBody1card = [...prevData.body1card];
                updatedBody1card[index] = {
                    ...updatedBody1card[index],
                    cardimage: file,
                };
                return { ...prevData, body1card: updatedBody1card };
            });
        }
    };

    const handleFileChange = (e, field) => {
        setImages((prevImages) => ({
            ...prevImages,
            [field]: e.target.files[0],
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = new FormData();
        data.append('pageType', formData.pageType);
        data.append('headContent', formData.headContent);
        data.append('maintitle', formData.maintitle);
        data.append('body1', JSON.stringify(formData.body1));
        data.append('body1card', JSON.stringify(formData.body1card));

        if (images.bannerImage) {
            data.append('bannerImage', images.bannerImage);
        }

        // images.cardImages.forEach((image, index) => {
        //     if (image) {
        //         data.append('cardImages', image); // No need for array index in field name
        //     }
        // });

        images.cardImages.forEach((image, index) => {
            if (image instanceof File) {
                data.append('cardImages', image, image.name);
            }
        });

        try {
            const response = await fetch(`${apipath}/aboutcms/content`, {
                method: 'POST',
                body: data,
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Network response was not ok: ${errorText}`);
            }

            const result = await response.json();

            if (result.success) {
                iziToast.success({
                    message: 'CMS Page added successfully',
                    position: 'topCenter',
                });
                // navigate('/cmsDetails');
            } else {
                iziToast.error({
                    message: `CMS Page addition failed: ${result.error || 'Unknown error'}`,
                    position: 'topCenter',
                });
            }
        } catch (error) {
            console.error('Error adding CMS page:', error);
            iziToast.error({
                message: `An error occurred: ${error.message}`,
                position: 'topCenter',
            });
        }
    };




    // const handleUpdateContent = async (req, res) => {
    //     try {
    //         const { pageType, headContent, maintitle, body1, body1card } = req.body;

    //         // Parse and validate JSON fields
    //         const parsedBody1 = JSON.parse(body1);
    //         const parsedBody1Card = JSON.parse(body1card);

    //         // Perform your logic to update or add CMS content
    //         // ...

    //         // Example success response
    //         res.json({ success: true });

    //     } catch (error) {
    //         console.error('Error processing request:', error);
    //         res.status(400).json({ error: 'Invalid data or failed processing' });
    //     }
    // };


    const renderFieldsByPageType = () => {
        return (
            <>
                <div className="mb-3 col-lg-12">
                    <label htmlFor="headContent" className="mb-2">
                        Header Content
                    </label>
                    <input
                        type="text"
                        id="headContent"
                        className="form-control"
                        placeholder="Enter header content"
                        value={formData.headContent}
                        onChange={(e) => handleChange(e, 'headContent')}
                    />
                </div>
                <div className="mb-3 col-lg-12">
                    <label htmlFor="maintitle" className="mb-2">
                        Main Title
                    </label>
                    <input
                        type="text"
                        id="maintitle"
                        className="form-control"
                        placeholder="Enter main title"
                        value={formData.maintitle}
                        onChange={(e) => handleChange(e, 'maintitle')}
                    />
                </div>
                <div className="mb-3 col-lg-12">
                    <label htmlFor="bannerImage" className="mt-2">
                        Banner Image
                    </label>
                    <input
                        type="file"
                        id="bannerImage"
                        className="form-control"
                        accept=".png, .jpg, .jpeg"
                        onChange={(e) => handleFileChange(e, 'bannerImage')}
                    />
                    {images.bannerImage && images.bannerImage.name && (
                        <div className="text-muted">
                            Existing file: {images.bannerImage.name}
                        </div>
                    )}
                </div>
                <div className="mb-3 col-lg-12">
                    <h3>Body Content</h3>
                    {formData.body1.map((item, index) => (
                        <div key={index} className="mb-3">
                            <input
                                type="text"
                                className="form-control mb-2"
                                placeholder="Title"
                                value={item.title}
                                onChange={(e) => handleBodyChange('body1', index, 'title', e.target.value)}
                            />
                            <textarea
                                className="form-control"
                                rows={2}
                                placeholder="Content"
                                value={item.content}
                                onChange={(e) => handleBodyChange('body1', index, 'content', e.target.value)}
                            />
                            <button type="button" className="btn btn-danger mt-2" onClick={() => handleRemoveBodyItem('body1', index)}>
                                Remove
                            </button>
                        </div>
                    ))}
                    <button type="button" className="btn btn-secondary" onClick={() => handleAddBodyItem('body1')}>
                        Add New Body Item
                    </button>
                </div>
                <div className="mb-3 col-lg-12">
                    <h3>Body Card</h3>
                    {formData.body1card.map((card, index) => (
                        <div key={index} className="mb-3">
                            <input
                                type="text"
                                className="form-control mb-2"
                                placeholder="Card Title"
                                value={card.cardtitle}
                                onChange={(e) => handleBodyChange('body1card', index, 'cardtitle', e.target.value)}
                            />
                            <textarea
                                className="form-control"
                                rows={2}
                                placeholder="Card Content"
                                value={card.cardcontent}
                                onChange={(e) => handleBodyChange('body1card', index, 'cardcontent', e.target.value)}
                            />
                            <input
                                type="file"
                                className="form-control mt-2"
                                accept=".png, .jpg, .jpeg"
                                onChange={(e) => handleCardImageChange(index, e)}
                            />
                            {images.cardImages[index] && images.cardImages[index].name && (
                                <div className="text-muted">
                                    Existing file: {images.cardImages[index].name}
                                </div>
                            )}
                            <button type="button" className="btn btn-danger mt-2" onClick={() => handleRemoveBodyItem('body1card', index)}>
                                Remove
                            </button>
                        </div>
                    ))}
                    <button type="button" className="btn btn-secondary" onClick={() => handleAddBodyItem('body1card')}>
                        Add New Card
                    </button>
                </div>
            </>
        );
    };

    return (
        <>
            <AdminSidebar />
            <div className="home">
                <div className="toggle-sidebar" style={{ display: 'none' }}>
                    <i className="bi bi-menu"></i>
                </div>
                <div className="dashboard">
                    <div className="dashboard-header">
                        <h1>Add About CMS Page</h1>
                    </div>
                    <div className="container-fluid mt-3">
                        <form className="row login-form" onSubmit={handleSubmit}>
                            <div className="mb-3 col-lg-12">
                                <label htmlFor="pageType" className="mb-2">
                                    Page Type
                                </label>
                                <select
                                    id="pageType"
                                    className="form-control"
                                    value={formData.pageType}
                                    onChange={(e) => setFormData((prevData) => ({ ...prevData, pageType: e.target.value }))}
                                >
                                    <option value="">Select Page Type</option>
                                    {/* <option value="about">About Us</option> */}
                                    <option value="Why Us">Why Us</option>
                                    <option value="Our Company">Our Company</option>
                                    <option value="Our Mission">Our Mission</option>
                                    <option value="Our Vision">Our Vision</option>
                                </select>
                            </div>
                            {formData.pageType && renderFieldsByPageType()}
                            <div className="col-lg-12 mt-3 text-center">
                                <button type="submit" className="btn btn-primary">
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AboutCmsPageAdd;
