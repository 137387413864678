import React, { useState, useEffect } from "react";
import axios from "axios";
import { format } from 'date-fns';
import { useAuth } from "../../AuthContext";

const Stepper = ({ orderId }) => {
  const { apipath } = useAuth();
  const [currentStep, setCurrentStep] = useState(-1);
  const [steps, setSteps] = useState([
    { label: "Order Placed", key: "orderUpdate", date: null, dateField: "paymentDate" },
    { label: "Dispatched", key: "dispatchUpdate", date: null, dateField: "dispatchDate" },
    { label: "Delivered", key: "deliverUpdate", date: null, dateField: "deliverDate" },
  ]);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          const response = await axios.get(`${apipath}/order/orderdetails/${orderId}`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          const order = response.data;
          setCurrentStep(getCurrentStep(order));
          setSteps(prevSteps => prevSteps.map(step => ({
            ...step,
            date: getStepDate(order, step.key, step.dateField)
          })));
        } else {
          console.error("No token found");
        }
      } catch (error) {
        console.error("Error fetching order details", error);
      }
    };

    fetchOrderDetails();
  }, [orderId]);

  const getCurrentStep = (order) => {
    for (let i = steps.length - 1; i >= 0; i--) {
      if (order[steps[i].key] && order[steps[i].key].length > 0) return i;
    }
    return -1;
  };

  const getStepDate = (order, stepKey, dateField) => {
    if (order[stepKey] && order[stepKey].length > 0) {
      return format(new Date(order[stepKey][0][dateField]), 'dd MMM yyyy');
    }
    return "N/A";
  };

  return (
    <div className="order-tracker">
      {steps.map((step, index) => (
        <div key={index} className="step">
          <div className={`circle ${index <= currentStep ? "complete" : ""}`}>
            {index <= currentStep ? "✓" : ""}
          </div>
          <div className={`label ${index <= currentStep ? "complete" : ""}`}>
            {step.label}
            {/* <br /> */}
            <span className={`date ${index <= currentStep ? "complete" : ""}`}>
              {step.date}
            </span>
          </div>
          {index < steps.length - 1 && (
            <div className={`line-container`}>
              <div
                className={`line ${index < currentStep ? "complete" : ""}`}
              ></div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Stepper;
