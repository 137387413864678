import React, { useEffect, useState } from 'react';
import AdminSidebar from "./AdminSidebar";
import axios from 'axios';
import { useAuth } from '../../AuthContext';
// import { Pie } from "react-chartjs-2";
// import ChartDataLabels from 'chartjs-plugin-datalabels';

const AdminDashboard = () => {
  const { apipath } = useAuth();
  const [productCount, setProductCount] = useState(0);

  // const data1 = [
  //   { value: 45, label: "45%" },
  //   { value: 10, label: '10%' },
  //   { value: 45, label: '45%' },
  // ];
  // const data2 = [
  //   { value: 45, label: "45%" },
  //   { value: 10, label: '10%' },
  //   { value: 45, label: '45%' },
  // ];

  useEffect(() => {
    const fetchUserCount = async () => {
      try {
        const response = await axios.get(`${apipath}/product/details`);
        setProductCount(response.data.productCount);
      } catch (error) {
        console.error('Error fetching user count:', error);
      }
    };

    fetchUserCount();
  }, []);


  return (
    <>
      <AdminSidebar />

      <div className="home">
        <div class="toggle-sidebar" style={{ display: "none" }}>
          <i class="bi bi-menu"></i>
        </div>
        <div className="dashboard">
          <div className="dashboard-header">
            <h1>Dashboard</h1>
          </div>

          <div className="row dash-row">
            <div className="col-lg-4">
              <div className="dash-card">
                <div className="dash-1">
                  <h3>Product Listed</h3>
                  <span>
                    <i className="bi bi-arrow-up"></i> 3%
                  </span>
                </div>
                <div className="dash-2">
                  <div>
                    <h2>{productCount}</h2>
                    {/* <h2>2700</h2> */}
                    <h5>0 last year</h5>
                  </div>
                  <div>
                    <img src="assets/Vector 1.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="dash-card">
                <div className="dash-1">
                  <h3>Total Order</h3>
                  <span>
                    <i className="bi bi-arrow-up"></i> 3%
                  </span>
                </div>
                <div className="dash-2">
                  <div>
                    <h2>0</h2>
                    <h5>0 last year</h5>
                  </div>
                  <div>
                    <img src="assets/Vector 1.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="dash-card">
                <div className="dash-1">
                  <h3>Total Earning</h3>
                  <span>
                    <i className="bi bi-arrow-up"></i> 3%
                  </span>
                </div>
                <div className="dash-2">
                  <div>
                    <h2>₹ 0</h2>
                    <h5>0 last year</h5>
                  </div>
                  <div>
                    <img src="assets/Vector 1.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="row dash-row2">
            <div className="col-lg-4">
              <div className="dash-box">
                <h2>Sales Data</h2>
                <h5>March 2020</h5>
                <div className="pie-label">
                  <div className="d-flex">
                    <span className="span1"> </span>
                    <p> Profit Margin</p>
                  </div>
                  <div className="d-flex">
                    <span className="span2"> </span>
                    <p> Selling Price</p>
                  </div>
                  <div className="d-flex">
                    <span className="span3"> </span>
                    <p> Purchase Cost</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="dash-box">
                <h2>Distributed Data</h2>
                <h5>March 2020</h5>
                <div className="pie-label">
                  <div className="d-flex">
                    <span className="span1"> </span>
                    <p> Amazon Fees</p>
                  </div>
                  <div className="d-flex">
                    <span className="span2"> </span>
                    <p> Supplier Fees</p>
                  </div>
                  <div className="d-flex">
                    <span className="span3"> </span>
                    <p> Taxes</p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;