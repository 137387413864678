// import React from "react";
// import Navbar from "./Navbar";
// import Footer from "./Footer";
// import { Link } from "react-router-dom";
// import EnquiryForm from "./EnquiryForm";

// const Vision = () => {
//   return (
//     <>
//       <Navbar />

//       <Link to="/" className="float">
//         <img src="imgs-alu/whtsp.png" alt="" />
//       </Link>

//       <Link to="/" className="float2" title="Help">
//         <img src="imgs-alu/help.png" alt="" />
//       </Link>

//       <div className="container-fluid main-banner">
//         <div className="heading-main">
//           <h1 className="text-[48px] text-white font-candal text-center">
//             Our Vision
//           </h1>
//           <h4 className="text-[17px] font-exo text-white font-medium text-center">
//             Home{" "}
//             <i className="bi bi-chevron-double-right text-light-yellow"></i>
//             Our Vision
//           </h4>
//         </div>
//       </div>

//       <div className="container py-5">
//         <img src="imgs-alu/vision.png" alt="" className="w-full" />
//       </div>

//       <div className="my-4 w-full md:w-9/12 mx-auto">
//         <p className="font-nunito font-normal text-xl text-dark-gray leading-9">
//           Our mission is to be a customer-centric organization. It is vital to
//           provide a quality product for our customers, a good return on their
//           investment, and a successful outcome for their building project.
//         </p>
//         <p className="font-nunito font-normal text-xl text-dark-gray leading-9">
//           Our procedures and strategies are built on the principle of
//           customer-centricity. Essentially, the driving idea for determining our
//           objectives is to ask ourselves what value addition is there for the
//           customer. <br /> Narayan Aluminium Alloys Pvt. Ltd demonstrates
//           innovation, dependability, and sustainability by combining industrial
//           knowledge with current values. We follow the most recent customer
//           response, corporate culture, workplace safety, environmental
//           responsibility, and ISO product certification standards. <br /> As
//           aluminum manufacturers, we maintain upgraded test facilities to assure
//           consistent outcomes in every batch.
//         </p>
//       </div>

//       <div className="container-fluid py-5 bg-light-blue mb-5">
//         <div className="main-heading">
//           <h1>
//             O<span>ur Visio</span>n
//           </h1>
//         </div>
//         <div className="w-full lg:w-9/12 mx-auto flex flex-wrap">
//           <div className="w-full lg:w-6/12 p-2">
//             <div className="p-4 shadow-custom-3 bg-light-blue-2 rounded-[10px]">
//               <div className="flex items-center">
//                 <img src="imgs-alu/quality 5.png" alt="" />
//                 <h4 className="font-exo font-semibold text-[22px] text-regal-blue ml-4">
//                   Respectful with a sense of assurance
//                 </h4>
//               </div>
//               <p className="font-nunito text-center font-medium text-[15px] leading-6 px-3 mt-3">
//                 We are considerate and thoughtful of others. We are self-assured
//                 and proud of our accomplishments, yet we like to stay humble.
//               </p>
//             </div>
//           </div>
//           <div className="w-full lg:w-6/12 p-2">
//             <div className="p-4 shadow-custom-3 bg-light-blue-2 rounded-[10px]">
//               <div className="flex items-center">
//                 <img src="imgs-alu/quality 6.png" alt="" />
//                 <h4 className="font-exo font-semibold text-[22px] text-regal-blue ml-4">
//                   Holding an outstanding reputation
//                 </h4>
//               </div>
//               <p className="font-nunito text-center font-medium text-[15px] leading-6 px-3 mt-3">
//                 Narayan Aluminium Alloys Pvt. Ltd always makes the correct
//                 decisions. The quality of our products and how we interact with
//                 the community help in reputation building.
//               </p>
//             </div>
//           </div>
//           <div className="w-full lg:w-6/12 p-2">
//             <div className="p-4 shadow-custom-3 bg-light-blue-2 rounded-[10px]">
//               <div className="flex items-center">
//                 <img src="imgs-alu/quality 7.png" alt="" />
//                 <h4 className="font-exo font-semibold text-[22px] text-regal-blue ml-4">
//                   Motivated & solution orientation
//                 </h4>
//               </div>
//               <p className="font-nunito text-center font-medium text-[15px] leading-6 px-3 mt-3">
//                 We have a positive attitude and gain energy from issue
//                 solutions. We recognize that problems can arise and collaborate
//                 to solve them when they do.
//               </p>
//             </div>
//           </div>
//           <div className="w-full lg:w-6/12 p-2">
//             <div className="p-4 shadow-custom-3 bg-light-blue-2 rounded-[10px]">
//               <div className="flex items-center">
//                 <img src="imgs-alu/quality 8.png" alt="" />
//                 <h4 className="font-exo font-semibold text-[22px] text-regal-blue ml-4">
//                   Motivated & solution orientation
//                 </h4>
//               </div>
//               <p className="font-nunito text-center font-medium text-[15px] leading-6 px-3 mt-3">
//                 We have a positive attitude and gain energy from issue
//                 solutions. We recognize that problems can arise and collaborate
//                 to solve them when they do.
//               </p>
//             </div>
//           </div>
//           <div className="w-full lg:w-6/12 p-2 mx-auto">
//             <div className="p-4 shadow-custom-3 bg-light-blue-2 rounded-[10px]">
//               <div className="flex items-center">
//                 <img src="imgs-alu/quality 9.png" alt="" />
//                 <h4 className="font-exo font-semibold text-[22px] text-regal-blue ml-4">
//                   Digitalization
//                 </h4>
//               </div>
//               <p className="font-nunito text-center font-medium text-[15px] leading-6 px-3 mt-3">
//                 We utilize analytics and big data in the areas of fewer
//                 stoppages, early detection of defects, better service, and lower
//                 spare parts expenditures.
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>

//       <EnquiryForm/>

//       <Footer />
//     </>
//   );
// };

// export default Vision;


import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link, useSearchParams } from "react-router-dom";
import EnquiryForm from "./EnquiryForm";
import { useAuth } from "../../AuthContext";
import axios from 'axios';

const Vision = () => {
  const { apipath, filepath } = useAuth();
  const [visionData, setVisionData] = useState(null);
  const [searchParams] = useSearchParams();
  const pageType = searchParams.get("pageType") || "Default";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apipath}/aboutcms/content/${pageType}`);
        // Log the data portion of the response
        //  console.log('Response Data:', response.data);
        setVisionData(response.data);
      } catch (error) {
        console.error('Error fetching about data:', error);
      }
    };

    fetchData();
  }, [pageType]);

  if (!visionData) {
    return <div>Loading...</div>;
  }


  return (
    <>
      <Navbar />

      <Link to="/" className="float">
        <img src="imgs-alu/whtsp.png" alt="" />
      </Link>

      <Link to="/" className="float2" title="Help">
        <img src="imgs-alu/help.png" alt="" />
      </Link>

      <div className="container-fluid main-banner">
        <div className="heading-main">
          <h1 className="text-[48px] text-white font-candal text-center">
            Our Vision
          </h1>
          <h4 className="text-[17px] font-exo text-white font-medium text-center">
            Home{" "}
            <i className="bi bi-chevron-double-right text-light-yellow"></i>
            Our Vision
          </h4>
        </div>
      </div>

      <div className="container py-5">
        <img src={filepath + visionData.bannerImage} alt="" className="w-full" />
      </div>

      <div className="my-4 w-full md:w-9/12 mx-auto">
        <p className="font-nunito font-normal text-xl text-dark-gray leading-9">
          {visionData.headContent}
        </p>
      </div>

      <div className="container-fluid py-5 bg-light-blue mb-5">
        <div className="main-heading">
          <h1>
            <span>{visionData.maintitle}</span>
          </h1>
        </div>
        <div className="w-full lg:w-9/12 mx-auto flex flex-wrap">
          {visionData.body1card.slice(0, 4).map((item, index) => (
            <div className="w-full lg:w-6/12 p-2" key={index}>
              <div className="p-4 shadow-custom-3 bg-light-blue-2 rounded-[10px]">
                <div className="flex items-center">
                  <img src={filepath + item.cardimage} alt="" />
                  <h4 className="font-exo font-semibold text-[22px] text-regal-blue ml-4">
                    {item.cardtitle}
                  </h4>
                </div>
                <p className="font-nunito text-center font-medium text-[15px] leading-6 px-3 mt-3">
                  {item.cardcontent}
                </p>
              </div>
            </div>
          ))}
          <div className="w-full lg:w-6/12 p-2 mx-auto">
            <div className="p-4 shadow-custom-3 bg-light-blue-2 rounded-[10px]">
              <div className="flex items-center">
                <img src={filepath + visionData.body1card[4].cardimage} alt="" />
                <h4 className="font-exo font-semibold text-[22px] text-regal-blue ml-4">
                  {visionData.body1card[4].cardtitle}
                </h4>
              </div>
              <p className="font-nunito text-center font-medium text-[15px] leading-6 px-3 mt-3">
                {visionData.body1card[4].cardcontent}
              </p>
            </div>
          </div>
        </div>
      </div>

      <EnquiryForm />

      <Footer />
    </>
  );
};

export default Vision;