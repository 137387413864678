// import React, { useState } from "react";
// import Navbar from "./Navbar";
// import Footer from "./Footer";
// import { Link } from "react-router-dom";
// import Carousel from "react-bootstrap/Carousel";
// import ExampleCarouselImage from "./ExampleCarouselImage";

// const Landing = () => {
//   const [show, setShow] = useState(1);

//   const handleShow = (index) => {
//     setShow(show === index ? null : index);
//   };

//   return (
//     <>
//       <Navbar />

//       {/* <div className="container-fluid banner">
//         <div className="row banner-row">
//           <div className="col-lg-6 banner-left">
//             <div className="banner-icons">
//               <img src="imgs-alu/logos_facebook.png" alt="" />
//               <img src="imgs-alu/skill-icons_instagram.png" alt="" />
//               <img src="imgs-alu/logos_twitter.png" alt="" />
//               <img src="imgs-alu/devicon_linkedin.png" alt="" />
//             </div>
//             <h1>SHAPING THE WORLD OF ALUMINIUM FOR A BETTER FUTURE</h1>
//             <div className="banner-button">
//               <button>Check Our Products</button>
//             </div>
//           </div>
//           <div className="col-lg-6 banner-img">
//             <img src="imgs-alu/right-banner.png" alt="" />
//           </div>
//         </div>
//       </div> */}

//       <Carousel>
//         <Carousel.Item interval={2000}>
//           <ExampleCarouselImage
//             text="SHAPING THE WORLD OF ALUMINIUM FOR A BETTER FUTURE"
//             imgUrl="imgs-alu/right-banner.png"
//           />
//           <Carousel.Caption></Carousel.Caption>
//         </Carousel.Item>
//         <Carousel.Item interval={1000}>
//           <ExampleCarouselImage
//             text="BUILDING DREAMS WITH ALUMINIUM PRECISION"
//             imgUrl="imgs-alu/b2-1.png"
//           />
//           <Carousel.Caption></Carousel.Caption>
//         </Carousel.Item>
//       </Carousel>

//       {/* ABOUT US */}

//       <div className="container-fluid about">
//         <div className="main-heading">
//           <h1>
//             A<span>bout U</span>s
//           </h1>
//         </div>
//         <div className="container">
//           <div className="row pt-4">
//             <div className="col-lg-6">
//               <div className="about-img">
//                 <img src="imgs-alu/about-img.png" alt="" />
//               </div>
//             </div>
//             <div className="col-lg-6">
//               <div className="about-content">
//                 <p>
//                   Narayan Aluminium Alloys Pvt. Ltd is a company dealing with
//                   the extrusion, anodizing, and powder coating of aluminum. Our
//                   product line includes aluminum extruded items for use in
//                   architectural, transportation, industrial, and numerous
//                   specialized applications.
//                 </p>
//                 <p>
//                   Our company’s basic values are safety, sustainability, and
//                   customer satisfaction. The protection of our workers and
//                   environmental security is our top concern.
//                 </p>
//                 <div className="about-button">
//                   <Link to="/about">
//                     <button>Learn More</button>
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* CARDS */}

//       <div className="container">
//         <div className="row card-row">
//           <div className="col-lg-4">
//             <div className="feature-card">
//               <div className="feature-img">
//                 <img src="imgs-alu/quality-control 1.png" alt="" />
//               </div>
//               <h2>Quality Assurance</h2>
//               <p>
//                 It's a series of methods used to confirm that a product complies
//                 with its manufacturing requirements. Tests are conducted on both
//                 the manufacturing process and finished goods.
//               </p>
//             </div>
//           </div>
//           <div className="col-lg-4">
//             <div className="feature-card">
//               <div className="feature-img">
//                 <img src="imgs-alu/quality-control 2.png" alt="" />
//               </div>
//               <h2>Customer Handling</h2>
//               <p>
//                 The Investor Customer Manager supports with communicating new
//                 information, attending meetings and queries, giving management
//                 feedback, and handling emergencies.
//               </p>
//             </div>
//           </div>
//           <div className="col-lg-4">
//             <div className="feature-card">
//               <div className="feature-img">
//                 <img src="imgs-alu/quality-control 3.png" alt="" />
//               </div>
//               <h2>Testing Assistance</h2>
//               <p>
//                 The testing facilities of Narayan Aluminium Alloys Pvt. Ltd will
//                 assist you in evaluating products, components, compatibility,
//                 and safety of goods, features, and assets.
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* OUR KEY PRODUCTS */}

//       <div className="container key">
//         <div className="main-heading">
//           <h1>
//             O<span>ur Key Produc</span>ts
//           </h1>
//         </div>
//         <div className="row key-row">
//           <div className="col-lg-4">
//             <Link to="/coloring" className="text-decoration-none">
//               <div className="key-card key-card-1">
//                 <h3>Coloring</h3>
//               </div>
//             </Link>
//           </div>
//           <div className="col-lg-4">
//             <Link to="/designing" className="text-decoration-none">
//               <div className="key-card key-card-2">
//                 <h3>Designing</h3>
//               </div>
//             </Link>
//           </div>
//           <div className="col-lg-4">
//             <Link to="/" className="text-decoration-none">
//               <div className="key-card key-card-3">
//                 <h3>Machining</h3>
//               </div>
//             </Link>
//           </div>
//         </div>
//       </div>

//       <div className="container-fluid man">
//         <div className="main-heading">
//           <h1>
//             W<span>hat Are We Manufacturi</span>ng
//           </h1>
//         </div>
//         <div className="container">
//           <div className="row man-row">
//             <div className="col-lg-4">
//               <div className="man-card">
//                 <div className="man-img">
//                   <img src="imgs-alu/man1.png" alt="" />
//                 </div>
//                 <h2>EXTRUSION</h2>
//                 <p>
//                   With a guarantee to deliver manufacturing materials of the
//                   highest caliber.
//                 </p>
//                 <div className="man-button">
//                   <Link to="/">
//                     <button>Details</button>
//                   </Link>
//                 </div>
//               </div>
//             </div>
//             <div className="col-lg-4">
//               <div className="man-card">
//                 <div className="man-img">
//                   <img src="imgs-alu/man2.png" alt="" />
//                 </div>
//                 <h2>SURFACE COATING</h2>
//                 <p>
//                   Flexible, long-lasting, and environmentally friendly powder
//                   coating to color metals.
//                 </p>
//                 <div className="man-button">
//                   <Link to="/">
//                     <button>Details</button>
//                   </Link>
//                 </div>
//               </div>
//             </div>
//             <div className="col-lg-4">
//               <div className="man-card">
//                 <div className="man-img">
//                   <img src="imgs-alu/man3.png" alt="" />
//                 </div>
//                 <h2>ANODIZING</h2>
//                 <p>
//                   The anodizing process reinforces the metal surface's corrosion
//                   and erosion resistance.
//                 </p>
//                 <div className="man-button">
//                   <Link to="/">
//                     <button>Details</button>
//                   </Link>
//                 </div>
//               </div>
//             </div>
//             <div className="col-lg-4">
//               <div className="man-card">
//                 <div className="man-img">
//                   <img src="imgs-alu/man4.png" alt="" />
//                 </div>
//                 <h2>FABRICATION</h2>
//                 <p>
//                   Streamlined method for shaping metal into the necessary forms
//                   to your exact specifications.
//                 </p>
//                 <div className="man-button">
//                   <Link to="/">
//                     <button>Details</button>
//                   </Link>
//                 </div>
//               </div>
//             </div>
//             <div className="col-lg-4">
//               <div className="man-card">
//                 <div className="man-img">
//                   <img src="imgs-alu/man5.png" alt="" />
//                 </div>
//                 <h2>BILLET MAKING</h2>
//                 <p>
//                   Narayan Aluminium Pvt. Ltd is a leader in BILLET MAKING when
//                   it comes to supplying goods.
//                 </p>
//                 <div className="man-button">
//                   <Link to="/">
//                     <button>Details</button>
//                   </Link>
//                 </div>
//               </div>
//             </div>
//             <div className="col-lg-4">
//               <div className="man-card">
//                 <div className="man-img">
//                   <img src="imgs-alu/man6.png" alt="" />
//                 </div>
//                 <h2>TOOLROOM</h2>
//                 <p>
//                   The tool room is used for all tool maintenance, manufacture,
//                   storage, and repair.
//                 </p>
//                 <div className="man-button">
//                   <Link to="/">
//                     <button>Details</button>
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="container-fluid quality py-5">
//         <div className="container flex flex-wrap">
//           <div className="w-full sm:w-6/12 md:w-4/12 lg:w-3/12 p-2">
//             <div className="bg-light-white p-4 border-2 border-white rounded-lg">
//               <div className="flex justify-center">
//                 <img src="imgs-alu/Ellipse 1.png" alt="" width="100" />
//               </div>
//               <h3 className="text-white font-josefin-sans font-semibold text-[22px] text-center pt-4">
//                 Best Deal Assurance
//               </h3>
//             </div>
//           </div>
//           <div className="w-full sm:w-6/12 md:w-4/12 lg:w-3/12 p-2">
//             <div className="bg-light-white p-4 border-2 border-white rounded-lg">
//               <div className="flex justify-center">
//                 <img src="imgs-alu/Ellipse 2.png" alt="" width="100" />
//               </div>
//               <h3 className="text-white font-josefin-sans font-semibold text-[22px] text-center pt-4">
//                 100% Satisfaction
//               </h3>
//             </div>
//           </div>
//           <div className="w-full sm:w-6/12 md:w-4/12 lg:w-3/12 p-2">
//             <div className="bg-light-white p-4 border-2 border-white rounded-lg">
//               <div className="flex justify-center">
//                 <img src="imgs-alu/Ellipse 3.png" alt="" width="100" />
//               </div>
//               <h3 className="text-white font-josefin-sans font-semibold text-[22px] text-center pt-4">
//                 Advanced Support
//               </h3>
//             </div>
//           </div>
//           <div className="w-full sm:w-6/12 md:w-4/12 lg:w-3/12 p-2">
//             <div className="bg-light-white p-4 border-2 border-white rounded-lg">
//               <div className="flex justify-center">
//                 <img src="imgs-alu/Ellipse 4.png" alt="" width="100" />
//               </div>
//               <h3 className="text-white font-josefin-sans font-semibold text-[22px] text-center pt-4">
//                 The Best Choice
//               </h3>
//             </div>
//           </div>
//           <div className="w-full sm:w-6/12 md:w-4/12 lg:w-3/12 p-2">
//             <div className="bg-light-white p-4 border-2 border-white rounded-lg">
//               <div className="flex justify-center">
//                 <img src="imgs-alu/Ellipse 5.png" alt="" width="100" />
//               </div>
//               <h3 className="text-white font-josefin-sans font-semibold text-[22px] text-center pt-4">
//                 Quality Control
//               </h3>
//             </div>
//           </div>
//           <div className="w-full sm:w-6/12 md:w-4/12 lg:w-3/12 p-2">
//             <div className="bg-light-white p-4 border-2 border-white rounded-lg">
//               <div className="flex justify-center">
//                 <img src="imgs-alu/Ellipse 6.png" alt="" width="100" />
//               </div>
//               <h3 className="text-white font-josefin-sans font-semibold text-[22px] text-center pt-4">
//                 Customer Delight
//               </h3>
//             </div>
//           </div>
//           <div className="w-full sm:w-6/12 md:w-4/12 lg:w-3/12 p-2">
//             <div className="bg-light-white p-4 border-2 border-white rounded-lg">
//               <div className="flex justify-center">
//                 <img src="imgs-alu/Ellipse 7.png" alt="" width="100" />
//               </div>
//               <h3 className="text-white font-josefin-sans font-semibold text-[22px] text-center pt-4">
//                 Customer Delight
//               </h3>
//             </div>
//           </div>
//           <div className="w-full sm:w-6/12 md:w-4/12 lg:w-3/12 p-2">
//             <div className="bg-light-white p-4 border-2 border-white rounded-lg">
//               <div className="flex justify-center">
//                 <img src="imgs-alu/Ellipse 8.png" alt="" width="100" />
//               </div>
//               <h3 className="text-white font-josefin-sans font-semibold text-[22px] text-center pt-4">
//                 Decades of Experience
//               </h3>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="container-fluid bg-custom-gradient  p-5">
//         <div className="container">
//           <div className="w-full lg:w-9/12 mx-auto catalogue p-[6%] flex justify-between items-center rounded-lg">
//             <div className="main-heading">
//               <h1>
//                 C<span>atalogu</span>e
//               </h1>
//             </div>
//             <div className="animate-bounce w-16 h-16 rounded-full bg-regal-blue flex justify-center items-center cursor-pointer">
//               <img src="imgs-alu/ic_round-download.png" width="40" alt="" />
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="container py-5">
//         <div className="main-heading">
//           <h1>
//             G<span>et in Touch With U</span>s
//           </h1>
//         </div>
//         <p className="font-raleway text-center text-lg text-dark-gray font-medium">
//           We are available to help
//         </p>

//         <form action="">
//           <div className="landing-form w-full lg:w-9/12 mx-auto flex flex-wrap">
//             <div className="w-full sm:w-6/12 md:w-4/12 lg:w-2/12 p-1 mx-auto">
//               <div>
//                 <input
//                   type="text"
//                   placeholder="Name"
//                   className="w-full p-1 border-1 border-[#D9D9D9]"
//                 />
//               </div>
//             </div>
//             <div className="w-full sm:w-6/12 md:w-4/12 lg:w-2/12 p-1 mx-auto">
//               <div>
//                 <input
//                   type="text"
//                   placeholder="Email"
//                   className="w-full p-1 border-1 border-[#D9D9D9]"
//                 />
//               </div>
//             </div>
//             <div className="w-full sm:w-6/12 md:w-4/12 lg:w-2/12 p-1 mx-auto">
//               <div>
//                 <input
//                   type="text"
//                   placeholder="Phone No."
//                   className="w-full p-1 border-1 border-[#D9D9D9]"
//                 />
//               </div>
//             </div>
//             <div className="w-full sm:w-6/12 md:w-4/12 lg:w-2/12 p-1 mx-auto">
//               <div>
//                 <input
//                   type="text"
//                   placeholder="Message"
//                   className="w-full p-1 border-1 border-[#D9D9D9]"
//                 />
//               </div>
//             </div>
//             <div className="w-full sm:w-6/12 md:w-4/12 lg:w-2/12 p-1 mx-auto">
//               <div>
//                 <button className=" w-full font-raleway font-medium text-base text-white py-1 bg-dark-yellow">
//                   Contact Us
//                 </button>
//               </div>
//             </div>
//           </div>
//         </form>
//       </div>

//       <div className="container-fluid bg-sky-blue py-5 mb-5">
//         <div className="container">
//           <div class="row faq-row">
//             <div class="col-lg-6">
//               <div class="faq-left">
//                 <h1>
//                   Frequently Asked Questions <span>(FAQ)</span>
//                 </h1>
//                 <hr />
//                 <p>
//                   Explore answers to commonly asked questions about our
//                   platform, services, and product experience.
//                 </p>
//               </div>
//             </div>
//             <div class="col-lg-6 faq-cards">
//               <div class="faq-card">
//                 <div class="faq-heading">
//                   <h3>
//                     Can you produce the sheets according to our requirements ?
//                   </h3>
//                   <i
//                     class={show === 1 ? "bi bi-dash" : "bi bi-plus"}
//                     onClick={() => handleShow(1)}
//                   ></i>
//                 </div>
//                 {show === 1 && (
//                   <p class="faq-p">
//                     Yes, at Narayan Aluminium Alloy Pvt. Ltd., we pride
//                     ourselves on our flexibility and ability to tailor our
//                     designs to meet your specific needs. Whether it's
//                     customization of existing models or the development of
//                     entirely new sheets, our expert team is equipped to fulfill
//                     your requirements with precision and excellence.
//                   </p>
//                 )}
//               </div>
//               <div class="faq-card">
//                 <div class="faq-heading">
//                   <h3>
//                     Can you produce the sheets according to our requirements ?
//                   </h3>
//                   <i
//                     class={show === 2 ? "bi bi-dash" : "bi bi-plus"}
//                     onClick={() => handleShow(2)}
//                   ></i>
//                 </div>
//                 {show === 2 && (
//                   <p class="faq-p">
//                     Yes, at Narayan Aluminium Alloy Pvt. Ltd., we pride
//                     ourselves on our flexibility and ability to tailor our
//                     designs to meet your specific needs. Whether it's
//                     customization of existing models or the development of
//                     entirely new sheets, our expert team is equipped to fulfill
//                     your requirements with precision and excellence.
//                   </p>
//                 )}
//               </div>
//               <div class="faq-card">
//                 <div class="faq-heading">
//                   <h3>
//                     Can you produce the sheets according to our requirements ?
//                   </h3>
//                   <i
//                     class={show === 3 ? "bi bi-dash" : "bi bi-plus"}
//                     onClick={() => handleShow(3)}
//                   ></i>
//                 </div>
//                 {show === 3 && (
//                   <p class="faq-p">
//                     Yes, at Narayan Aluminium Alloy Pvt. Ltd., we pride
//                     ourselves on our flexibility and ability to tailor our
//                     designs to meet your specific needs. Whether it's
//                     customization of existing models or the development of
//                     entirely new sheets, our expert team is equipped to fulfill
//                     your requirements with precision and excellence.
//                   </p>
//                 )}
//               </div>
//               <div class="faq-card">
//                 <div class="faq-heading">
//                   <h3>
//                     Can you produce the sheets according to our requirements ?
//                   </h3>
//                   <i
//                     class={show === 4 ? "bi bi-dash" : "bi bi-plus"}
//                     onClick={() => handleShow(4)}
//                   ></i>
//                 </div>
//                 {show === 4 && (
//                   <p class="faq-p">
//                     Yes, at Narayan Aluminium Alloy Pvt. Ltd., we pride
//                     ourselves on our flexibility and ability to tailor our
//                     designs to meet your specific needs. Whether it's
//                     customization of existing models or the development of
//                     entirely new sheets, our expert team is equipped to fulfill
//                     your requirements with precision and excellence.
//                   </p>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <Footer />
//     </>
//   );
// };

// export default Landing;


import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import ExampleCarouselImage from "./ExampleCarouselImage";
import { useAuth } from "../../AuthContext";
import axios from 'axios'
import FAQ from "./FAQ";

const Landing = () => {
  const { apipath, filepath } = useAuth();
  const [homeData, setHomeData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apipath}/home-cms/details`); // Adjust the URL as needed
        setHomeData(response.data);
      } catch (error) {
        console.error('Error fetching about data:', error);
      }
    };

    fetchData();
  }, []);

  const cardLinks = [
    '/extrusion?pageType=Extrusion', // Link for the first card
    '/powder?pageType=Powder%20Coating', // Link for the second card
    '/anodizing?pageType=Anodizing', // Link for the third card
    '/fabrication?pageType=Fabrication', // Link for the fourth card
    '/billet?pageType=Billet%20Making', // Link for the fifth card
    '/toolroom?pageType=Tool%20Room'  // Link for the sixth card
  ];

  if (!homeData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Navbar />

      {/* <div className="container-fluid banner">
        <div className="row banner-row">
          <div className="col-lg-6 banner-left">
            <div className="banner-icons">
              <img src="imgs-alu/logos_facebook.png" alt="" />
              <img src="imgs-alu/skill-icons_instagram.png" alt="" />
              <img src="imgs-alu/logos_twitter.png" alt="" />
              <img src="imgs-alu/devicon_linkedin.png" alt="" />
            </div>
            <h1>SHAPING THE WORLD OF ALUMINIUM FOR A BETTER FUTURE</h1>
            <div className="banner-button">
              <button>Check Our Products</button>
            </div>
          </div>
          <div className="col-lg-6 banner-img">
            <img src="imgs-alu/right-banner.png" alt="" />
          </div>
        </div>
      </div> */}

      <Carousel>
        <Carousel.Item interval={2000}>
          <ExampleCarouselImage
            text={homeData.banner[0].bannertext}
            imgUrl={filepath + homeData.banner[0].bannerImage}
          />
          <Carousel.Caption></Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={1000}>
          <ExampleCarouselImage
            text={homeData.banner[1].bannertext}
            imgUrl={filepath + homeData.banner[1].bannerImage}
          />
          <Carousel.Caption></Carousel.Caption>
        </Carousel.Item>
      </Carousel>

      {/* ABOUT US */}

      {homeData.body1.map((item, index) => (
      <div className="container-fluid about" key={index}>
        <div className="main-heading">
          <h1>
            <span>{item.body1heading}</span>
          </h1>
        </div>
        <div className="container">
          <div className="row pt-4">
            <div className="col-lg-6">
              <div className="about-img">
                <img src={filepath + item.body1image} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-content">
                <p>
                  {item.body1text}
                </p>
                <div className="about-button">
                  <Link to="/about">
                    <button>Learn More</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      ))}

      {/* CARDS */}

      <div className="container">
        <div className="row card-row">
        {homeData.body2card.map((item, index) => (
          <div className="col-lg-4" key={index}>
            <div className="feature-card">
              <div className="feature-img">
                <img src={filepath + item.cardimage} alt="" />
              </div>
              <h2>{item.cardheading}</h2>
              <p>
                {item.cardcontent}
              </p>
            </div>
          </div>
        ))}
        </div>
      </div>

      {/* OUR KEY PRODUCTS */}

      <div className="container key">
        <div className="main-heading">
          <h1>
            <span>{homeData.body3heading}</span>
          </h1>
        </div>
        <div className="row key-row">
          <div className="col-lg-4">
            <Link to="/coloring?pageType=Coloring" className="text-decoration-none">
              <div className="key-card key-card-1">
                <h3>{homeData.body3card[0].cardtitle}</h3>
              </div>
            </Link>
          </div>
          <div className="col-lg-4">
            <Link to="/designing?pageType=Designing" className="text-decoration-none">
              <div className="key-card key-card-2">
                <h3>{homeData.body3card[1].cardtitle}</h3>
              </div>
            </Link>
          </div>
          <div className="col-lg-4">
            <Link to="/anodizing?pageType=Anodizing" className="text-decoration-none">
              <div className="key-card key-card-3">
                <h3>{homeData.body3card[2].cardtitle}</h3>
              </div>
            </Link>
          </div>
        </div>
      </div>

      <div className="container-fluid man">
        <div className="main-heading">
          <h1>
            <span>{homeData.body4heading}</span>
          </h1>
        </div>
        <div className="container">
          <div className="row man-row">
          {homeData.body4card.map((item, index) => (
            <div className="col-lg-4" key={index}>
              <div className="man-card">
                <div className="man-img">
                  <img src={filepath + item.cardimage} alt="" />
                </div>
                <h2>{item.cardheading}</h2>
                <p>
                  {item.cardcontent}
                </p>
                <div className="man-button">
                  <Link to={cardLinks[index]}>
                    <button>Details</button>
                  </Link>
                </div>
              </div>
            </div>
          ))}
          </div>
        </div>
      </div>

      <div className="container-fluid quality py-5">
        <div className="container flex flex-wrap">
        {homeData.body5card.map((item, index) => (
          <div className="w-full sm:w-6/12 md:w-4/12 lg:w-3/12 p-2" key={index}>
            <div className="bg-light-white p-4 border-2 border-white rounded-lg">
              <div className="flex justify-center">
                <img src={filepath + item.cardimage} alt="" width="100" />
              </div>
              <h3 className="text-white font-josefin-sans font-semibold text-[22px] text-center pt-4">
                {item.cardtitle}
              </h3>
            </div>
          </div>
        ))}
        </div>
      </div>

      <div className="container-fluid bg-custom-gradient  p-5">
        <div className="container">
          <div className="w-full lg:w-9/12 mx-auto catalogue p-[6%] flex justify-between items-center rounded-lg">
            <div className="main-heading">
              <h1>
                C<span>atalogu</span>e
              </h1>
            </div>
            <div className="animate-bounce w-16 h-16 rounded-full bg-regal-blue flex justify-center items-center cursor-pointer">
              <img src="imgs-alu/ic_round-download.png" width="40" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="container py-5">
        <div className="main-heading">
          <h1>
            G<span>et in Touch With U</span>s
          </h1>
        </div>
        <p className="font-raleway text-center text-lg text-dark-gray font-medium">
          We are available to help
        </p>

        <form action="">
          <div className="landing-form w-full lg:w-9/12 mx-auto flex flex-wrap">
            <div className="w-full sm:w-6/12 md:w-4/12 lg:w-2/12 p-1 mx-auto">
              <div>
                <input
                  type="text"
                  placeholder="Name"
                  className="w-full p-1 border-1 border-[#D9D9D9]"
                />
              </div>
            </div>
            <div className="w-full sm:w-6/12 md:w-4/12 lg:w-2/12 p-1 mx-auto">
              <div>
                <input
                  type="text"
                  placeholder="Email"
                  className="w-full p-1 border-1 border-[#D9D9D9]"
                />
              </div>
            </div>
            <div className="w-full sm:w-6/12 md:w-4/12 lg:w-2/12 p-1 mx-auto">
              <div>
                <input
                  type="text"
                  placeholder="Phone No."
                  className="w-full p-1 border-1 border-[#D9D9D9]"
                />
              </div>
            </div>
            <div className="w-full sm:w-6/12 md:w-4/12 lg:w-2/12 p-1 mx-auto">
              <div>
                <input
                  type="text"
                  placeholder="Message"
                  className="w-full p-1 border-1 border-[#D9D9D9]"
                />
              </div>
            </div>
            <div className="w-full sm:w-6/12 md:w-4/12 lg:w-2/12 p-1 mx-auto">
              <div>
                <button className=" w-full font-raleway font-medium text-base text-white py-1 bg-dark-yellow">
                  Contact Us
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

      <FAQ />

      <Footer />
    </>
  );
};

export default Landing;
