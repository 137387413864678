import React, { useEffect, useRef, useState } from "react";
import AdminSidebar from './AdminSidebar'
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import iziToast from 'izitoast';

const ProductsDetails = () => {

  const navigate = useNavigate();
  const { apipath, filepath } = useAuth();
  const [productData, setProductData] = useState([]);

  // Function to fetch product data
  const fetchData = async () => {
    try {
      const response = await axios.get(`${apipath}/product/details`);
      setProductData(response.data.product || []);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

   // Function to handle product deletion
  const handleDelete = async (productId) => {
    try {
      const response = await axios.delete(`${apipath}/product/details/${productId}`);
      
      if (response.status === 200) {
        // Update the state to remove the deleted product from the UI
        setProductData(prevProducts => prevProducts.filter(product => product._id !== productId));
        
        // Show success notification
        iziToast.success({
          message: "Product deleted successfully",
          position: "topCenter",
        });
        
        // Navigate to the products details page if needed
        navigate("/productsDetails");
      } else {
        // Show error notification if deletion failed
        iziToast.error({
          message: "Product deletion failed",
          position: "topCenter",
        });
      }
    } catch (error) {
      console.error('Error deleting product:', error);
      // Show error notification if an error occurred
      iziToast.error({
        message: "An error occurred while deleting the product",
        position: "topCenter",
      });
    }
  };


  const handleUpdate = async (productId) => {
    try {
      // Redirect to the user details page with the productId as a parameter
      navigate(`/productEdit/${productId}`);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
        <AdminSidebar />
        <div className="home">
        <div class="toggle-sidebar" style={{ display: "none" }}>
          <i class="bi bi-menu"></i>
        </div>
        <div className="dashboard">
          <div className="dashboard-header">
            <h1>Product Management</h1>
          </div>

          <div className="container-fluid">          
          <div className='filter-flex'>
            <div class="input-group search-group">
              <div class="input-group-prepend search-prepend">
                <span class="input-group-text search-text" id="basic-addon1">
                  <i className="bi bi-search"></i>
                </span>
              </div>
              <input
                type="text"
                class="form-control search-control"
                placeholder="Search by name"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </div>
              <div><Link to="/productAdd"> <button className="btn btn-primary"> Add Product
              </button></Link></div>
            </div>
            <div className="row foot-tablerow">
              <div className="col-lg-11 maintable-column">
                <div className="container mt-4">
                  <table id="datatable" className="table">
                    <thead>
                      <tr className="tr1">
                      <th>Id</th>
                        <th>Name</th>
                        <th>Image</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {productData.map((product, index) => (
                        <tr key={product._id}>
                        <td>{index + 1}</td>
                          <td>{product.productName}</td>
                          <td><img src={ product.productPic !== '' && product.productPic !== null ? filepath +product.productPic : `${process.env.PUBLIC_URL}/assets/products.png`} width={70} height={70} style={{"border-radius":"35px","cursor":"pointer"}} alt="product-img"/></td>
                          <td>₹{" "}{product.price}</td>
                          <td>{product.category}</td>
                          <td>
                          {/* <i className="fa-regular fa-pen-to-square"></i> &nbsp;
                          <i className="fa-solid fa-trash"></i> */}
                          <button className="table-btn me-2"
                              onClick={() => handleUpdate(product._id)}>
                              <i className="bi bi-pencil"></i>
                            </button>
                            <button className="table-btn" onClick={() => handleDelete(product._id)}>
                              <i className="bi bi-trash"></i>
                            </button>
                        </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductsDetails