import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import iziToast from "izitoast";
import { useAuth } from "../../AuthContext";

const Contact = () => {
  const navigate = useNavigate();
  const { apipath } = useAuth();
  const [contactData, setContactData] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    phoneNo: '',
    email: '',
    address: '',
    companyName: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apipath}/footer/details`); // Adjust the URL as needed
        console.log(response.data);
        setContactData(response.data);
      } catch (error) {
        console.error('Error fetching about data:', error);
      }
    };

    fetchData();
  }, []);

  if (!contactData) {
    return <div>Loading...</div>;
  }

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apipath}/contact/add`, formData);
      setFormData({
        name: '',
        phoneNo: '',
        email: '',
        address: '',
        companyName: '',
        message: ''
      });

      if (response.status === 201) {
        iziToast.success({
          message: 'Thank you for contacting us.',
          position: 'topCenter',
        });
        navigate('/contact');
      } else {
        iziToast.error({
          message: 'Contact fill failed',
          position: 'topCenter',
        });
      }
    } catch (error) {
      console.error('Error apply:', error);

      // if (error.response && error.response.data && error.response.data.message) {
      //   const errorMessage = error.response.data.message;

      //   if (errorMessage.includes('E11000 duplicate key error collection')) {
      //     if (errorMessage.includes('phoneNo')) {
      //       iziToast.error({
      //         message: 'This phone number is already registered.',
      //         position: 'topCenter',
      //       });
      //     } else if (errorMessage.includes('email')) {
      //       iziToast.error({
      //         message: 'This email is already registered.',
      //         position: 'topCenter',
      //       });
      //     } else {
      //       iziToast.error({
      //         message: 'A duplicate key error occurred.',
      //         position: 'topCenter',
      //       });
      //     }
      //   } else {
      //     iziToast.error({
      //       message: errorMessage,
      //       position: 'topCenter',
      //     });
      //   }
      // } else {
      //   iziToast.error({
      //     message: 'An error occurred',
      //     position: 'topCenter',
      //   });
      // }
    }
  };


  return (
    <>
      <Navbar />

      <Link to="/" className="float">
        <img src="imgs-alu/whtsp.png" alt="" />
      </Link>

      <div className="container-fluid main-banner">
        <div className="heading-main">
          <h1 className="text-[48px] text-white font-candal text-center">
            Contact Us
          </h1>
          <h4 className="text-[17px] font-exo text-white font-medium text-center">
            Home{" "}
            <i className="bi bi-chevron-double-right text-light-yellow"></i>
            Contact Us
          </h4>
        </div>
      </div>

      <div className="container py-4">
        <div className="main-heading">
          <h1>
            C<span>ontact U</span>s
          </h1>
        </div>
      </div>

      <div className="container my-4 shadow-custom rounded-2xl flex flex-wrap">
        <div className="w-full lg:w-5/12 contact-bg p-5 rounded-custom ml-[-10px]">
          <h1 className="font-poppins font-semibold text-white text-2xl ">
            Contact Information
          </h1>
          <p className="font-poppins font-normal text-white text-base">
            Say something if you have a doubt
          </p>

          <div className="pt-10 lg:pt-16 list-none lg:pb-0 pb-16">
            <li className="font-poppins font-medium text-lg text-white lg:mb-4 mb-2">
              <i className="bi bi-telephone mr-2"></i>{" "}
              <a
                href="tel:+91 811 484 8666"
                className="no-underline text-white"
              >
                {contactData.phonenumber}
              </a>
            </li>
            <li className="font-poppins font-medium text-lg text-white lg:mb-4 mb-2">
              <i className="bi bi-envelope mr-2"></i>{" "}
              <a
                href="mailto:sales@narayanaluminium.com"
                className="no-underline text-white"
              >
                {contactData.email}
              </a>
            </li>
            <li className="font-poppins font-medium text-lg text-white lg:mb-4 mb-2">
              <i className="bi bi-geo-alt-fill mr-2"></i>
              {contactData.address}
            </li>
            <li className="font-poppins font-medium text-lg text-white lg:mb-4 mb-2">
              <i className="bi bi-globe mr-2"></i>{" "}
              <a
                href="https://narayanaluminium.com"
                className="no-underline text-white"
              >
                {contactData.weblink}
              </a>
            </li>
          </div>
        </div>
        <div className="w-full lg:w-7/12 p-5">
          <h1 className="font-poppins font-semibold text-black text-2xl">
            Get In Touch With Us
          </h1>
          <p className="font-poppins font-normal text-black text-base">
            We are happy to answer any questions you may have regarding our
            services.
          </p>

          <form className="flex flex-wrap mt-7" onSubmit={handleRegister}>
            <div className="w-full lg:w-1/2 px-3 py-2">
              <label
                htmlFor="name"
                className="block font-poppins font-base text-semibold text-label-gray mb-1"
              >
                Name
              </label>
              <input
                type="text"
                placeholder="Name"
                className="w-full p-2 border-b-2 border-input-gray font-poppins font-normal text-base text-input-gray"
                name="name"
                value={formData.name}
                onChange={handleChange}
                minLength="3"
                required
              />
            </div>
            <div className="w-full lg:w-1/2 px-3 py-2">
              <label
                htmlFor="name"
                className="block font-poppins font-base text-semibold text-label-gray mb-1"
              >
                Email
              </label>
              <input
                type="email"
                placeholder="Email"
                className="w-full p-2 border-b-2 border-input-gray font-poppins font-normal text-base text-input-gray"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="w-full lg:w-1/2 px-3 py-2">
              <label
                htmlFor="name"
                className="block font-poppins font-base text-semibold text-label-gray mb-1"
              >
                Phone no.
              </label>
              <input
                type="text"
                placeholder="00000-00000"
                className="w-full p-2 border-b-2 border-input-gray font-poppins font-normal text-base text-input-gray"
                name="phoneNo"
                value={formData.phoneNo}
                onChange={handleChange}
                maxLength="15"
                required
              />
            </div>
            <div className="w-full lg:w-1/2 px-3 py-2">
              <label
                htmlFor="name"
                className="block font-poppins font-base text-semibold text-label-gray mb-1"
              >
                Address
              </label>
              <input
                type="text"
                placeholder="Address"
                className="w-full p-2 border-b-2 border-input-gray font-poppins font-normal text-base text-input-gray"
                name="address"
                value={formData.address}
                onChange={handleChange}
                required
              />
            </div>
            <div className="w-full lg:w-1/2 px-3 py-2">
              <label
                htmlFor="name"
                className="block font-poppins font-base text-semibold text-label-gray mb-1"
              >
                Company Name
              </label>
              <input
                type="text"
                placeholder="Company"
                className="w-full p-2 border-b-2 border-input-gray font-poppins font-normal text-base text-input-gray"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="w-full px-3 py-2">
              <label
                htmlFor="name"
                className="block font-poppins font-base text-semibold text-label-gray mb-1"
              >
                Message
              </label>
              <input
                type="text"
                placeholder="Message"
                className="w-full p-2 border-b-2 border-input-gray font-poppins font-normal text-base text-input-gray"
                name="message"
                value={formData.message}
                onChange={handleChange}
                maxLength={100}
                required
              />
            </div>
            <div className="w-full flex justify-end mt-4">
              <button className="py-2 px-12 rounded bg-m-blue font-poppins font-medium text-white text-lg border-1 border-m-blue hover:bg-transparent hover:!text-m-blue hover:ease-in-out duration-300">
                Send Message
              </button>
            </div>
          </form>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Contact;
