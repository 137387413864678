// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import Navbar from "./Navbar";
// import Footer from "./Footer";
// import Stepper from "./Stepper";

// const Orders = () => {
//   const [activeTab, setActiveTab] = useState("pending");

//   const openTab = (tabName) => {
//     setActiveTab(tabName);
//   };

//   return (
//     <>
//       <Navbar />

//       <Link to="/" className="float">
//         <img src="imgs-alu/whtsp.png" alt="" />
//       </Link>

//       <div className="container-fluid main-banner">
//         <div className="heading-main">
//           <h1 className="text-[48px] text-white font-candal text-center">
//             My Orders
//           </h1>
//           <h4 className="text-[17px] font-exo text-white font-medium text-center">
//             Home{" "}
//             <i className="bi bi-chevron-double-right text-light-yellow"></i>
//             My Orders
//           </h4>
//         </div>
//       </div>

//       <div className="container py-4">
//         <div className="w-full flex justify-center mx-auto mb-4 font-poppins text-xl font-normal">
//           <button
//             className={`tablinks px-6 py-2 border-b  ${
//               activeTab === "pending" &&
//               "font-semibold border-b-3 border-water-blue"
//             }`}
//             onClick={() => openTab("pending")}
//             id="pending-button"
//           >
//             Orders In Progress
//           </button>
//           <button
//             className={`tablinks px-6 py-2 border-b ${
//               activeTab === "past" &&
//               "font-semibold border-b-3 border-water-blue"
//             }`}
//             onClick={() => openTab("past")}
//             id="past-button"
//           >
//             Past Orders
//           </button>
//         </div>

//         {activeTab === "pending" && (
//           <div>
//             <div className="w-full lg:w-2/3 mx-auto my-3 bg-white border border-gray-200 shadow-lg p-4 rounded-md">
//               <div className="lg:flex lg:justify-between">
//                 <h3 className="font-alata text-[20px] font-normal text-black">
//                   Aluminium Sheet
//                 </h3>
//                 <h3 className="font-alata text-[20px] font-normal text-black">
//                   ₹ 2500
//                 </h3>
//               </div>
//               <div className="lg:flex lg:justify-between my-3">
//                 <div>
//                   <span className="font-alata text-[15px] font-normal">
//                     Order Id :{" "}
//                   </span>
//                   <span className="font-inter text-[15px] font-normal px-3 py-1 rounded-full bg-[rgba(56,158,234,0.28)]">
//                     {" "}
//                     9898{" "}
//                   </span>
//                 </div>
//                 <div>
//                   <span className="font-alata text-[15px] font-normal">
//                     Status :{" "}
//                   </span>
//                   <span className="font-inter text-[15px] font-normal px-3 py-1 rounded-full bg-[rgba(56,158,234,0.28)]">
//                     {" "}
//                     Pending{" "}
//                   </span>
//                 </div>
//               </div>
//               <h3 className="font-alata text-[15px] font-normal text-black">
//                 Track Order :
//               </h3>
//               <Stepper />
//             </div>
//             <div className="w-full lg:w-2/3 mx-auto my-3 bg-white border border-gray-200 shadow-lg p-4 rounded-md">
//               <div className="lg:flex lg:justify-between">
//                 <h3 className="font-alata text-[20px] font-normal text-black">
//                   Aluminium Sheet
//                 </h3>
//                 <h3 className="font-alata text-[20px] font-normal text-black">
//                   ₹ 2500
//                 </h3>
//               </div>
//               <div className="lg:flex lg:justify-between my-3">
//                 <div>
//                   <span className="font-alata text-[15px] font-normal">
//                     Order Id :{" "}
//                   </span>
//                   <span className="font-inter text-[15px] font-normal px-3 py-1 rounded-full bg-[rgba(56,158,234,0.28)]">
//                     {" "}
//                     9898{" "}
//                   </span>
//                 </div>
//                 <div>
//                   <span className="font-alata text-[15px] font-normal">
//                     Status :{" "}
//                   </span>
//                   <span className="font-inter text-[15px] font-normal px-3 py-1 rounded-full bg-[rgba(56,158,234,0.28)]">
//                     {" "}
//                     Pending{" "}
//                   </span>
//                 </div>
//               </div>
//               <h3 className="font-alata text-[15px] font-normal text-black">
//                 Track Order :
//               </h3>
//               <Stepper />
//             </div>
//             <div className="w-full lg:w-2/3 mx-auto my-3 bg-white border border-gray-200 shadow-lg p-4 rounded-md">
//               <div className="lg:flex lg:justify-between">
//                 <h3 className="font-alata text-[20px] font-normal text-black">
//                   Aluminium Sheet
//                 </h3>
//                 <h3 className="font-alata text-[20px] font-normal text-black">
//                   ₹ 2500
//                 </h3>
//               </div>
//               <div className="lg:flex lg:justify-between my-3">
//                 <div>
//                   <span className="font-alata text-[15px] font-normal">
//                     Order Id :{" "}
//                   </span>
//                   <span className="font-inter text-[15px] font-normal px-3 py-1 rounded-full bg-[rgba(56,158,234,0.28)]">
//                     {" "}
//                     9898{" "}
//                   </span>
//                 </div>
//                 <div>
//                   <span className="font-alata text-[15px] font-normal">
//                     Status :{" "}
//                   </span>
//                   <span className="font-inter text-[15px] font-normal px-3 py-1 rounded-full bg-[rgba(56,158,234,0.28)]">
//                     {" "}
//                     Pending{" "}
//                   </span>
//                 </div>
//               </div>
//               <h3 className="font-alata text-[15px] font-normal text-black">
//                 Track Order :
//               </h3>
//               <Stepper />
//             </div>
//           </div>
//         )}
//         {activeTab === "past" && (
//           <div>
//             <div className="w-full lg:w-2/3 mx-auto my-3 bg-white border border-gray-200 shadow-lg p-4 rounded-md">
//               <div className="lg:flex lg:justify-between">
//                 <h3 className="font-alata text-[20px] font-normal text-black">
//                   Aluminium Sheet
//                 </h3>
//                 <h3 className="font-alata text-[20px] font-normal text-black">
//                   ₹ 2500
//                 </h3>
//               </div>
//               <div className="lg:flex lg:justify-between my-3">
//                 <div>
//                   <span className="font-alata text-[15px] font-normal">
//                     Order Id :{" "}
//                   </span>
//                   <span className="font-inter text-[15px] font-normal px-3 py-1 rounded-full bg-[rgba(56,158,234,0.28)]">
//                     {" "}
//                     9898{" "}
//                   </span>
//                 </div>
//                 <div>
//                   <span className="font-alata text-[15px] font-normal">
//                     Status :{" "}
//                   </span>
//                   <span className="font-inter text-[15px] font-normal px-3 py-1 rounded-full bg-[rgba(56,158,234,0.28)]">
//                     {" "}
//                     Delivered{" "}
//                   </span>
//                 </div>
//               </div>
//               <h3 className="font-alata text-[15px] font-normal text-black">
//                 Track Order :
//               </h3>
//               <Stepper />
//             </div>
//             <div className="w-full lg:w-2/3 mx-auto my-3 bg-white border border-gray-200 shadow-lg p-4 rounded-md">
//               <div className="lg:flex lg:justify-between">
//                 <h3 className="font-alata text-[20px] font-normal text-black">
//                   Aluminium Sheet
//                 </h3>
//                 <h3 className="font-alata text-[20px] font-normal text-black">
//                   ₹ 2500
//                 </h3>
//               </div>
//               <div className="lg:flex lg:justify-between my-3">
//                 <div>
//                   <span className="font-alata text-[15px] font-normal">
//                     Order Id :{" "}
//                   </span>
//                   <span className="font-inter text-[15px] font-normal px-3 py-1 rounded-full bg-[rgba(56,158,234,0.28)]">
//                     {" "}
//                     9898{" "}
//                   </span>
//                 </div>
//                 <div>
//                   <span className="font-alata text-[15px] font-normal">
//                     Status :{" "}
//                   </span>
//                   <span className="font-inter text-[15px] font-normal px-3 py-1 rounded-full bg-[rgba(56,158,234,0.28)]">
//                     {" "}
//                     Delivered{" "}
//                   </span>
//                 </div>
//               </div>
//               <h3 className="font-alata text-[15px] font-normal text-black">
//                 Track Order :
//               </h3>
//               <Stepper />
//             </div>
            
//           </div>
//         )}
//       </div>

//       <Footer />
//     </>
//   );
// };

// export default Orders;



import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Stepper from "./Stepper";
import axios from "axios";
import { useAuth } from "../../AuthContext";

const Orders = () => {
  const [activeTab, setActiveTab] = useState("pending");
  const { apipath } = useAuth();
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          const response = await axios.get(`${apipath}/order/orderdetails`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          setOrders(response.data);
        } else {
          console.error("No token found");
        }
      } catch (error) {
        console.error("Error fetching orders", error);
        setOrders([]); // Set orders to an empty array on error
      }
    };

    fetchOrders();
  }, []);

  const openTab = (tabName) => {
    setActiveTab(tabName);
  };

  const filteredOrders = orders.filter(order =>
    activeTab === "pending" ? order.status === "Pending" : order.status !== "Pending"
  );

  return (
    <>
      <Navbar />
      <Link to="/" className="float">
        <img src="imgs-alu/whtsp.png" alt="" />
      </Link>
      <div className="container-fluid main-banner">
        <div className="heading-main">
          <h1 className="text-[48px] text-white font-candal text-center">My Orders</h1>
          <h4 className="text-[17px] font-exo text-white font-medium text-center">
            Home <i className="bi bi-chevron-double-right text-light-yellow"></i> My Orders
          </h4>
        </div>
      </div>
      <div className="container py-4">
        <div className="w-full flex justify-center mx-auto mb-4 font-poppins text-xl font-normal">
          <button
            className={`tablinks px-6 py-2 border-b ${activeTab === "pending" && "font-semibold border-b-3 border-water-blue"}`}
            onClick={() => openTab("pending")}
            id="pending-button"
          >
            Orders In Progress
          </button>
          <button
            className={`tablinks px-6 py-2 border-b ${activeTab === "past" && "font-semibold border-b-3 border-water-blue"}`}
            onClick={() => openTab("past")}
            id="past-button"
          >
            Past Orders
          </button>
        </div>
        {filteredOrders.map(order => (
          <div key={order._id} className="w-full lg:w-2/3 mx-auto my-3 bg-white border border-gray-200 shadow-lg p-4 rounded-md">
            <div className="lg:flex lg:justify-between">
              <h3 className="font-alata text-[20px] font-normal text-black">Aluminium Sheet</h3>
              <h3 className="font-alata text-[20px] font-normal text-black">₹ {order.amount}</h3>
            </div>
            <div className="lg:flex lg:justify-between my-3">
              <div>
                <span className="font-alata text-[15px] font-normal">Order Id : </span>
                <span className="font-inter text-[15px] font-normal px-3 py-1 rounded-full bg-[rgba(56,158,234,0.28)]"> {order._id} </span>
              </div>
              <div>
                <span className="font-alata text-[15px] font-normal">Status : </span>
                <span className="font-inter text-[15px] font-normal px-3 py-1 rounded-full bg-[rgba(56,158,234,0.28)]"> {order.status} </span>
              </div>
            </div>
            <h3 className="font-alata text-[15px] font-normal text-black">Track Order :</h3>
            <Stepper orderId={order._id} />
          </div>
        ))}
      </div>
      <Footer />
    </>
  );
};

export default Orders;
