import React, { useState, useEffect } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import AdminSidebar from '../admin/AdminSidebar';
import iziToast from 'izitoast';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../AuthContext';

const PolicyPageEditor = () => {
    const { apipath } = useAuth();
    const [formData, setFormData] = useState({
        privacypolicyContent: '',
        refundpolicyContent: '',
        termandconditionpolicyContent: '',
    });
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch the policy page data from the server
        axios.get(`${apipath}/policy/details`)
            .then(response => {
                const data = response.data;
                setFormData({
                    privacypolicyContent: data.privacypolicyContent || '',
                    refundpolicyContent: data.refundpolicyContent || '',
                    termandconditionpolicyContent: data.termandconditionpolicyContent || '',
                });
            })
            .catch(error => {
                iziToast.error({ message: `Error fetching policy page data: ${error.message}`, position: 'topCenter' });
            });
    }, []);

    const handleEditorChange = (field, data) => {
        setFormData(prevData => ({ ...prevData, [field]: data }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(`${apipath}/policy/update`, formData)
            .then(response => {
                iziToast.success({ message: 'Policy page updated successfully', position: 'topCenter' });
            })
            .catch(error => {
                iziToast.error({ message: `Error updating policy page: ${error.message}`, position: 'topCenter' });
            });
    };

    return (
        <>
            <AdminSidebar />
            <div className="home">
                <div className="toggle-sidebar" style={{ display: 'none' }}>
                    <i className="bi bi-menu"></i>
                </div>
                <div className="dashboard">
                    <div className="dashboard-header">
                        <h1>Edit Policy Page</h1>
                    </div>
                    <div className="container-fluid mt-3">
                        <form className="row login-form" onSubmit={handleSubmit}>
                            <div className="mb-3 col-lg-12">
                                <label htmlFor="privacypolicyContent" className="mb-2">Privacy Policy Content</label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={formData.privacypolicyContent}
                                    onChange={(event, editor) => {
                                        handleEditorChange('privacypolicyContent', editor.getData());
                                    }}
                                />
                            </div>
                            <div className="mb-3 col-lg-12">
                                <label htmlFor="refundpolicyContent" className="mb-2">Refund Policy Content</label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={formData.refundpolicyContent}
                                    onChange={(event, editor) => {
                                        handleEditorChange('refundpolicyContent', editor.getData());
                                    }}
                                />
                            </div>
                            <div className="mb-3 col-lg-12">
                                <label htmlFor="termandconditionpolicyContent" className="mb-2">Terms and Conditions Policy Content</label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={formData.termandconditionpolicyContent}
                                    onChange={(event, editor) => {
                                        handleEditorChange('termandconditionpolicyContent', editor.getData());
                                    }}
                                />
                            </div>
                            <button type="submit" className="btn btn-primary">Save Changes</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PolicyPageEditor;
