import React, { useState, useEffect } from 'react';
import AdminSidebar from '../admin/AdminSidebar'; // Adjust the path as needed
import iziToast from 'izitoast';
import { useAuth } from '../../AuthContext';

const FooterForm = () => {
    const { apipath } = useAuth();
    const [formData, setFormData] = useState({
        logo: null, // Change to null to handle file input
        discription: '',
        address: '',
        phonenumber: '',
        email: '',
        weblink: '',
        copyright: '',
    });

    // Load data for an existing footer if needed
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${apipath}/footer/details`);
                if (response.ok) {
                    const result = await response.json();
                    setFormData({
                        logo: result.logo || null,
                        discription: result.discription || '',
                        address: result.address || '',
                        phonenumber: result.phonenumber || '',
                        email: result.email || '',
                        weblink: result.weblink || '',
                        copyright: result.copyright || '',
                    });
                } else {
                    iziToast.error({ message: 'Failed to load data', position: 'topCenter' });
                }
            } catch (error) {
                iziToast.error({ message: `An error occurred: ${error.message}`, position: 'topCenter' });
            }
        };
        fetchData();
    }, []);

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        if (type === 'file') {
            setFormData(prevData => ({ ...prevData, [name]: files[0] }));
        } else {
            setFormData(prevData => ({ ...prevData, [name]: value }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = new FormData();
        Object.keys(formData).forEach(key => {
            data.append(key, formData[key]);
        });

        try {
            const response = await fetch(`${apipath}/footer/update`, {
                method: 'POST',
                body: data // Use FormData to handle both text and file data
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Network response was not ok: ${errorText}`);
            }

            const result = await response.json();

            if (result.success) {
                iziToast.success({ message: 'Footer added/updated successfully', position: 'topCenter' });
            } else {
                iziToast.error({ message: 'Footer addition/update failed', position: 'topCenter' });
            }
        } catch (error) {
            iziToast.error({ message: `An error occurred: ${error.message}`, position: 'topCenter' });
        }
    };

    return (
        <>
            <AdminSidebar />
            <div className="home">
                <div className="toggle-sidebar" style={{ display: 'none' }}>
                    <i className="bi bi-menu"></i>
                </div>
                <div className="dashboard">
                    <div className="dashboard-header">
                        <h1>Add or Update Footer</h1>
                    </div>
                    <div className="container-fluid mt-3">
                        <form className="row login-form" onSubmit={handleSubmit}>
                            <div className="mb-3 col-lg-12">
                                <label htmlFor="logo" className="mb-2">Logo</label>
                                <input
                                    type="file"
                                    id="logo"
                                    name="logo"
                                    className="form-control"
                                    onChange={handleChange}
                                />
                                {formData.logo && (
                                    <p className="mt-1">Current Image: {formData.logo instanceof File ? formData.logo.name : formData.logo}</p>
                                )}
                            </div>
                            <div className="mb-3 col-lg-12">
                                <label htmlFor="discription" className="mb-2">Description</label>
                                <input
                                    type="text"
                                    id="discription"
                                    name="discription"
                                    className="form-control"
                                    placeholder="Enter description"
                                    value={formData.discription}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="mb-3 col-lg-12">
                                <label htmlFor="address" className="mb-2">Address</label>
                                <input
                                    type="text"
                                    id="address"
                                    name="address"
                                    className="form-control"
                                    placeholder="Enter address"
                                    value={formData.address}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="mb-3 col-lg-12">
                                <label htmlFor="phonenumber" className="mb-2">Phone Number</label>
                                <input
                                    type="text"
                                    id="phonenumber"
                                    name="phonenumber"
                                    className="form-control"
                                    placeholder="Enter phone number"
                                    value={formData.phonenumber}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="mb-3 col-lg-12">
                                <label htmlFor="email" className="mb-2">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    className="form-control"
                                    placeholder="Enter email"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="mb-3 col-lg-12">
                                <label htmlFor="email" className="mb-2">Web-Link</label>
                                <input
                                    type="text"
                                    id="text"
                                    name="weblink"
                                    className="form-control"
                                    placeholder="Web-Link"
                                    value={formData.weblink}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="mb-3 col-lg-12">
                                <label htmlFor="copyright" className="mb-2">Copyright</label>
                                <input
                                    type="text"
                                    id="copyright"
                                    name="copyright"
                                    className="form-control"
                                    placeholder="Enter copyright"
                                    value={formData.copyright}
                                    onChange={handleChange}
                                />
                            </div>
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FooterForm;
