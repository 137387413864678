// import React, { useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import iziToast from "izitoast";
// import validator from "validator";
// import Navbar from "../pages/Navbar";
// import Footer from "../pages/Footer";

// const Register = () => {
//   const [showPassword, setShowPassword] = useState(null);
//   const [errors, setErrors] = useState({});
//   const navigate = useNavigate();
//   const [formData, setFormData] = useState({
//     name: "",
//     phone: "",
//     email: "",
//     companyName: "",
//     address: "",
//     password: "",
//     agreeToTerms: false,
//   });

//   const handleVisibility = (index) => {
//     setShowPassword(showPassword === index ? null : index);
//   };

//   const handleChange = (e) => {
//     const { name, value, type, checked } = e.target;
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       [name]: type === "checkbox" ? checked : value,
//     }));

//     // Clear validation error when user starts typing
//     if (errors[name]) {
//       setErrors((prevErrors) => ({
//         ...prevErrors,
//         [name]: '',
//       }));
//     }
//   };

//   const handleRegister = async (e) => {
//     e.preventDefault();
//     // Perform validation
//     const validationErrors = {};
//     if (!formData.name) {
//       validationErrors.name = 'Name is required';
//     }
//     if (!formData.email) {
//       validationErrors.email = 'Email is required';
//     } else if (!validator.isEmail(formData.email)) {
//       validationErrors.email = 'Invalid email address';
//     }
//     if (!formData.phone) {
//       validationErrors.phone = 'Mobile number is required';
//     }
//     if (!formData.password) {
//       validationErrors.password = 'Password is required';
//     }
//     if (!formData.companyName) {
//       validationErrors.companyName = 'Company Name is required';
//     }
//     if (!formData.address) {
//       validationErrors.address = 'Address is required';
//     }
//     if (!formData.agreeToTerms) {
//       validationErrors.agreeToTerms = 'You must agree to the terms and privacy policies';
//     }

//     // Set validation errors
//     setErrors(validationErrors);

//     // If there are validation errors, prevent form submission
//     if (Object.keys(validationErrors).length > 0) {
//       return;
//     }

//     try {
//         const response = await fetch('http://localhost:4000/api/v1/users/register', {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(formData),
//         });

//         const data = await response.json();

//         if (response.status === 201) {
//           iziToast.success({
//             title: 'Success',
//             message: 'Registration successful',
//             position: 'topCenter',
//             timeout: 2000,
//           });
//           navigate('/login');
//         } else if (response.status === 400) {
//           iziToast.error({
//             title: 'Error',
//             message: data.message || 'Email already exists. Please try another email.',
//             position: 'topCenter',
//           });
//         } else {
//           iziToast.error({
//             title: 'Error',
//             message: 'Registration failed. Please try again later.',
//             position: 'topCenter',
//           });
//         }
//       } catch (error) {
//         iziToast.error({
//           title: 'Error',
//           message: 'Registration failed. Please try again later.',
//           position: 'topCenter',
//         });
//       }
//     };

//   return (
//     <>
//       <Navbar />

//       <div className="container my-5 px-2 py-5 bg-login-blue shadow-custom-login rounded-2xl flex flex-wrap">
//         <div className="w-full lg:w-6/12 flex items-center">
//           <img src="imgs-alu/login.png" alt="" className="w-full" />
//         </div>
//         <div className="w-full lg:w-1/2 flex flex-col justify-center items-center">
//           <div className="flex items-center">
//             <div>
//               <img src="imgs-alu/waving-hand.png" alt="" />
//             </div>
//             <h1 className="font-montserrat font-semibold text-[36px] text-m-blue ml-3">
//               Welcome
//             </h1>
//           </div>
//           <p className="font-poppins font-medium text-lg text-dark-gray">
//             Please fill in your details
//           </p>
//           <form action="" className="w-full" onSubmit={handleRegister}>
//             <div className="w-full lg:w-8/12 mx-auto mt-3">
//               <label htmlFor="" className="block font-poppins font-medium text-base mb-1">
//                 Full Name
//               </label>
//               <input
//                 type="text"
//                 className={`w-full p-2 bg-login-input-blue rounded-[10px] focus:outline-none font-normal font-poppins text-sm text-gray-3 ${errors.name && "is-invalid"}`}
//                 placeholder="Enter Your Full name"
//                 name="name"
//                 value={formData.name}
//                 onChange={handleChange}
//                 maxLength="25"
//               />
//               {errors.name && <div className="invalid-feedback">{errors.name}</div>}
//             </div>
//             <div className="w-full lg:w-8/12 mx-auto mt-3">
//               <label htmlFor="" className="block font-poppins font-medium text-base mb-1">
//                 Email
//               </label>
//               <input
//                 type="email"
//                 className={`w-full p-2 bg-login-input-blue rounded-[10px] focus:outline-none font-normal font-poppins text-sm text-gray-3 ${errors.email && "is-invalid"}`}
//                 placeholder="Enter Your Email"
//                 name="email"
//                 value={formData.email}
//                 onChange={handleChange}
//                 maxLength="50"
//               />
//               {errors.email && <div className="invalid-feedback">{errors.email}</div>}
//             </div>
//             <div className="w-full lg:w-8/12 mx-auto mt-3">
//               <label htmlFor="" className="block font-poppins font-medium text-base mb-1">
//                 Phone No.
//               </label>
//               <input
//                 type="text"
//                 className={`w-full p-2 bg-login-input-blue rounded-[10px] focus:outline-none font-normal font-poppins text-sm text-gray-3 ${errors.phone && "is-invalid"}`}
//                 placeholder="Enter Your Phone Number"
//                 name="phone"
//                 value={formData.phone}
//                 onChange={handleChange}
//                 maxLength="15"
//                 autoComplete="off"
//                 onKeyPress={(event) => {
//                   if (event.charCode < 48 || event.charCode > 57 || event.target.value.length >= 15) {
//                     event.preventDefault();
//                   }
//                 }}
//               />
//               {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
//             </div>
//             <div className="w-full lg:w-8/12 mx-auto mt-3">
//               <label htmlFor="" className="block font-poppins font-medium text-base mb-1">
//                 Company Name
//               </label>
//               <input
//                 type="text"
//                 className={`w-full p-2 bg-login-input-blue rounded-[10px] focus:outline-none font-normal font-poppins text-sm text-gray-3 ${errors.companyName && "is-invalid"}`}
//                 placeholder="Enter Your Company Name"
//                 name="companyName"
//                 value={formData.companyName}
//                 onChange={handleChange}
//                 maxLength="50"
//               />
//               {errors.companyName && <div className="invalid-feedback">{errors.companyName}</div>}
//             </div>
//             <div className="w-full lg:w-8/12 mx-auto my-3">
//               <label htmlFor="" className="block font-poppins font-medium text-base mb-1">
//                 Address
//               </label>
//               <textarea
//                 className={`w-full p-2 bg-login-input-blue rounded-[10px] focus:outline-none font-normal font-poppins text-sm text-gray-3 ${errors.address && "is-invalid"}`}
//                 placeholder="Enter Your Address"
//                 name="address"
//                 value={formData.address}
//                 onChange={handleChange}
//                 maxLength="50"
//               />
//               {errors.address && <div className="invalid-feedback">{errors.address}</div>}
//             </div>
//             <div className="w-full lg:w-8/12 mx-auto my-3">
//               <label htmlFor="" className="block font-poppins font-medium text-base mb-1">
//                 Password
//               </label>
//               <div className="flex w-full p-2 bg-login-input-blue rounded-[10px]">
//                 <input
//                   type={showPassword === 2 ? "text" : "password"}
//                   placeholder={`Enter Password ${errors.address && "is-invalid"}`}
//                   className="w-full bg-login-input-blue rounded-[10px] focus:outline-none font-normal font-poppins text-sm text-gray-3"
//                   name="password"
//                   value={formData.password}
//                   onChange={handleChange}
//                 />
//                 <span>
//                   <i
//                     className={`cursor-pointer ${showPassword === 2 ? "bi bi-eye-slash" : "bi bi-eye"}`}
//                     onClick={() => handleVisibility(2)}
//                   ></i>
//                 </span>
//                 {errors.password && <div className="invalid-feedback">{errors.password}</div>}
//               </div>
//             </div>
//             <div className="w-full lg:w-8/12 mx-auto my-3">
//               <p>
//                 <input
//                   type="checkbox"
//                   className={`me-2 ${errors.agreeToTerms && "is-invalid"}`}
//                   id="agreeToTerms"
//                   name="agreeToTerms"
//                   checked={formData.agreeToTerms}
//                   onChange={handleChange}
//                 />
//                 By submitting this form I agree to
//                 <span> terms & Condition, Privacy Policy, & Cookie Policy</span>
//                 {errors.agreeToTerms && <div className="invalid-feedback">{errors.agreeToTerms}</div>}
//               </p>
//             </div>
//             <div className="w-full lg:w-8/12 mx-auto pt-3">
//               <button className="w-full py-[10px] bg-m-blue text-white rounded-[10px] text-center font-Poppins text-lg font-medium">
//                 Register
//               </button>
//             </div>
//           </form>
//           <p className="text-black text-center font-poppins text-base font-medium leading-8">
//             Already have an account ?{" "}
//             <Link to="/login" className="no-underline">
//               <span className="text-m-blue">Login</span>
//             </Link>
//           </p>
//         </div>
//       </div>

//       <Footer />
//     </>
//   );
// };

// export default Register;



import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import iziToast from "izitoast";
import validator from "validator";
import Navbar from "../pages/Navbar";
import Footer from "../pages/Footer";
import { useAuth } from "../../AuthContext";

const Register = () => {
  const { apipath } = useAuth();
  const [showPassword, setShowPassword] = useState(null);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    companyName: "",
    address: "",
    password: "",
    agreeToTerms: false,
  });

  const handleVisibility = (index) => {
    setShowPassword(showPassword === index ? null : index);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));

    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    const validationErrors = {};
    if (!formData.name) {
      validationErrors.name = "Name is required";
    }
    if (!formData.email) {
      validationErrors.email = "Email is required";
    } else if (!validator.isEmail(formData.email)) {
      validationErrors.email = "Invalid email address";
    }
    if (!formData.phone) {
      validationErrors.phone = "Mobile number is required";
    }
    if (!formData.password) {
      validationErrors.password = "Password is required";
    }
    if (!formData.companyName) {
      validationErrors.companyName = "Company Name is required";
    }
    if (!formData.address) {
      validationErrors.address = "Address is required";
    }
    if (!formData.agreeToTerms) {
      validationErrors.agreeToTerms = "You must agree to the terms and privacy policies";
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    try {
      const response = await fetch(`${apipath}/users/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.status === 201) {
        iziToast.success({
          title: "Success",
          message: "Registration initiated. Check your phone for the OTP.",
          position: "topCenter",
          timeout: 2000,
        });
        navigate("/verify-otp", { state: { email: formData.email, phone: formData.phone } });
      } else if (response.status === 400) {
        iziToast.error({
          title: "Error",
          message: data.message || "Email already exists. Please try another email.",
          position: "topCenter",
        });
      } else {
        iziToast.error({
          title: "Error",
          message: "Registration failed. Please try again later.",
          position: "topCenter",
        });
      }
    } catch (error) {
      iziToast.error({
        title: "Error",
        message: "Registration failed. Please try again later.",
        position: "topCenter",
      });
    }
  };

  return (
    <>
      <Navbar />
      <div className="container my-5 px-2 py-5 bg-login-blue shadow-custom-login rounded-2xl flex flex-wrap">
        <div className="w-full lg:w-6/12 flex items-center">
          <img src="imgs-alu/login.png" alt="" className="w-full" />
        </div>
        <div className="w-full lg:w-1/2 flex flex-col justify-center items-center">
          <div className="flex items-center">
            <div>
              <img src="imgs-alu/waving-hand.png" alt="" />
            </div>
            <h1 className="font-montserrat font-semibold text-[36px] text-m-blue ml-3">
              Welcome
            </h1>
          </div>
          <p className="font-poppins font-medium text-lg text-dark-gray">
            Please fill in your details
          </p>
          <form className="w-full" onSubmit={handleRegister}>
            <div className="w-full lg:w-8/12 mx-auto mt-3">
              <label className="block font-poppins font-medium text-base mb-1">
                Full Name
              </label>
              <input
                type="text"
                className={`w-full p-2 bg-login-input-blue rounded-[10px] focus:outline-none font-normal font-poppins text-sm text-gray-3 ${errors.name && "is-invalid"}`}
                placeholder="Enter Your Full name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                maxLength="25"
              />
              {errors.name && <div className="invalid-feedback">{errors.name}</div>}
            </div>
            <div className="w-full lg:w-8/12 mx-auto mt-3">
              <label className="block font-poppins font-medium text-base mb-1">
                Email
              </label>
              <input
                type="email"
                className={`w-full p-2 bg-login-input-blue rounded-[10px] focus:outline-none font-normal font-poppins text-sm text-gray-3 ${errors.email && "is-invalid"}`}
                placeholder="Enter Your Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                maxLength="50"
              />
              {errors.email && <div className="invalid-feedback">{errors.email}</div>}
            </div>
            <div className="w-full lg:w-8/12 mx-auto mt-3">
              <label className="block font-poppins font-medium text-base mb-1">
                Phone No.
              </label>
              <input
                type="text"
                className={`w-full p-2 bg-login-input-blue rounded-[10px] focus:outline-none font-normal font-poppins text-sm text-gray-3 ${errors.phone && "is-invalid"}`}
                placeholder="Enter Your Phone Number"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                maxLength="15"
                autoComplete="off"
                onKeyPress={(event) => {
                  if (event.charCode < 48 || event.charCode > 57 || event.target.value.length >= 15) {
                    event.preventDefault();
                  }
                }}
              />
              {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
            </div>
            <div className="w-full lg:w-8/12 mx-auto mt-3">
              <label className="block font-poppins font-medium text-base mb-1">
                Company Name
              </label>
              <input
                type="text"
                className={`w-full p-2 bg-login-input-blue rounded-[10px] focus:outline-none font-normal font-poppins text-sm text-gray-3 ${errors.companyName && "is-invalid"}`}
                placeholder="Enter Your Company Name"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
                maxLength="50"
              />
              {errors.companyName && <div className="invalid-feedback">{errors.companyName}</div>}
            </div>
            <div className="w-full lg:w-8/12 mx-auto my-3">
              <label className="block font-poppins font-medium text-base mb-1">
                Address
              </label>
              <textarea
                className={`w-full p-2 bg-login-input-blue rounded-[10px] focus:outline-none font-normal font-poppins text-sm text-gray-3 ${errors.address && "is-invalid"}`}
                placeholder="Enter Your Address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                maxLength="50"
              />
              {errors.address && <div className="invalid-feedback">{errors.address}</div>}
            </div>
            <div className="w-full lg:w-8/12 mx-auto my-3">
              <label className="block font-poppins font-medium text-base mb-1">
                Password
              </label>
              <div className="flex w-full p-2 bg-login-input-blue rounded-[10px]">
                <input
                  type={showPassword === 2 ? "text" : "password"}
                  placeholder="Enter Password"
                  className="w-full bg-login-input-blue rounded-[10px] focus:outline-none font-normal font-poppins text-sm text-gray-3"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                />
                <span>
                  <i
                    className={`cursor-pointer ${showPassword === 2 ? "bi bi-eye-slash" : "bi bi-eye"}`}
                    onClick={() => handleVisibility(2)}
                  ></i>
                </span>
                {errors.password && <div className="invalid-feedback">{errors.password}</div>}
              </div>
            </div>
            {/* <div className="w-full lg:w-8/12 mx-auto my-3">
              <p>
                <input
                  type="checkbox"
                  className={`me-2 ${errors.agreeToTerms && "is-invalid"}`}
                  id="agreeToTerms"
                  name="agreeToTerms"
                  checked={formData.agreeToTerms}
                  onChange={handleChange}
                />
                By submitting this form I agree to
                <span> Terms & Conditions, Privacy Policy, & Rufund Policy</span>
                {errors.agreeToTerms && <div className="invalid-feedback">{errors.agreeToTerms}</div>}
              </p>
            </div> */}
            <div className="w-full lg:w-8/12 mx-auto my-3">
              <p>
                <input
                  type="checkbox"
                  className={`me-2 ${errors.agreeToTerms && "is-invalid"}`}
                  id="agreeToTerms"
                  name="agreeToTerms"
                  checked={formData.agreeToTerms}
                  onChange={handleChange}
                />
                By submitting this form, I agree to{" "}
                <span>
                <a href="/refund" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                    Refund Policy
                  </a>
                  ,{" "}
                  <a href="/privacy" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                    Privacy Policy
                  </a>
                  {" "}&{" "}
                  <a href="/terms" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                    Terms & Conditions
                  </a>
                </span>
                {errors.agreeToTerms && <div className="invalid-feedback">{errors.agreeToTerms}</div>}
              </p>
            </div>

            <div className="w-full lg:w-8/12 mx-auto pt-3">
              <button className="w-full py-[10px] bg-m-blue text-white rounded-[10px] text-center font-Poppins text-lg font-medium">
                Register
              </button>
            </div>
          </form>
          <p className="text-black text-center font-poppins text-base font-medium leading-8">
            Already have an account?{" "}
            <Link to="/login" className="no-underline">
              <span className="text-m-blue">Login</span>
            </Link>
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Register;
