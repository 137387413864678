import React, { useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link } from "react-router-dom";

const Specification = () => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);


  return (
    <>
      <Navbar />

      <div className="container-fluid main-banner">
        <div className="heading-main">
          <h1 className="text-[48px] text-white font-candal text-center">
            Specifications
          </h1>
          <h4 className="text-[17px] font-exo text-white font-medium text-center">
            Home{" "}
            <i className="bi bi-chevron-double-right text-light-yellow"></i>
            Specifications
          </h4>
        </div>
      </div>

      <div className="container my-5 pb-2 border-b border-[#C0C0C0]">
        <div className="font-jost text-base font-medium">
          <span className="text-enquiry-button">Category </span>
          <i className="bi bi-chevron-right text-enquiry-button"></i>
          <span className="text-black"> Channel </span>
          <i className="bi bi-chevron-right text-enquiry-button"></i>
          <span className="text-black"> 7898 </span>
        </div>
      </div>

      <div className="container">
        <div className="w-full">
          <div className="bg-enquiry-button font-nunito text-base font-semibold text-center text-black p-2 border border-black">TECHNICAL FACT SHEET</div>
          <table className="w-full p-2 mx-auto overflow-auto mb-8">
            <thead>

              <tr className=" font-nunito text-base font-semibold text-center text-black">
                <th className="p-2 border border-black">Parameters</th>
                <th className="p-2 border border-black">Press 1</th>
                <th className="p-2 border border-black">Press 2</th>
                <th className="p-2 border border-black">Press 3</th>
              </tr>
              <tr className=" font-nunito text-base font-semibold text-center text-black">
                <th className="p-2 border border-black">Make</th>
                <th className="p-2 border border-black">Wean United (USA)</th>
                <th className="p-2 border border-black">Wean United (USA)</th>
                <th className="p-2 border border-black">Wean United (USA)</th>
              </tr>
            </thead>
            <tbody>

              <tr className="font-nunito text-base font-normal text-center">
                <td className="p-2 border border-black">
                  Strength of extrusion press (TONS)
                </td>
                <td className="p-2 border border-black">1800</td>
                <td className="p-2 border border-black">960</td>
                <td className="p-2 border border-black">2750</td>
              </tr>
              <tr className="font-nunito text-base font-normal text-center">
                <td className="p-2 border border-black">
                  Strength of extrusion press (TONS)
                </td>
                <td className="p-2 border border-black">1800</td>
                <td className="p-2 border border-black">960</td>
                <td className="p-2 border border-black">2750</td>
              </tr>
              <tr className="font-nunito text-base font-normal text-center">
                <td className="p-2 border border-black">
                  Strength of extrusion press (TONS)
                </td>
                <td className="p-2 border border-black">1800</td>
                <td className="p-2 border border-black">960</td>
                <td className="p-2 border border-black">2750</td>
              </tr>
              <tr className="font-nunito text-base font-normal text-center">
                <td className="p-2 border border-black">
                  Strength of extrusion press (TONS)
                </td>
                <td className="p-2 border border-black">1800</td>
                <td className="p-2 border border-black">960</td>
                <td className="p-2 border border-black">2750</td>
              </tr>
              <tr className="font-nunito text-base font-normal text-center">
                <td className="p-2 border border-black">
                  Strength of extrusion press (TONS)
                </td>
                <td className="p-2 border border-black">1800</td>
                <td className="p-2 border border-black">960</td>
                <td className="p-2 border border-black">2750</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* <div className="flex justify-center my-14">
        <Link to="/cart">
          <button className="py-2 px-12 rounded-pill bg-enquiry-button font-poppins font-medium text-white text-lg border-1 border-enquiry-button hover:bg-transparent hover:!text-enquiry-button hover:ease-in-out duration-300">
            Proceed To Pay
          </button>
        </Link>
      </div> */}

      <Footer />
    </>
  );
};

export default Specification;
