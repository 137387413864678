// import React from "react";
// import { Link } from "react-router-dom";
// import Navbar from "./Navbar";
// import Footer from "./Footer";

// const Billing = () => {
//   return (
//     <>
//       <Navbar />

//       <Link to="/" className="float">
//         <img src="imgs-alu/whtsp.png" alt="" />
//       </Link>

//       <div className="container-fluid main-banner">
//         <div className="heading-main">
//           <h1 className="text-[48px] text-white font-candal text-center">
//           Billing Information
//           </h1>
//           <h4 className="text-[17px] font-exo text-white font-medium text-center">
//             Home{" "}
//             <i className="bi bi-chevron-double-right text-light-yellow"></i>
//             Billing Information
//           </h4>
//         </div>
//       </div>

//       <div className="container py-4">
//         <div className="main-heading">
//           <h1>
//             Pl<span>ease fill your information bel</span>ow
//           </h1>
//           </div>

//           <div className="w-full lg:w-8/12 p-5 my-4 mx-auto bg-light-blue rounded-xl shadow-custom-contact">
//             <form action="" className="flex flex-wrap">
//               <div className="w-full lg:w-1/2 p-2">
//                 <div>
//                   <label
//                     htmlFor=""
//                     className="block font-poppins font-medium text-base mb-1"
//                   >
//                     Name
//                   </label>
//                   <input
//                     type="text"
//                     placeholder="Enter your Name"
//                     className="w-full p-2 bg-transparent border-1 rounded-lg border-input-border focus:outline-none font-normal font-poppins text-sm text-gray-3"
//                   />
//                 </div>
//               </div>
//               <div className="w-full lg:w-1/2 p-2">
//                 <div>
//                   <label
//                     htmlFor=""
//                     className="block font-poppins font-medium text-base mb-1"
//                   >
//                     Email
//                   </label>
//                   <input
//                     type="text"
//                     placeholder="Enter your Email"
//                     className="w-full p-2 bg-transparent border-1 rounded-lg border-input-border focus:outline-none font-normal font-poppins text-sm text-gray-3"
//                   />
//                 </div>
//               </div>
//               <div className="w-full lg:w-1/2 p-2">
//                 <div>
//                   <label
//                     htmlFor=""
//                     className="block font-poppins font-medium text-base mb-1"
//                   >
//                     Phone no.
//                   </label>
//                   <input
//                     type="text"
//                     placeholder="+91-00000-00000"
//                     className="w-full p-2 bg-transparent border-1 rounded-lg border-input-border focus:outline-none font-normal font-poppins text-sm text-gray-3"
//                   />
//                 </div>
//               </div>
//               <div className="w-full lg:w-1/2 p-2">
//                 <div>
//                   <label
//                     htmlFor=""
//                     className="block font-poppins font-medium text-base mb-1"
//                   >
//                     Pin Code
//                   </label>
//                   <input
//                     type="text"
//                     placeholder="Pin Code"
//                     className="w-full p-2 bg-transparent border-1 rounded-lg border-input-border focus:outline-none font-normal font-poppins text-sm text-gray-3"
//                   />
//                 </div>
//               </div>
//               <div className="w-full lg:w-1/2 p-2">
//                 <div>
//                   <label
//                     htmlFor=""
//                     className="block font-poppins font-medium text-base mb-1"
//                   >
//                     Address
//                   </label>
//                   <input
//                     type="text"
//                     placeholder="Address"
//                     className="w-full p-2 bg-transparent border-1 rounded-lg border-input-border focus:outline-none font-normal font-poppins text-sm text-gray-3"
//                   />
//                 </div>
//               </div>
//               <div className="w-full lg:w-1/2 p-2">
//                 <div>
//                   <label
//                     htmlFor=""
//                     className="block font-poppins font-medium text-base mb-1"
//                   >
//                     City
//                   </label>
//                   <input
//                     type="text"
//                     placeholder="City"
//                     className="w-full p-2 bg-transparent border-1 rounded-lg border-input-border focus:outline-none font-normal font-poppins text-sm text-gray-3"
//                   />
//                 </div>
//               </div>
//               <div className="w-full lg:w-1/2 p-2">
//                 <div>
//                   <label
//                     htmlFor=""
//                     className="block font-poppins font-medium text-base mb-1"
//                   >
//                    State
//                   </label>
//                   <input
//                     type="text"
//                     placeholder="State"
//                     className="w-full p-2 bg-transparent border-1 rounded-lg border-input-border focus:outline-none font-normal font-poppins text-sm text-gray-3"
//                   />
//                 </div>
//               </div>
//               <div className="w-full lg:w-1/2 p-2">
//                 <div>
//                   <label
//                     htmlFor=""
//                     className="block font-poppins font-medium text-base mb-1"
//                   >
//                     Country
//                   </label>
//                   <input
//                     type="text"
//                     placeholder="Country"
//                     className="w-full p-2 bg-transparent border-1 rounded-lg border-input-border focus:outline-none font-normal font-poppins text-sm text-gray-3"
//                   />
//                 </div>
//               </div>
              
//               <div className="w-full p-2">
//                 <div>
//                   <label
//                     htmlFor=""
//                     className="block font-poppins font-medium text-base mb-1"
//                   >
//                     Message
//                   </label>
//                   <textarea
//                     type="text"
//                     placeholder="Enter Message"
//                     className="w-full p-2 bg-transparent border-1 rounded-lg border-input-border focus:outline-none font-normal font-poppins text-sm text-gray-3"
//                   >
//                     {" "}
//                   </textarea>
//                 </div>
//               </div>
              

//               <div className="mt-4">
//                 <button className="py-2 px-12 rounded-pill bg-enquiry-button font-poppins font-medium text-white text-lg border-1 border-enquiry-button hover:bg-transparent hover:!text-enquiry-button hover:ease-in-out duration-300">
//                   Submit
//                 </button>
//               </div>
//             </form>
//           </div>
        
//       </div>

//       <Footer />
//     </>
//   );
// };

// export default Billing;


import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import axios from "axios";
import { useAuth } from "../../AuthContext";
import iziToast from 'izitoast';

const Billing = () => {
  const { apipath } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { cart } = location.state || { cart: [] };

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    pinCode: '',
    address: '',
    city: '',
    state: '',
    country: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      await axios.post(`${apipath}/billing/submit`, { ...formData, cart }, {
        headers: { Authorization: `Bearer ${token}` }
      });

      iziToast.success({
        title: 'Success',
        message: 'Billing information saved successfully',
        position: 'topCenter'
      });

      navigate('/bill-summary', { state: { cart, formData } });
    } catch (error) {
      console.error('Error submitting billing information:', error);
      iziToast.error({
        title: 'Error',
        message: 'Failed to save billing information',
        position: 'topCenter'
      });
    }
  };

  return (
    <>
      <Navbar />
      <Link to="/" className="float">
        <img src="imgs-alu/whtsp.png" alt="WhatsApp" />
      </Link>
      <div className="container-fluid main-banner">
        <div className="heading-main">
          <h1 className="text-[48px] text-white font-candal text-center">
            Billing Information
          </h1>
          <h4 className="text-[17px] font-exo text-white font-medium text-center">
            Home <i className="bi bi-chevron-double-right text-light-yellow"></i> Billing Information
          </h4>
        </div>
      </div>
      <div className="container py-4">
        <div className="main-heading">
          <h1>
            Pl<span>ease fill your information bel</span>ow
          </h1>
        </div>
        <div className="w-full lg:w-8/12 p-5 my-4 mx-auto bg-light-blue rounded-xl shadow-custom-contact">
          <form onSubmit={handleSubmit} className="flex flex-wrap">
            <div className="w-full lg:w-1/2 p-2">
              <div>
                <label className="block font-poppins font-medium text-base mb-1">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Enter your Name"
                  className="w-full p-2 bg-transparent border-1 rounded-lg border-input-border focus:outline-none font-normal font-poppins text-sm text-gray-3"
                />
              </div>
            </div>
            <div className="w-full lg:w-1/2 p-2">
              <div>
                <label className="block font-poppins font-medium text-base mb-1">
                  Email
                </label>
                <input
                  type="text"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter your Email"
                  className="w-full p-2 bg-transparent border-1 rounded-lg border-input-border focus:outline-none font-normal font-poppins text-sm text-gray-3"
                />
              </div>
            </div>
            <div className="w-full lg:w-1/2 p-2">
              <div>
                <label className="block font-poppins font-medium text-base mb-1">
                  Phone no.
                </label>
                <input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="+91-00000-00000"
                  className="w-full p-2 bg-transparent border-1 rounded-lg border-input-border focus:outline-none font-normal font-poppins text-sm text-gray-3"
                />
              </div>
            </div>
            <div className="w-full lg:w-1/2 p-2">
              <div>
                <label className="block font-poppins font-medium text-base mb-1">
                  Pin Code
                </label>
                <input
                  type="text"
                  name="pinCode"
                  value={formData.pinCode}
                  onChange={handleChange}
                  placeholder="Pin Code"
                  className="w-full p-2 bg-transparent border-1 rounded-lg border-input-border focus:outline-none font-normal font-poppins text-sm text-gray-3"
                />
              </div>
            </div>
            <div className="w-full lg:w-1/2 p-2">
              <div>
                <label className="block font-poppins font-medium text-base mb-1">
                  Address
                </label>
                <input
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  placeholder="Address"
                  className="w-full p-2 bg-transparent border-1 rounded-lg border-input-border focus:outline-none font-normal font-poppins text-sm text-gray-3"
                />
              </div>
            </div>
            <div className="w-full lg:w-1/2 p-2">
              <div>
                <label className="block font-poppins font-medium text-base mb-1">
                  City
                </label>
                <input
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  placeholder="City"
                  className="w-full p-2 bg-transparent border-1 rounded-lg border-input-border focus:outline-none font-normal font-poppins text-sm text-gray-3"
                />
              </div>
            </div>
            <div className="w-full lg:w-1/2 p-2">
              <div>
                <label className="block font-poppins font-medium text-base mb-1">
                  State
                </label>
                <input
                  type="text"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  placeholder="State"
                  className="w-full p-2 bg-transparent border-1 rounded-lg border-input-border focus:outline-none font-normal font-poppins text-sm text-gray-3"
                />
              </div>
            </div>
            <div className="w-full lg:w-1/2 p-2">
              <div>
                <label className="block font-poppins font-medium text-base mb-1">
                  Country
                </label>
                <input
                  type="text"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  placeholder="Country"
                  className="w-full p-2 bg-transparent border-1 rounded-lg border-input-border focus:outline-none font-normal font-poppins text-sm text-gray-3"
                />
              </div>
            </div>
            <div className="w-full p-2">
              <div>
                <label className="block font-poppins font-medium text-base mb-1">
                  Message
                </label>
                <textarea
                  type="text"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Enter Message"
                  className="w-full p-2 bg-transparent border-1 rounded-lg border-input-border focus:outline-none font-normal font-poppins text-sm text-gray-3"
                />
              </div>
            </div>
            <div className="mt-4">
              <button className="py-2 px-12 rounded-pill bg-enquiry-button font-poppins font-medium text-white text-lg border-1 border-enquiry-button hover:bg-transparent hover:!text-enquiry-button hover:ease-in-out duration-300">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Billing;
