import React, { useState, useEffect } from 'react';
import AdminSidebar from '../admin/AdminSidebar';
import iziToast from 'izitoast';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext';

const ManufacturingCmsPageAdd = () => {
    const { apipath } = useAuth();
    const [formData, setFormData] = useState({
        pageType: '',
        bannerImage: null,
        headContent: '',
        maintitle: '',
        sideImage: null,
        body1title: '',
        body1content: [{ content: '' }], // Changed to an array of objects
        body2card: [{ cardTitle: '', cardContent: '', bulletins: [''] }],
        bottomstatement: ''
    });
    const [images, setImages] = useState({ bannerImage: null, sideImage: null });
    const navigate = useNavigate();

    useEffect(() => {
        if (formData.pageType) {
            (async () => {
                try {
                    const response = await fetch(apipath +`/manufacturingcms/content/${formData.pageType}`);
                    if (response.ok) {
                        const result = await response.json();
                        setFormData(prevData => ({
                            ...prevData,
                            ...result,
                            body1content: result.body1content || [{ content: '' }], // Ensure correct structure
                            body2card: result.body2card || [{ cardTitle: '', cardContent: '', bulletins: [''] }]
                        }));
                        setImages({
                            bannerImage: result.bannerImage ? { name: result.bannerImage } : null,
                            sideImage: result.sideImage ? { name: result.sideImage } : null
                        });
                    } else {
                        iziToast.error({ message: 'Failed to load data', position: 'topCenter' });
                    }
                } catch (error) {
                    iziToast.error({ message: `An error occurred: ${error.message}`, position: 'topCenter' });
                }
            })();
        }
    }, [formData.pageType]);

    const handleChange = (e, field) => {
        const { value } = e.target;
        setFormData(prevData => ({ ...prevData, [field]: value }));
    };

    const handleBodyContentChange = (index, value) => {
        setFormData(prevData => {
            const updatedContent = [...prevData.body1content];
            updatedContent[index] = { content: value }; // Update to match schema
            return { ...prevData, body1content: updatedContent };
        });
    };

    const handleAddBodyContent = () => {
        setFormData(prevData => ({
            ...prevData,
            body1content: [...prevData.body1content, { content: '' }] // New entry is an object
        }));
    };

    const handleRemoveBodyContent = (index) => {
        setFormData(prevData => {
            const updatedContent = [...prevData.body1content];
            updatedContent.splice(index, 1);
            return { ...prevData, body1content: updatedContent };
        });
    };

    const handleCardChange = (cardIndex, field, value) => {
        setFormData(prevData => {
            const updatedCards = [...prevData.body2card];
            updatedCards[cardIndex] = { ...updatedCards[cardIndex], [field]: value };
            return { ...prevData, body2card: updatedCards };
        });
    };

    const handleBulletinChange = (cardIndex, bulletinIndex, value) => {
        setFormData(prevData => {
            const updatedCards = [...prevData.body2card];
            const updatedBulletins = [...updatedCards[cardIndex].bulletins];
            updatedBulletins[bulletinIndex] = value;
            updatedCards[cardIndex] = { ...updatedCards[cardIndex], bulletins: updatedBulletins };
            return { ...prevData, body2card: updatedCards };
        });
    };

    const handleAddBulletin = (cardIndex) => {
        setFormData(prevData => {
            const updatedCards = [...prevData.body2card];
            updatedCards[cardIndex].bulletins.push('');
            return { ...prevData, body2card: updatedCards };
        });
    };

    const handleRemoveBulletin = (cardIndex, bulletinIndex) => {
        setFormData(prevData => {
            const updatedCards = [...prevData.body2card];
            const updatedBulletins = [...updatedCards[cardIndex].bulletins];
            updatedBulletins.splice(bulletinIndex, 1);
            updatedCards[cardIndex] = { ...updatedCards[cardIndex], bulletins: updatedBulletins };
            return { ...prevData, body2card: updatedCards };
        });
    };

    const handleAddCard = () => {
        setFormData(prevData => ({
            ...prevData,
            body2card: [...prevData.body2card, { cardTitle: '', cardContent: '', bulletins: [''] }]
        }));
    };

    const handleRemoveCard = (cardIndex) => {
        setFormData(prevData => {
            const updatedCards = [...prevData.body2card];
            updatedCards.splice(cardIndex, 1);
            return { ...prevData, body2card: updatedCards };
        });
    };

    const handleFileChange = (e, field) => {
        setImages(prevImages => ({ ...prevImages, [field]: e.target.files[0] }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = new FormData();
        data.append('pageType', formData.pageType);
        data.append('headContent', formData.headContent);
        data.append('maintitle', formData.maintitle);
        data.append('body1title', formData.body1title);
        data.append('body1content', JSON.stringify(formData.body1content));
        data.append('body2card', JSON.stringify(formData.body2card));
        data.append('bottomstatement', formData.bottomstatement);

        if (images.bannerImage) {
            data.append('bannerImage', images.bannerImage);
        }

        if (images.sideImage) {
            data.append('sideImage', images.sideImage);
        }

        try {
            const response = await fetch(`${apipath}/manufacturingcms/edit`, {
                method: 'POST',
                body: data
            });
        
            if (!response.ok) {
                const errorText = await response.text();
                console.error('Error response:', errorText);
                throw new Error(`Network response was not ok: ${errorText}`);
            }
        
            const result = await response.json();
        
            if (result.success) {
                iziToast.success({ message: 'CMS Page added successfully', position: 'topCenter' });
                // navigate('/cmsDetails');
            } else {
                iziToast.error({ message: `CMS Page addition failed: ${result.error || 'Unknown error'}`, position: 'topCenter' });
            }
        } catch (error) {
            console.error('Error adding CMS page:', error);
            iziToast.error({ message: `An error occurred: ${error.message}`, position: 'topCenter' });
        }
    };

    return (
        <>
            <AdminSidebar />
            <div className="home">
                <div className="toggle-sidebar" style={{ display: 'none' }}>
                    <i className="bi bi-menu"></i>
                </div>
                <div className="dashboard">
                    <div className="dashboard-header">
                        <h1>Add or Update CMS Page</h1>
                    </div>
                    <div className="container-fluid mt-3">
                        <form className="row login-form" onSubmit={handleSubmit}>
                            <div className="mb-3 col-lg-12">
                                <label htmlFor="pageType" className="mb-2">Page Type</label>
                                <select
                                    id="pageType"
                                    className="form-control"
                                    value={formData.pageType}
                                    onChange={(e) => setFormData(prevData => ({ ...prevData, pageType: e.target.value }))}
                                >
                                    <option value="">Select Page Type</option>
                                    <option value="Extrusion">Extrusion</option>
                                    <option value="Anodizing">Anodizing</option>
                                    <option value="Powder Coating">Powder Coating</option>
                                    <option value="Billet Making">Billet Making</option>
                                    <option value="Tool Room">Tool Room</option>
                                    <option value="Fabrication">Fabrication</option>
                                    <option value="Coloring">Coloring</option>
                                    <option value="Designing">Designing</option>
                                </select>
                            </div>
                            {formData.pageType && (
                                <>
                                    <div className="mb-3 col-lg-12">
                                        <label htmlFor="headContent" className="mb-2">Header Content</label>
                                        <input
                                            type="text"
                                            id="headContent"
                                            className="form-control"
                                            placeholder="Enter header content"
                                            value={formData.headContent}
                                            onChange={(e) => handleChange(e, 'headContent')}
                                        />
                                    </div>
                                    <div className="mb-3 col-lg-12">
                                        <label htmlFor="maintitle" className="mb-2">Main Title</label>
                                        <input
                                            type="text"
                                            id="maintitle"
                                            className="form-control"
                                            placeholder="Enter main title"
                                            value={formData.maintitle}
                                            onChange={(e) => handleChange(e, 'maintitle')}
                                        />
                                    </div>
                                    <div className="mb-3 col-lg-12">
                                        <label htmlFor="bannerImage" className="mb-2">Banner Image</label>
                                        <input
                                            type="file"
                                            id="bannerImage"
                                            className="form-control"
                                            accept=".png, .jpg, .jpeg"
                                            onChange={(e) => handleFileChange(e, 'bannerImage')}
                                        />
                                        {images.bannerImage && images.bannerImage.name && (
                                            <div className="text-muted">Existing file: {images.bannerImage.name}</div>
                                        )}
                                    </div>
                                    <div className="mb-3 col-lg-12">
                                        <label htmlFor="sideImage" className="mb-2">Side Image</label>
                                        <input
                                            type="file"
                                            id="sideImage"
                                            className="form-control"
                                            accept=".png, .jpg, .jpeg"
                                            onChange={(e) => handleFileChange(e, 'sideImage')}
                                        />
                                        {images.sideImage && images.sideImage.name && (
                                            <div className="text-muted">Existing file: {images.sideImage.name}</div>
                                        )}
                                    </div>
                                    <div className="mb-3 col-lg-12">
                                        <label htmlFor="body1title" className="mb-2">Body 1 Title</label>
                                        <input
                                            type="text"
                                            id="body1title"
                                            className="form-control"
                                            placeholder="Enter body 1 title"
                                            value={formData.body1title}
                                            onChange={(e) => handleChange(e, 'body1title')}
                                        />
                                    </div>
                                    <div className="mb-3 col-lg-12">
                                        <label className="mb-2">Body 1 Content</label>
                                        {formData.body1content.map((item, index) => (
                                            <div key={index} className="input-group mb-2">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={`Enter content ${index + 1}`}
                                                    value={item.content}
                                                    onChange={(e) => handleBodyContentChange(index, e.target.value)}
                                                />
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-danger"
                                                    onClick={() => handleRemoveBodyContent(index)}
                                                >
                                                    Remove
                                                </button>
                                            </div>
                                        ))}
                                        <button
                                            type="button"
                                            className="btn btn-outline-primary"
                                            onClick={handleAddBodyContent}
                                        >
                                            Add More Content
                                        </button>
                                    </div>
                                    <div className="mb-3 col-lg-12">
                                        <label className="mb-2">Body 2 Cards</label>
                                        {formData.body2card.map((card, cardIndex) => (
                                            <div key={cardIndex} className="card mb-3">
                                                <div className="card-body">
                                                    <div className="mb-3">
                                                        <label htmlFor={`cardTitle_${cardIndex}`} className="mb-2">Card Title</label>
                                                        <input
                                                            type="text"
                                                            id={`cardTitle_${cardIndex}`}
                                                            className="form-control"
                                                            placeholder="Enter card title"
                                                            value={card.cardTitle}
                                                            onChange={(e) => handleCardChange(cardIndex, 'cardTitle', e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor={`cardContent_${cardIndex}`} className="mb-2">Card Content</label>
                                                        <textarea
                                                            id={`cardContent_${cardIndex}`}
                                                            className="form-control"
                                                            placeholder="Enter card content"
                                                            value={card.cardContent}
                                                            onChange={(e) => handleCardChange(cardIndex, 'cardContent', e.target.value)}
                                                        ></textarea>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="mb-2">Bulletins</label>
                                                        {card.bulletins.map((bulletin, bulletinIndex) => (
                                                            <div key={bulletinIndex} className="input-group mb-2">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={`Bulletin ${bulletinIndex + 1}`}
                                                                    value={bulletin}
                                                                    onChange={(e) => handleBulletinChange(cardIndex, bulletinIndex, e.target.value)}
                                                                />
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-outline-danger"
                                                                    onClick={() => handleRemoveBulletin(cardIndex, bulletinIndex)}
                                                                >
                                                                    Remove
                                                                </button>
                                                            </div>
                                                        ))}
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-primary"
                                                            onClick={() => handleAddBulletin(cardIndex)}
                                                        >
                                                            Add Bulletin
                                                        </button>
                                                    </div>
                                                </div>
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-danger"
                                                    onClick={() => handleRemoveCard(cardIndex)}
                                                >
                                                    Remove Card
                                                </button>
                                            </div>
                                        ))}
                                        <button
                                            type="button"
                                            className="btn btn-outline-primary"
                                            onClick={handleAddCard}
                                        >
                                            Add More Cards
                                        </button>
                                    </div>
                                    <div className="mb-3 col-lg-12">
                                        <label htmlFor="bottomstatement" className="mb-2">Bottom Statement</label>
                                        <input
                                            type="text"
                                            id="bottomstatement"
                                            className="form-control"
                                            placeholder="Enter bottom statement"
                                            value={formData.bottomstatement}
                                            onChange={(e) => handleChange(e, 'bottomstatement')}
                                        />
                                    </div>
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </>
                            )}
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ManufacturingCmsPageAdd;
